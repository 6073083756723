import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Grid, InputAdornment, Typography } from "@material-ui/core";
import { generateKeyId } from "../../utils/proprietaryHooks";
import { useStylesMenuSettings } from "./menusettings.styles";
import InputSearch from "../../components/InputForm/InputSearch.component";
import SearchIcon from "@material-ui/icons/Search";

export const SelectIcon = ({
  selectedIcon,
  dataIcons,
  handleCloseModal,
  title,
  buttons,
  setSelectedIcon,
}) => {
  const classes = useStylesMenuSettings();
  const [searchIcon, setSearchIcon] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    const btn = document.getElementById("btnChargeIcon");
    if (selectedIcon.icon === "" && btn !== null) {
      btn.classList.add("Mui-disabled");
      btn.disabled = true;
      btn.setAttribute("tabindex", "-1");
    } else if (selectedIcon.icon !== undefined) {
      handleIconSelected(new MouseEvent("onClick"), selectedIcon.icon);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIconSelected = (e, id) => {
    e.preventDefault();
    const elements = document.getElementsByClassName("selectedIcon");
    if (elements.length > 0) {
      for (const element of elements) {
        element.classList.remove("selectedIcon");
        element.style.border = "2px solid transparent";
      }
    }

    const currentItem = document.getElementById(id);

    if (currentItem !== null) {
      currentItem.style.border = "2px solid #009BDD";
      currentItem.classList.add("selectedIcon");
    }
    // setSelectedIcon({
    //   icon: id,
    // });
    // const btn = document.getElementById("btnChargeIcon");
    // if (btn.disabled) {
    //   btn.classList.remove("Mui-disabled");
    //   btn.disabled = false;
    //   btn.setAttribute("tabindex", "0");
    // }
  };

  const filteredIcons = useMemo(() => {
    if (searchIcon !== "") {
      return dataIcons.filter((item) =>
        item.name.toLowerCase().includes(searchIcon.toLowerCase())
      );
    } else {
      return dataIcons;
    }
  }, [dataIcons, searchIcon]);

  const createPersonalizedIcon = (Icon) => {
    if (Icon !== undefined) {
      return (
        <Icon
          color="#575756"
          width={"40px"}
        />
      )
    } else {
      return <div></div>
    }
  }

  return (
    <>
      <div>
        <Typography className={classes.titleSelectTypeMenu} variant="subtitle1">
          {title}
        </Typography>
      </div>
      <div>
        <InputSearch
          name="CustomersFilter"
          inputRef={ref}
          value={searchIcon}
          onChange={(e) => {
            setSearchIcon(ref.current.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.colorSearchAdornment} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          margin="dense"
          fullWidth
        />
      </div>
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ overflowY: "scroll", maxHeight: "70%", minHeight: "60%" }}
      >
        {filteredIcons.map((item) => {
          return (
            <Grid
              id={`${item.name}`}
              container
              item
              lg={3}
              md={4}
              xs={6}
              sm={6}
              justifyContent="center"
              key={generateKeyId(15)}
              iconname={`${item.name}`}
              isPersonalizedIcon={item.icon !== undefined ? "true" : "false"}
              style={{
                padding: "6px 0px 6px 0px",
                border: `${selectedIcon.icon === item.name
                  ? "2px solid #009BDD"
                  : "2px solid transparent"
                  }`,
              }}
              className={`${selectedIcon.icon === item.name && "selectedIcon"
                } `}
            >
              <Grid container justifyContent="center">
                <Grid item lg={6} md={6} sm={12} xs={12} style={{ marginRight: "10px", textAlign: "center"  }}>
                  <Button
                    iconname={`${item.name}`}
                    isPersonalizedIcon={item.icon !== undefined ? "true" : "false"}
                    style={{
                      display: "inline-block",
                      height: "68px",
                      width: "68px",
                      textAlign: "center",
                    }}
                    onClick={(e) => handleIconSelected(e, `${item.name}`)}
                  >
                    {
                      item.icon !== undefined ? createPersonalizedIcon(item.icon) : (
                        <span
                          className="material-icons"
                          style={{
                            fontSize: "40px",
                            color: "#575756",
                          }}
                        >
                          {item.name}
                         </span>
                      )
                    }
                  </Button>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  item
                  lg={7}
                  md={7}
                  sm={12}
                  xs={12}
                >
                  <span
                    style={{
                      color: "#575756",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {item.name.length > 12
                      ? `${item.name.substring(0, 12)}...`
                      : item.name}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {buttons ? (
        <Grid
          container
          direcction="row"
          justifyContent="center"
          style={{ paddingTop: "20px" }}
        >
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={() => handleCloseModal(false)}
          >
            Cancelar
          </Button>
          <Button
            id="btnChargeIcon"
            color="primary"
            variant="contained"
            style={{ marginLeft: "10px" }}
            // disabled={selectedIcon.icon !== "" ? false : true}
            onClick={() => {
              const elements = document.getElementsByClassName("selectedIcon");
              let iconName = "";
              if (elements.length > 0) {
                for (const element of elements) {
                  iconName = element.getAttribute("iconname");
                }
              }
              handleCloseModal(true, iconName);
            }}
          >
            Cargar
          </Button>
        </Grid>
      ) : null}{" "}
    </>
  );
};
