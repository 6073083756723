import React from 'react'

export const EmailIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="162" height="96.798" viewBox="0 0 162 96.798">
            <g id="Componente_193_1" data-name="Componente 193 – 1" transform="translate(1)">
                <g id="Grupo_3267" data-name="Grupo 3267">
                    <path id="Trazado_2488" data-name="Trazado 2488" d="M885,509.416h160" transform="translate(-885 -430.103)" fill="none" stroke="#3f3d56" stroke-linecap="round" strokeWidth="2" />
                    <g id="Trazado_2485" data-name="Trazado 2485" transform="translate(24 -0.232)" fill="#fff">
                        <path d="M 56.50053024291992 71.27279663085938 L 3.507752656936646 37.92698287963867 L 3.367977142333984 37.83902740478516 L 55.99747467041016 1.254420042037964 L 108.6257553100586 37.83816909790039 L 108.479850769043 37.93162536621094 L 56.50053024291992 71.27279663085938 Z" stroke="none" />
                        <path d="M 55.99747848510742 2.472297668457031 L 5.179649353027344 37.79751968383789 L 56.49540328979492 70.08805847167969 L 106.8255920410156 37.8046875 L 55.99747848510742 2.472297668457031 M 55.99747467041016 0.23150634765625 C 56.17565155029297 0.23150634765625 56.35382843017578 0.284271240234375 56.50565338134766 0.389801025390625 L 109.527961730957 37.24744415283203 C 110.2146987915039 37.72484588623047 109.8655319213867 38.77335357666016 109.0197601318359 38.77335357666016 L 56.50568389892578 72.45753479003906 L 2.97515869140625 38.77335357666016 C 2.129417419433594 38.77335357666016 1.780220031738281 37.72484588623047 2.466987609863281 37.24744415283203 L 55.48929595947266 0.389801025390625 C 55.64112091064453 0.284271240234375 55.81929779052734 0.23150634765625 55.99747467041016 0.23150634765625 Z" stroke="none" fill="#3f3d56" />
                    </g>
                    <path id="Trazado_2486" data-name="Trazado 2486" d="M46.966.366a.763.763,0,0,1,.866,0L96.386,34.3a.714.714,0,0,1-.433,1.3L47.833,61.782-1.154,35.6a.714.714,0,0,1-.433-1.3Z" transform="translate(32.598 5.106)" fill="rgba(0,0,0,0.15)" />
                    <rect id="Rectángulo_1386" data-name="Rectángulo 1386" width="90.947" height="69.053" rx="2" transform="translate(30.658 19.029) rotate(-7)" fill="#fff" stroke={color} strokeWidth="2" />
                    <path id="Trazado_2487" data-name="Trazado 2487" d="M11,63.41,60.078,82.269l40.9-16.732,5.895,38.39-90.426-2.868Z" transform="translate(22.684 -27.571)" fill="rgba(0,0,0,0.15)" />
                    <g id="Trazado_2481" data-name="Trazado 2481" transform="translate(-288.895 -350.86)" fill="#fff">
                        <path d="M 421.465576171875 446.6587524414062 L 316.3237609863281 446.6587524414062 C 316.1459655761719 446.6585388183594 316.0001831054688 446.5158996582031 316 446.3407592773438 L 316 389.154296875 C 316.0001525878906 389.0194396972656 316.0877380371094 388.9034118652344 316.2104797363281 388.8579406738281 L 338.2565002441406 397.9569702148438 L 368.9008483886719 410.6041259765625 L 368.9032592773438 410.6051025390625 L 368.9056701660156 410.6060791015625 C 369.1413879394531 410.7019348144531 369.3899841308594 410.7505493164062 369.6446228027344 410.7505493164062 C 369.9060974121094 410.7505493164062 370.1609191894531 410.6994018554688 370.4020690917969 410.5985107421875 L 370.404541015625 410.5974731445312 L 370.4070434570312 410.5964050292969 L 400.1095886230469 397.9794006347656 L 421.581298828125 388.8586730957031 C 421.7024841308594 388.9046936035156 421.7892150878906 389.0204467773438 421.7893676757812 389.1555480957031 L 421.7893676757812 446.3418884277344 C 421.7891540527344 446.5158386230469 421.6433715820312 446.6585388183594 421.4644165039062 446.6587524414062 L 421.465576171875 446.6587524414062 Z" stroke="none" />
                        <path d="M 317 390.265625 L 317 445.6587524414062 L 420.7893676757812 445.6587524414062 L 420.7893676757812 390.2815551757812 L 370.7880859375 411.52099609375 C 370.424072265625 411.6733093261719 370.0393371582031 411.7505493164062 369.6445922851562 411.7505493164062 C 369.26025390625 411.7505493164062 368.8849182128906 411.6771545410156 368.529052734375 411.532470703125 L 368.5193481445312 411.5285034179688 L 317 390.265625 M 316.3237609863281 387.8374328613281 L 316.3616638183594 387.8374328613281 L 316.3955383300781 387.8525085449219 L 369.2823486328125 409.6797485351562 C 369.5177307128906 409.7754516601562 369.7817993164062 409.7740783691406 370.0160827636719 409.6759948730469 L 421.4277038574219 387.8374328613281 L 421.465576171875 387.8374328613281 C 422.1963195800781 387.8382873535156 422.7884521484375 388.4273986816406 422.7893676757812 389.154296875 L 422.7893676757812 446.3418884277344 C 422.7884521484375 447.06884765625 422.1963195800781 447.6578979492188 421.465576171875 447.6587524414062 L 316.3237609863281 447.6587524414062 C 315.5929565429688 447.6578979492188 315.0008239746094 447.06884765625 315 446.3418884277344 L 315 389.154296875 C 315.0008239746094 388.4273986816406 315.593017578125 387.8382873535156 316.3237609863281 387.8374328613281 Z" stroke="none" fill="#3f3d56" />
                    </g>
                    <path id="Trazado_2477" data-name="Trazado 2477" d="M379.255,640.884h-24.35a1.138,1.138,0,1,1,0-2.275h24.35a1.138,1.138,0,0,1,0,2.275Z" transform="translate(-320.615 -554.457)" fill={color} />
                    <path id="Trazado_2478" data-name="Trazado 2478" d="M363.642,614.884h-8.736a1.138,1.138,0,1,1,0-2.275h8.736a1.138,1.138,0,0,1,0,2.275Z" transform="translate(-320.615 -533.348)" fill={color} />
                </g>
            </g>
        </svg>
    )
}
