import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Controller } from 'react-hook-form';

const AntCheckbox = withStyles((theme) => ({
  root: {
    color: "grey",
    "&$checked": {
      color: theme.palette.primary.light,
    },
  },
  checked: {},
}))(Checkbox);

export const ControlledCheckComponent = (props) => {
  const { control, isDisabled, name } = props;
  return (
    <Controller
      name={name}
      control={control}
      type="checkbox"
      render={({ props, onChange, value }) => (
        <AntCheckbox
          disabled={isDisabled}
          onChange={(e) => onChange(e.target.checked)}
          {...props}
          checked={value}
        />
      )}
      {...props}
    />
  )
}
