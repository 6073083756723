import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import { DEFAULTRESPONSE, GET_ALL_SUBJECTS, GET_ERROR_NOTIFICATION } from "./types";

export const getSubjectsByTemplesId = (id) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      idTemplate: id,
    });
    const response = await axios.get(`${API_SECURITY_ENDPOINT}/api/Subjects/SubjectsByTemplateId`, myConfig);
    if (response.status === 200) {

      const templates = response.data.result;
      dispatch({
        type: GET_ALL_SUBJECTS,
        payload: templates
      })
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: GET_ERROR_NOTIFICATION,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
}