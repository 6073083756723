import React from 'react'
import { Grid, MenuItem, Typography, FormControlLabel, Paper, useTheme } from '@material-ui/core'
import { formatDate, isEmpty, makeId } from '../../utils/proprietaryHooks';
import { TimeComponent } from '../../components/Time/TimeComponent';
import { ControlledCheckComponent } from '../../components/CheckComponents/ControlledCheckComponent';
import { useStylesNotifications } from './notification.style';
import ControlledInputRoundedForm from '../../components/InputForm/ControlledInputRoundedForm'

export const NotificationFormRightView = (props) => {
  const classes = useStylesNotifications();
  const theme = useTheme();

  const {
    enabledTabsForm,
    subjects,
    currentTemplate,
    initialDate,
    errors, 
    control, 
    setValue, 
    getValues,
  } = props;
  debugger;
  return (
    <Grid container lg={8} item sm={12}>
            <Paper className={classes.paperTable}>
              <Grid container justifyContent="space-between" alignItems="stretch"
                spacing={3} style={{ padding: "30px", visibility: isEmpty(currentTemplate) ? "hidden" : 'visible' }}>
                <Grid item lg={6} sm={12}>
                  <Typography className={classes.titleMain}>
                    Plantilla {isEmpty(currentTemplate) ? "" : currentTemplate.title}
                  </Typography>
                  <ControlledInputRoundedForm
                    id="issue"
                    name="issue"
                    select
                    label="Asunto del correo"
                    fullWidth
                    control={control}
                    disabled={!enabledTabsForm}
                    error={errors.issue}
                    helperText={errors.issue?.message}
                  >
                    {subjects.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </ControlledInputRoundedForm>
                  <Typography className={classes.titleLight}>
                    Variables de la plantilla
                  </Typography>
                  {
                    !isEmpty(currentTemplate) &&
                    currentTemplate.variables.map((item) => {
                      if (item.type === "date") {
                        return <ControlledInputRoundedForm
                          key={makeId(5)}
                          id={item.id}
                          name={item.name}
                          disabled={!enabledTabsForm}
                          label={item.label}
                          type="date"
                          inputProps={{
                            min: `${formatDate(initialDate.current.nextDay, "yyyy-mm-dd")}`,
                          }}
                          fullWidth
                          style={{ color: theme.palette.primary.light }}
                          control={control}
                          error={errors[item.name]}
                          helperText={errors[item.name]?.message}
                        />
                      }

                      if (item.type === "text") {
                        return <ControlledInputRoundedForm
                          key={makeId(5)}
                          id={item.id}
                          name={item.name}
                          label={item.label}
                          multiline={item.multiline}
                          rows={item.row}
                          inputProps={{
                            maxLength: item.maxLength !== undefined ? item.maxLength : 50,
                          }}
                          fullWidth
                          disabled={!enabledTabsForm}
                          control={control}
                          error={errors[item.name]}
                          helperText={errors[item.name]?.message}
                        />
                      }

                      if (item.type === "time") {
                        return <TimeComponent
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          getValues={getValues}
                          GlobalDisabled={!enabledTabsForm}
                          propsGlobal={item} />
                      }

                      if (item.type === "check") {
                        return (
                          <FormControlLabel
                            key={makeId(5)}
                            disabled={!enabledTabsForm}
                            control={<ControlledCheckComponent
                              id={item.id}
                              name={item.name}
                              isDisabled={!enabledTabsForm}
                              control={control}
                            />}
                            label={
                              <Typography color="inherit" style={{ fontSize: "14px" }}>
                                {item.label}
                              </Typography>
                            }
                          />
                        )
                      }
                      return <></>
                    })
                  }
                  <Typography className={classes.titleLight}>
                    Fecha de envío del correo
                  </Typography>
                  <ControlledInputRoundedForm
                    id="dateSend"
                    name="dateSend"
                    disabled={!enabledTabsForm}
                    label="Fecha de envío"
                    type="date"
                    inputProps={{
                      min: `${formatDate(initialDate.current.today, "yyyy-mm-dd")}`,
                    }}
                    fullWidth
                    style={{ color: theme.palette.primary.light }}
                    control={control}
                    error={errors.dateSend}
                    helperText={errors.dateSend?.message}
                  />
                  <Typography className={classes.textInfoRange}>
                    {
                      isEmpty(currentTemplate)
                        ? ""
                        : `La notificación será enviada a las 23:59 de la fecha seleccionada`
                    }
                  </Typography>
                  {/* <Grid item lg={12} md={12} sm={12}>
                    {globalErrors !== "" && (
                      <div className={classes.errorText}>
                        <Typography color="error" variant="subtitle2">
                          {globalErrors}
                        </Typography>
                      </div>
                    )}
                  </Grid> */}
                </Grid>
                <Grid item lg={6} sm={12}>
                  <img src={currentTemplate.urlImage} alt="Imagen plantilla" style={{ maxWidth: "100%" }}></img>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
  )
}

export const MomoizedNotificationFormRightView = React.memo(NotificationFormRightView)
