import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { validateOtp } from "../../actions/otp.actions";
import PageBackground from "../../components/pageBackground.component";
import AlertConfirmOtp from "../../components/alertConfirmOtp.component";

/**
 * Otp Component ( full view for list of Otp)
 *
 * @export Class Component
 * @class Otp
 * @extends {Component}
 * @returns Redux connect
*/

const ResetOtp = ({ component: Component, container, ...props }) => {

    const { history, resetOtpReducer, resultValidation, validateOtp } = props;
    const [showPrivilegeAlert, setShowPrivilegeAlert] = useState(false);
    const [dataValidation, setdataValidation] = useState("Procesando solicitud espera un momento por favor...");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (showPrivilegeAlert === false) {
            let userToken = window.location.search.substring(7);
            validateOtp({
                token: userToken,
            }, history);
        }
        if (resultValidation === "A10") {
            setdataValidation("Se envio correctamente la solicitud para el restablecimiento del OTP.");
        } else if (resultValidation === "R11") {
            setdataValidation("No se puede enviar la solicitud de restablecimiento de OTP motivo " + resetOtpReducer);
        }
        setShowPrivilegeAlert(true);
    });

    return (
        <div>
            <PageBackground></PageBackground>
            {showPrivilegeAlert && (
                <AlertConfirmOtp
                    isOpen={true}
                    modalMessage={dataValidation}
                    showBtnAccept={false}
                    fadeOut={false}
                    showBtnClose={false}
                    textButtonAccept="Aceptar"
                ></AlertConfirmOtp>
            )}
        </div>
    );
};

ResetOtp.propTypes = {
    validateOtp: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    resetOtpReducer: state.resetOtpReducer.optResponseData,
    resultValidation: state.resetOtpReducer.optResponseCode
});

const mapDispatchToProps = {
    validateOtp
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetOtp);
