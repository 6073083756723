import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

/**
 * PrimaryButton constant ( function component used as generic primary button )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.light,
    padding: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    fontSize: "12px",
  },
  typography: {
    fontSize: "13.9px",
  },
}));

const ErrorBoxUko = ({ ...props }) => {
  const { value, appName } = props;
  const classes = useStyles();
  return (
    <div>
      {!appName && (<br />)}
      <div className={classes.root}>
        <Typography className={classes.typography}>{value} </Typography>
      </div>
    </div>
  );
};

export default ErrorBoxUko;
