import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import PageBackground from "../../../components/pageBackground.component";
import CardBody from "../../../components/CardBody/cardBody.component";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import PrimaryButton from "../../../components/primaryButton.component";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { loginUser, generateTokens } from "../../../actions/auth.action";
import { getEnterprise } from "../../../actions/enterprise.action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SET_IS_AUTHENTICATED } from "../../../actions/types";
import ErrorBox from "../../../components/errorBox.component";
import { getInfoApplicationIsFreeTrial, getParmeterConfigAction } from '../../../actions/applications.action'
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import ReCAPTCHA from "react-google-recaptcha";
import MaintenanceView from "../../notifications/MaintenanceView";

let isVerified = false
let tryFailedByRecaptcha = 0;

function onChange(value) {
  if (value) {
    isVerified = true;
  } else {
    tryFailedByRecaptcha = 1;
  }

}

const Login = (props) => {
  const {
    appName,
    loginUser,
    getAuthenticationResponse,
    authIsFreeTrialApp,
    generateTokensResponse,
    getInfoApplicationIsFreeTrial,
    isAuthenticated,
    getParmeterConfigAction,
    parameterConfig,
    tenant,
  } = props;
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [urlFreeTrial, setUrlFreeTrial] = useState("");
  const [errorsApp, setErrorsApp] = useState();
  const [blocked, setBlocked] = useState({
    timeStatus: "",
    timeBlocked: "",
  });
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Campo obligatorio"),
    password: Yup.string().required("Campo obligatorio"),
  });
  const [messageAttempts, setMessageAttempts] = useState("");
  const {
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });
  const theme = useTheme();
  const classes = useStyles();

  //#region

  useEffect(() => {
    if (isAuthenticated) {
      //window.location.assign(isAuthenticated.urlAuth);
    }
    if (appName && appName !== "") {
      setLoading(true);
      getInfoApplicationIsFreeTrial(appName, setLoading);
      getParmeterConfigAction(appName, 'ParameterApps');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authIsFreeTrialApp.url !== "") {
      setLoading(false);
      setUrlFreeTrial(authIsFreeTrialApp.url + authIsFreeTrialApp.code)
    }
  }, [authIsFreeTrialApp])

  useEffect(() => {
    if (!!getAuthenticationResponse) {
      if (getAuthenticationResponse.responseCode === 200) {
        reset();
      } else {
        setLoading(false);
        setErrorsApp(getAuthenticationResponse.message);
        reset();
      }

      if (getAuthenticationResponse.code === "A10" || getAuthenticationResponse.code === "A20") {
        window.location.assign(getAuthenticationResponse.url);
      }

      if (getAuthenticationResponse.isBlocked !== undefined && getAuthenticationResponse.isBlocked) {
        setBlocked({
          timeBlocked:
            "El usuario se encuentra bloqueado.",
          timeStatus: false,
        });
        setMessageAttempts(`Para solucionarlo, comunícate al ${getAuthenticationResponse.messageParameter}`);
      }

      if (getAuthenticationResponse.failedLoginInformation !== undefined
        && getAuthenticationResponse.failedLoginInformation?.failedAttempts !== undefined
        && getAuthenticationResponse.failedLoginInformation?.failedAttempts === 1) {
        setBlocked({
          timeBlocked: "",
          timeStatus: false,
        });
      }

      if (getAuthenticationResponse.code === "A20") {
        setBlocked({
          timeBlocked:
            "El usuario se encuentra bloqueado.",
          timeStatus: false,
        });
      }
    }

    if (!!generateTokensResponse) {
      if (generateTokensResponse.informationCode === "A10" || generateTokensResponse.informationCode === "A20") {
        window.location.assign(generateTokensResponse.urlApp);
        dispatch({
          action: SET_IS_AUTHENTICATED,
          payload: {
            authenticated: true,
            urlAuth: generateTokensResponse.urlApp,
          },
        });
      }
    }
    console.log(getAuthenticationResponse);
  }, [getAuthenticationResponse, generateTokensResponse, reset, dispatch]);
  //#endregion

  //#region  custom methods

  const setLogin = async (data) => {
    let userData = {
      user: data.username,
      password: data.password,
      appname: appName,
      page: window.location.search.substring(1),
    };

    if(parameterConfig && parameterConfig.captchaApply){
      if (isVerified) {
        setLoading(true);
        loginUser(userData, history, tenant);
      } else {
        tryFailedByRecaptcha = 1;
      }
    } else {
      setLoading(true);
      loginUser(userData, history, tenant);
    }
  };

  const getRemainingTime = (deadline) => {
    let now = new Date(),
      remainTime = (new Date(deadline) - now + 1000) / 1000,
      remainSeconds = ("0" + Math.floor(remainTime % 60)).slice(-2),
      remainMinutes = ("0" + Math.floor((remainTime / 60) % 60)).slice(-2),
      remainHours = ("0" + Math.floor((remainTime / 3600) % 24)).slice(-2);
    return {
      remainSeconds,
      remainMinutes,
      remainHours,
      remainTime,
    };
  };

  const ClockCountsDown = (finaMinute) => {
    if (!!getAuthenticationResponse && !getAuthenticationResponse.isBlocked) {
      let FechaHora = "";
      let fechaActual = new Date();
      let faltanMinutos = finaMinute * 1000;
      let fechaFuturo = fechaActual.getTime() + faltanMinutos;
      if (blocked.timeStatus !== true) {
        let timerUpdate = setInterval(() => {
          let currentTime = getRemainingTime(new Date(fechaFuturo));
          FechaHora = currentTime.remainMinutes + ":" + currentTime.remainSeconds;
          setBlocked({
            timeBlocked:
              "El usuario se encuentra bloqueado, vuelve a intentarlo en " +
              FechaHora,
            timeStatus: true,
          });
          setMessageAttempts("Si olvidaste la contraseña, puedes dar clic en la opción \"Recordar contraseña\"");
          if (currentTime.remainTime <= 0) {
            clearInterval(timerUpdate);
            setBlocked({
              timeBlocked:
                "El usuario ya fue liberado por favor intente acceder de nuevo.",
              timeStatus: false,
            });
            setMessageAttempts("");
          }
        }, 1000);
      }
    }
  };

  /**
   *  returns error box of auth response
   * @returns {JSX.Element}
   */

  const getErrors = () => {
    return !!getAuthenticationResponse && getAuthenticationResponse.failedLoginInformation
      ? (<>
        {getAuthenticationResponse.responseStat === "R13"
          ? errorsApp &&
          (blocked.timeBlocked === ""
            ? ClockCountsDown(
              getAuthenticationResponse.failedLoginInformation.lockTime
            )
            : "",
            (
              <ErrorBox
                value={
                  <>
                    {blocked.timeBlocked === "" ? errorsApp : blocked.timeBlocked}
                    {messageAttempts !== "" && <br></br>}
                    {messageAttempts !== "" && <br></br>}
                    {blocked.timeBlocked !== "" && (messageAttempts !== "" && messageAttempts)}
                  </>
                }
              ></ErrorBox>
            ))
          : getAuthenticationResponse.responseStat !== "R21"
            ? errorsApp &&
            (getAuthenticationResponse.responseStat === "R11" &&
              getAuthenticationResponse.failedLoginInformation.failedAttempts >
              0 ? (
              <ErrorBox
                value={
                  <>
                    {errorsApp}
                    {!!getAuthenticationResponse && getAuthenticationResponse.failedLoginInformation.failedAttempts === 1 ? "tienes dos (" : "tienes solo un ("}
                    {!!getAuthenticationResponse && 3 - getAuthenticationResponse.failedLoginInformation.failedAttempts}
                    {!!getAuthenticationResponse && getAuthenticationResponse.failedLoginInformation.failedAttempts === 1 ? ") intentos más." : ") intento más."}
                  </>
                }
              ></ErrorBox>
            ) : (
              <ErrorBox value={errorsApp}></ErrorBox>
            ))
            : !!errorsApp && <ErrorBox value={errorsApp}></ErrorBox>}
      </>
      ) : (!!getAuthenticationResponse &&
        (getAuthenticationResponse.responseStat === "R21"
          || getAuthenticationResponse.responseStat === "R22"
          || getAuthenticationResponse.responseStat === "R23"
        )) && <ErrorBox value={getAuthenticationResponse.message}></ErrorBox>;
  };

  const getErrorRecaptcha = () => {
    if (!isVerified && tryFailedByRecaptcha > 0) {
      tryFailedByRecaptcha = 0;
      return <ErrorBox
        value={"Por favor valida que no eres un robot"}></ErrorBox>

    }
  }
  //#endregion

  return (
    <div >
      <div className="login">
        <div className="card_login" >
          <PageBackground></PageBackground>
          <CardBody title="Login">
            <form id="login-validation" autoComplete="off">
              <fieldset disabled={isVisible ? true : false}>
                <hr></hr>
                <Controller
                  as={TextField}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  name="username"
                  control={control}
                  placeholder="Nombre Usuario"
                  fullWidth={true}
                  onChange={() => {
                    clearErrors("username");
                  }}
                  error={!!errors.username}
                  helperText={errors.username ? errors.username.message : null}
                  InputProps={{
                    classes,
                    startAdornment: (
                      <div style={{ height: "35.38px", backgroundColor: theme.palette.primary.light, borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", marginTop: "5px" }}>
                        <PersonIcon style={{ color: "#fff", marginTop: "4px", marginLeft: "5px", marginRight: "5px" }} />
                      </div>
                    ),
                  }}
                />
                <br />
                <Controller
                  as={TextField}
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  name="password"
                  control={control}
                  placeholder="Contraseña"
                  fullWidth={true}
                  onChange={() => {
                    clearErrors("password");
                  }}
                  error={!!errors.password}
                  type={showPassword ? "text" : "password"}
                  helperText={errors.password ? errors.password.message : null}
                  InputProps={{
                    classes,
                    startAdornment: (
                      <div style={{ height: "35.38px", backgroundColor: theme.palette.primary.light, borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", marginTop: "5px" }}>
                        <LockIcon style={{ color: "#fff", marginTop: "4px", marginLeft: "5px", marginRight: "5px" }} />
                      </div>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowPassword((showPassword) => !showPassword)
                          }
                        >
                          {!showPassword ? (
                            <VisibilityIcon fontSize="small"></VisibilityIcon>
                          ) : (
                            <VisibilityOffIcon fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                
                {parameterConfig && parameterConfig.captchaApply && (
                  <ReCAPTCHA className="recaptcha"
                    sitekey="6Lc675cpAAAAAA9QU8AecdiDpFLP1TD8Ht1hpmf1"
                    onChange={onChange}
                    size="normal"
                  />
                  )
                }
                {parameterConfig && !parameterConfig.captchaApply && (
                  <>
                    <br></br>
                    <br></br>
                  </>
                )}
                <PrimaryButton
                  txtBtn={"Ingresar"}
                  loading={loading}
                  onClick={handleSubmit(setLogin)}
                />
                {getErrors()}
                {getErrorRecaptcha()}
                <hr></hr>
                <Typography variant="subtitle1" style={{ textAlign: "right" }}>
                  <Link
                    to="/resetPassword"
                    style={{
                      color: theme.palette.primary.dark,
                      textDecoration: "none",
                    }}
                  >
                    Recordar contraseña
                  </Link>
                  {["colfactura", "colnomina"].includes(appName) ? (
                    <>
                      {/*
                        appName === "colnomina" ? (
                          <Fragment>
                            <br />
                            <Link href="/" variant="body1">
                              Ver Demo
                            </Link>
                          </Fragment>
                        ) : null
                        
                    <Link href="/" variant="body1">
                      Comprar Colfactura
                    </Link>*/}
                      {/* <br />
                      <Link href="/" variant="body1" style={{ pointerEvents: 'none' }}>
                        Ver Demo
                    </Link> */}
                      <br />
                      <Link
                        onClick={() => {
                          window.open(
                            "https://colfactura.com/colfactura/",
                            "_blank"
                          );
                        }}
                        variant="body1"
                        style={{ textDecoration: "none" }}
                      >
                        {appName === "colfactura"
                          ? "Comprar Colfactura"
                          : "Comprar Colfactura nómina"}
                      </Link>
                    </>
                  ) : null}
                  {
                    urlFreeTrial !== "" ? <>
                      <br />
                      <Link
                        onClick={() => {
                          window.open(
                            urlFreeTrial,
                            "_blank"
                          );
                        }}
                        variant="body1"
                        style={{
                          color: theme.palette.primary.dark,
                          textDecoration: "none",
                        }}
                      >
                        Prueba Gratis
                      </Link>
                    </> : null
                  }
                </Typography>
              </fieldset>
            </form>
          </CardBody>
        </div>
      </div>
      <MaintenanceView appName={appName} setIsVisible={setIsVisible} isVisible={isVisible} ></MaintenanceView>
    </div>
  );
};

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
  getEnterprise: PropTypes.func.isRequired,
  enterpriseReducer: PropTypes.object.isRequired,
  generateTokens: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
  enterpriseReducer: state.enterpriseReducer,
  getAuthenticationResponse: state.authReducer.getAuthenticationResponse,
  generateTokensResponse: state.authReducer.generateTokensResponse,
  isAuthenticated: state.authReducer.isAuthenticated,
  authIsFreeTrialApp: state.authReducer.authIsFreeTrialApp,
  parameterConfig: state.applicationsReducer.parameterConfig,
});
export default connect(mapStateToProps, {
  loginUser,
  getEnterprise,
  generateTokens,
  getInfoApplicationIsFreeTrial,
  getParmeterConfigAction,
})(Login);

const useStyles = makeStyles((theme) => ({
  input: {
    fontWeight: "500",
    color: "#B2B2B2",
    paddingLeft: 6,
    fontFamily: "muli, saint-serif",
    fontSize: "18px",
    "&:hover": {
      color: "#4F4F4F",
      fontWeight: "400",
      border: "none",
    },
    borderRadius: "0px 12px 12px 0px",
    marginTop: 4,
  },
  root: {
    paddingLeft: "0px",
    boxShadow: "0px 4px 3px #00000033",
    borderRadius: "12px",
    "& .MuiInputBase-root": {
      color: theme.palette.primary.light,
    },
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));
