import { connect } from "react-redux";
import {
    Grid,
    useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

const AuthenticationLayoutUko = (props) => {
    const { children, title, logoApp, logoSignIn } = props;
    const theme = useTheme();
    const classes = useStyles();
    return (
        <Grid container style={{ minHeight: "100vh" }} >
            <Grid item md={6} xs={12} className={classes.gridOrderFirst} >
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    sx={{
                        padding: 4,
                        height: "100%",
                        backgroundColor: theme.palette.primary.light,
                    }}>
                    <Box>
                        <img alt="" src={logoApp} style={{ width: '100%' }} />
                    </Box>
                </Box>
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridOrderData} style={{ background: theme.palette.secondary.light, display:"flex", justifyContent:"center" }}>
                <Box style={{ display: "flex", alignItems:"center", justifyContent:"center", height:"100%" }} >
                    <Box style={{ textAlign:"center", maxWidth:"550px", width:"100%", padding:"32px" }}>
                        <img src={logoSignIn} alt="Logo" width= "115.71px" />
                        <h1 style={{ fontWeight: "700", fontStyle:'normal', fontSize: "28px", marginTop: "20px", color: theme.palette.secondary.titleText}}>
                            {title}
                        </h1>
                        {children}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

AuthenticationLayoutUko.propTypes = {
};
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps, {
})(AuthenticationLayoutUko);

const useStyles = makeStyles((theme) => ({
    gridOrderFirst: {
        [theme.breakpoints.up('md')]: {
            order: 1,
        },
        [theme.breakpoints.down('md')]: {
            order: 2,
        },
    },
    gridOrderData: {
        [theme.breakpoints.down('md')]: {
            order: 1,
        },
        [theme.breakpoints.up('md')]: {
            order: 2,
        },
    },
}));