import axios from "axios";
import { API_SECURITY_ENDPOINT } from "../config/config";
import { GET_ENTERPRISE } from "./types";

//acciones
export const getEnterprise = (username, appname) => async (
  dispatch,
  getState
) => {
  try {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `${localStorage.getItem("jwtToken")}`,
        userId: username,
        app: appname,
      },
    };

    const res = await axios.get(
      API_SECURITY_ENDPOINT + "/api/customer/GetCustomerByUser",
      headers
    );

    dispatch({
      type: GET_ENTERPRISE,
      payload: res.data.result,
    });
  } catch (error) {
    console.log("Error", error);
  }
};
