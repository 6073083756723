import aesjs from "aes-js";
import {
    encryptionKey,
    encryptionIv,    
  } from "../config/config";

export const encryptAes = (text) => {    
    var textBytes = aesjs.utils.utf8.toBytes(text);
    
    var aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    
    var textEncryptedBytes = aesCbc.encrypt(aesjs.padding.pkcs7.pad(textBytes));
    
    // To print or store the binary data, you may convert it to hex
    var encryptedHex = aesjs.utils.hex.fromBytes(textEncryptedBytes);    

    // When ready to decrypt the hex string, convert it back to bytes
    var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);

    // Convert bytes to base64
    const textEncryptBase64 = Buffer.from(encryptedBytes).toString('base64');

    return textEncryptBase64;
}


export const decryptAes = (text) => {    
    var aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
    var encryptedText = aesjs.utils.hex.toBytes(
        new Buffer(text || "", "base64").toString("hex")
    );
    var decryptedBytes = aesCbc.decrypt(encryptedText);
    var decryptedText = aesjs.utils.utf8
        .fromBytes(decryptedBytes)
        .replace(/(?:\\[rn]|[\r\n]+|[\000-\031\200-\377])+/g, "");
    return decryptedText;
}