import { useState } from "react";
import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { useStylesMenuSettings } from "../views/menuSettings/menusettings.styles";
import SwitchForm from "../components/Switch/switchForm.component";

const initialStateTree = {
  menu1: [],
  menu2: {},
  menu3: {
    application: [],
    user: []
  }
};

export const useTree = (itemAction, initialState = initialStateTree) => {

  const classes = useStylesMenuSettings();
  const [values, setValues] = useState(initialState);

  /**
   * AddInitialDataMenu() toma los datos, y luego establece el estado de menu1 al resultado de llamar a
   * addPropertiesTitle() sobre los datos.
   * @param data - los datos que se pasan desde el componente principal
   */
  const addInitialDataMenu = (data) => {
    setValues((state) => ({
      ...state,
      menu1: addPropertiesTitle(data, null),
    }));
  };

  /**
   * Toma un elemento, le agrega una propiedad de título y luego lo agrega al array de menu1.
   * @param item - el elemento que se va a añadir
   */
  const addItemMenu = (item) => {
    item.title = addPropertyTitle(item.name, item.key, undefined, item.isActivate, item);
    const temporalList = [...values.menu1, item];
    setValues((state) => ({ ...state, menu1: temporalList }));
  };

  /**
 * Agrega los datos iniciales al nivel 3 del árbol de menú.
 * @param {Object|Array} data Los datos que se agregarán al nivel 3 del árbol de menú. Pueden ser un objeto o un array.
 * @param {String} key La clave en la que se agregará el objeto de datos. Si no se proporciona, los datos se agregarán a todas las claves disponibles.
 */
  const addInitialDataMenu3 = (data, selectMenu) => {
    setValues((state) => ({
      ...state,
      menu3: {
        user: addPropertiesTitle({ user: data.user, application: [] }),
        application: addPropertiesTitle({ user: [], application: data.application }),
      },
    }));
  };

  /**
  * Agrega un elemento al nivel 3 del árbol de menú.
  * @param {Object} item El elemento de menú que se agregará al nivel 3 del árbol.
  * @param {String} key La clave en la que se agregará el elemento de menú. Debe ser "application" o "user".
  */
  const addItemMenu3 = (item, key) => {
    const createItemWithTitle = (item) => {
      return {
        ...item,
        title: addPropertyTitle(item.name, item.key, item.section, item.isActivate, item),
      };
    };

    const updateMenu3WithNewItem = (menu3, newItem, key) => {
      return {
        ...menu3,
        [key]: [...menu3[key], newItem],
      };
    };

    const newItem = createItemWithTitle(item);

    const newMenu3 = updateMenu3WithNewItem(values.menu3, newItem, key);

    setValues(prevState => ({ ...prevState, menu3: newMenu3 }));
  };

  /**
   * Add styles for each item, also add buttons edit and delete
   * @param {*} data 
   * @param {*} fatherItem necesary for show or not swith element
   * @returns 
   */
  const addPropertiesTitle = (data, fatherItem = null) => {
    let dataResponse = [];
    if (data !== undefined && data.application === undefined && data.user === undefined) {
      for (const dat of data) {
        let dataChildrenResponse = [];
        if (dat.children !== undefined && dat.children.length > 0) {
          dataChildrenResponse = addPropertiesTitle(dat.children, dat);
        }
        if (dataChildrenResponse.length !== 0) {
          dataResponse.push({
            ...dat,
            title: addPropertyTitle(
              dat.name,
              dat.key,
              dat.section,
              dat.isActivate,
              dat,
              fatherItem,
            ),
            children: dataChildrenResponse,
          });
        } else {
          dataResponse.push({
            ...dat,
            title: addPropertyTitle(
              dat.name,
              dat.key,
              dat.section,
              dat.isActivate,
              dat,
              fatherItem,
            ),
          });
        }
      }
    } else {
      let dataApplication = [];
      if (data.application.length > 0) {
        for (const dat of data.application) {
          dataApplication.push({
            ...dat,
            title: addPropertyTitle(
              dat.name,
              dat.key,
              dat.section,
              dat.isActivate,
              dat,
              fatherItem,
            ),
          });
        }
      }
      let dataUser = [];
      if (data.user.length > 0) {
        for (const dat of data.user) {
          dataUser.push({
            ...dat,
            title: addPropertyTitle(
              dat.name,
              dat.key,
              dat.section,
              dat.isActivate,
              dat,
              fatherItem,
            ),
          });
        }
      }
      dataResponse = dataApplication.length > 0 ? dataApplication : dataUser;
    }
    return dataResponse;
  }

  const addPropertyTitle = (name, key, section, isActivate, item, fatherItem = null) => {
    let shouldMoveAway = 0;
    let shouldBeSeeSwitch = false;
    if (fatherItem == null || fatherItem.isActivate) {
      shouldMoveAway = 4;
      shouldBeSeeSwitch = true;
    }
    return (
      <div
        id={`container-${key}`}
        className={classes.titleTree}
        onMouseOver={(e) => {
          const iconEdit = document.getElementById(`edit_${key}`);
          const iconDelete = document.getElementById(`delete_${key}`);
          const divContainer = document.getElementById(`container-${key}`);
          const extra = document.getElementById(`extra_${key}`);
          if (!!iconEdit) {
            iconEdit.style.display = "initial";
            extra.style.display = "block";
            divContainer.style.paddingRight = "0px";
          }

          if (!!iconDelete) {
            iconDelete.style.display = "initial";
          }
        }}
        onMouseLeave={(e) => {
          const iconEdit = document.getElementById(`edit_${key}`);
          const iconDelete = document.getElementById(`delete_${key}`);
          const divContainer = document.getElementById(`container-${key}`);
          const extra = document.getElementById(`extra_${key}`);
          if (!!iconEdit) {
            iconEdit.style.display = "none";
            extra.style.display = "none";
            divContainer.style.paddingRight = "12px";
          }

          if (!!iconDelete) {
            iconDelete.style.display = "none";
          }
        }}
      >
        <Grid container spacing={2} justifyContent="space-between" style={{ opacity: isActivate ? "1" : "0.5" }}>
          <Grid item lg={7} md={7} sm={6} xs={6} className={classes.containerTitleItem}>
            <span className={classes.titleItem}>{name}</span>
          </Grid>
          <Grid
            container
            item
            lg={5}
            md={5}
            sm={6}
            xs={6}
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            style={{ opacity: isActivate ? "1" : "0.5" }}
          >
            <Grid item lg={4} md={4}>
              <IconButton
                id={`edit_${key}`}
                style={{ display: "none", opacity: isActivate ? "1" : "0.5" }}
                onClick={() => {
                  const values = typeof section === "object" ? section : item;
                  itemAction(key, "Edit", values);
                }}
              >
                <span
                  className={`material-icons ${classes.icon}`}
                  style={{ fontSize: "20px", color: "#575756" }}
                >
                  edit
                </span>
              </IconButton>
            </Grid>
            {
              (item.children !== undefined && item.children.length === 0) || item.children === undefined
                ? (
                  <Grid item lg={4} md={4}>
                    <IconButton
                      id={`delete_${key}`}
                      style={{ display: "none", opacity: isActivate ? "1" : "0.5" }}
                      onClick={() => itemAction(key, "Delete", section)}
                    >
                      <span
                        className={`material-icons ${classes.icon}`}
                        style={{ fontSize: "20px", color: "#575756" }}
                      >
                        delete
                      </span>
                    </IconButton>
                  </Grid>
                )
                : <></>
            }

            <Grid container item lg={shouldMoveAway} md={shouldMoveAway} alignItems="center">
              <div id={`extra_${key}`} style={{ display: "none" }}>
                {
                  shouldBeSeeSwitch ?
                    <SwitchForm
                      key={`extra_${key}switch`}
                      checked={isActivate}
                      value={isActivate}
                      onChange={(e) => {
                        itemAction(key, "State", item, e);
                      }}
                    /> :
                    <></>
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return {
    values,
    addItemMenu,
    addInitialDataMenu,
    addInitialDataMenu3,
    addItemMenu3,
  };
};
