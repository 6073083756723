import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import {
  GET_ERRORS,
  GET_PRIVILEGE,
  GET_PRIVILEGES,
  DEFAULTRESPONSE,
  GET_APPLICATIONS,
  SET_PRIVILEGE_ERROR,
  GET_PRIVILEGE_CHANGED,
} from "./types";

export /**
 * Privileges Action getPrivileges
 *
 * @method GET
 * @param {*}
 */
  const getPrivileges = (appName, rolName) => async (dispatch) => {
    try {
      let defaultHeader = defaultHeaders();
      if (appName !== undefined) {
        Object.assign(defaultHeader.headers, {
          application: appName,
        });
      }

      dispatch({
        type: GET_PRIVILEGES,
        payload: [],
      });

      const response = await axios.get(
        API_SECURITY_ENDPOINT + "/api/privilege/list",
        defaultHeader
      );
      dispatch({
        type: GET_PRIVILEGES,
        payload: response.data.result.records,
      });
    } catch (error) {
      dispatch({
        type: GET_PRIVILEGES,
        payload: [],
      });
      console.error(error);
      const { response } = error;

      if (response && response.status === 400 && response.data.result && response.data.result.informationCode === "R11") {
        throw new Error(`Error getting privileges: ${response.statusText}`)
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
        });
      }
    }
  };

export /**
 * Privileges Action getApplication
 *
 * @method GET
 * @param {*} privilegeId
 */
  const getApplicationById = (privilegeId) => async (dispatch) => {
    try {
      let myConfig = defaultHeaders();
      Object.assign(myConfig.headers, {
        id: privilegeId,
      });
      const response = await axios.get(
        API_SECURITY_ENDPOINT + "/api/application/getApplicationsByIdPrivilege",
        myConfig
      );
      if (response.data.result.count === 0) {
        return;
      }
      dispatch({
        type: GET_APPLICATIONS,
        payload: response.data.result.records.filter(
          (item) => item.name !== "RESETOTP"
        ),
      });
    } catch (error) {
      const { response } = error;
      console.error(error);
      dispatch({
        type: GET_ERRORS,
        payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
      });
    }
  };

export /**
 * Privilege Action getPrivilegeById
 *
 * @method GET
 * @param {*} privilegeId
 */
  const getPrivilegeById = (privilegeId) => async (dispatch) => {
    if (privilegeId === 0) {
      dispatch({
        type: GET_PRIVILEGE,
        payload: [],
      });
    } else {
      try {
        let myConfig = defaultHeaders();
        Object.assign(myConfig.headers, {
          id: privilegeId,
        });
        const response = await axios.get(
          API_SECURITY_ENDPOINT + "/api/privilege/privilegeId",
          myConfig
        );
        if (response.data.result.count === 0) {
          return;
        }
        dispatch({
          type: GET_PRIVILEGE,
          payload: response.data.result.records[0],
        });
      } catch (error) {
        const { response } = error;
        console.error(error);
        dispatch({
          type: GET_ERRORS,
          payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
        });
      }
    }
  };

export /**
 * Privilege Action create ( create )
 *
 * @method POST
 * @param {history} history
 */
  const createPrivilege = (privilegeData, history, updateChecked, type) => async (
    dispatch
  ) => {
    try {
      var response = await axios.post(
        API_SECURITY_ENDPOINT + "/api/privilege/create",
        privilegeData,
        defaultHeaders()
      );
      if (response.status === 200) {
        updateChecked(type);
        dispatch({
          type: GET_PRIVILEGE_CHANGED,
          payload: `El privilegio fue creado exitosamente`,
        });
      }
    } catch (err) {
      if (err.state === 400) {
        dispatch({
          type: GET_PRIVILEGE_CHANGED,
          payload: err.message,
        });
      }
      const response = !!err.response
        ? err.response.data
        : DEFAULTRESPONSE.noResponseFromApi;

      dispatch({
        type: SET_PRIVILEGE_ERROR,
        payload: response,
      });
    }
  };

export /**
 * Privilege Action modify ( modify )
 *
 * @method POST
 * @param {history} history
 */
  const modifyPrivilege = (
    privilegeData,
    history,
    updateChecked,
    type,
    isActivating,
    restartFields
  ) => async (dispatch) => {
    try {
      var response = await axios.post(
        API_SECURITY_ENDPOINT + "/api/privilege/modify",
        privilegeData,
        defaultHeaders()
      );
      if (response.status === 200) {
        updateChecked(type);
        let criteria = false;
        if (
          privilegeData.idstate !== undefined &&
          privilegeData.licence === undefined
        ) {
          criteria = privilegeData.idstate;
        } else {
          criteria = privilegeData.licence;
        }

        if (typeof restartFields === "function") {
          restartFields();
        }

        dispatch({
          type: GET_PRIVILEGE_CHANGED,
          payload: isActivating
            ? `El privilegio quedó en estado ${criteria === true ? "activo" : "inactivo"
            } para todas las aplicaciones asociadas`
            : `El privilegio fue modificado exitosamente`,
        });
      } else {
        dispatch({
          type: SET_PRIVILEGE_ERROR,
          payload: response.data,
        });
      }
    } catch (err) {
      if (err.state === 400) {
        dispatch({
          type: GET_PRIVILEGE_CHANGED,
          payload: err.message,
        });
      }
      const response = !!err.response
        ? err.response.data
        : DEFAULTRESPONSE.noResponseFromApi;
      dispatch({
        type: SET_PRIVILEGE_ERROR,
        payload: response.data,
      });
    }
  };
  