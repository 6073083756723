import {
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Launch } from "@material-ui/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import InputSearch from "../InputForm/InputSearch.component";
import SearchIcon from "@material-ui/icons/Search";
import MenuOrder from "../MenuOrder/menuOrder.component";
import IconEspecialButton from "../ButtonForm/IconEspecialButton.component";
import PropTypes from "prop-types";
import SwitchForm from "../Switch/switchForm.component";
import "./table.css";
import { findFinalOfWord } from "../../utils/proprietaryHooks";
import TrashIcon from "../IconsLibrary/TrashIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `4px 0 0 4px`,
      },
    },
    padding: '8px',
    '& .MuiTableCell-root': {
      padding: '6px'
    }
  },
  container: {
    maxHeight: 440,
    width: "100%",
  },
  card: {
    borderRadius: "10px",
    background: theme.palette.primary.main,
  },
  paper: {
    width: "100% !important",
    padding: "10px 10px",
    background: theme.palette.common.white,
  },
  table: {
    background: theme.palette.common.white,
    padding: "10px 10px 10px 10px",
    WebkitBoxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
    MozBoxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
    boxShadow: "0px 0px 5px 0px rgba(186,180,186,1)",
  },
  columnStyle: {
    fontSize: "14px",
    font: "normal normal 600 14px/16px Muli",
  },
  tableCell: {
    height: "26px",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.light, // Añade opacidad al tono
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  alignSwitch: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  name: {
    color: "blue",
  },
  hover: {
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
    },
  },
  labels: {
    font: "normal normal normal 14px/19px Muli",
    letterSpacing: "0px",
    color: "#575756",
    cursor: "pointer",
  },
  menuOrder: {
    "&&:hover": {
      background: theme.palette.secondary.main,
      color: `${theme.palette.text.secondary} !important`,
      border: "none",
      boxShadow: "none",
    },
  },
  row: {
    background: '#F6F6F6 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 4px #00000033',
    opacity: 1,
    height: '45px',
  },
  colorSearchAdornment: {
    color: '#C6C6C6'
  }
}));

const CustomTable = ({
  data,
  paramToDisable,
  validateToDisable = true,
  isBoolTheValidationToDisable = true,
  columns,
  havePagination,
  isUser,
  buttons = {},
  mainParam,
  handleCellClick,
  option,
  setOption,
  globalDisabled,
  rows,
  handleChangePages,
  handleSearchUsers,
}) => {
  const classes = useStyles();

  //Filtro para paginación
  let filtered = [];

  // manejo de estado
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [isDropdownUp, setDropdownUp] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const searchRef = useRef(null);
  const [isEditing, setEditing] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [orderedColumns, setOrderedColumns] = useState([]);
  let component;
  let config = {
    cellSize: {
      lg: 2,
      md: 2,
      sm: 2
    }
  };

  const [currentFilter, setCurrentFilter] = useState({
    searchBar: true,
    option: false,
    dropdown: false,
  });

  useEffect(() => {
    // mainSectionDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  useEffect(() => {
    if(search === ''){
      handleSearch(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // metodos de renderización de la tabla
  const getDropDownState = (id) => {
    const column = orderedColumns.find((item) => item.name === selectedColumn);
    const naturalOrder = !!column ? column.naturalOrder : false;
    if (selectedColumn === id && !naturalOrder) {
      return (
        <ArrowDropUp
          id={id}
          className="dropdownIcon"
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      );
    } else if (selectedColumn === id && naturalOrder) {
      return (
        <ArrowDropDown
          id={id}
          className="dropdownIcon"
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      );
    } else {
      return (
        <ArrowDropDown
          id={id}
          className="dropdownIcon"
          onClick={() => setDropdownUp(!isDropdownUp)}
        />
      );
    }
  };

  const switchComponent = (value, row, column) => {
    const { component, haveIcon, handleIconClick } = column;
    const { handleChange } = component;
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        spacing={2}
        justifyContent={haveIcon ? "flex-end" : "center"}
        onMouseOver={() => {
          //if (Boolean(row[paramToDisable]) === false) {
          const trashButton = document.getElementById(row.id);
          if (!!trashButton) {
            trashButton.style.visibility = "visible";
            trashButton.style.cursor = "pointer";
          }
          //}
        }}
        onMouseLeave={() => {
          const trashButton = document.getElementById(row.id);
          if (!!trashButton) {
            trashButton.style.visibility = "hidden";
          }
        }}
      >
        <Tooltip
          title={value === 6 ? "No es posible cambiar de estado a este usuario" :`Activar/Inactivar ${column.tooltipMessage}`}
          aria-label={`Activar/Inactivar ${column.name}`}
          className={classes.alignSwitch}
        >
          <SwitchForm
            checked={column.format(value)}
            name={column.tooltipMessage}
            disabled={globalDisabled}
            onChange={(e) => {
              if(value !== 6){
                handleChange(e, row.id, column.id, row);
                setCurrentFilter({
                  searchBar: false,
                  option: false,
                  dropdown: false,
                });
                
              }
              
            }}
          />
        </Tooltip>
        {column.withIcon ? (
          <Tooltip
            title='Eliminar'
            aria-label="Eliminar"
            style={{ marginTop: 4, marginLeft: 2 }}
          // className={classes.alignSwitch}
          >
            <div>
            <TrashIcon
              id={row.id}
              style={{ visibility: "hidden", marginLeft: 4, justifyContent: 'center' }}
              color="primary"
              width={'20px'}
              active={true}
              onClick={(evt) => {
                handleIconClick(evt, row.id);
              }}
            />
            </div>
          </Tooltip>
        ) : <></>}
      </Grid>
    );
  };

  const typographyComponent = (value, row, column) => {
    if (column.wordLength === undefined) column.key = undefined;
    const result = !!column.key
      ? findFinalOfWord(value, column.wordLength, column.key)
      : value;
    value = !!value ? value : "";

    return (
      <>
        {value.length > column.wordLength ? (
          <Tooltip title={value}>
            <Typography
              className={classes.labels}
              onClick={(evt) => {
                if (typeof handleCellClick === "function")
                  handleCellClick(evt, row, column);
              }}
              align={column.align}
              style={!!column.customClass ? column.customClass : null}
            >
              {column.format ? column.format(result) : result}
            </Typography>
          </Tooltip>
        ) : (
          <Typography
            className={classes.labels}
            onClick={(evt) => {
              if (typeof handleCellClick === "function")
                handleCellClick(evt, row, column);
            }}
            align={column.align}
            style={!!column.customClass ? column.customClass : null}
          >
            {column.format ? column.format(result) : result}
          </Typography>
        )}
      </>
    );
  };

  const getRowData = () => {
    if (currentFilter.searchBar) {
      filtered = filteredData;
    } else if (currentFilter.option) {
      filtered = orderByOption;
    } else if (currentFilter.dropdown) {
      filtered = orderedColumn;
    } else {
      filtered = currentData;
    }

    if (filtered.length === 0 && rows === 0) {
      return (
        <TableRow className={classes.row}>
          <TableCell
            colSpan={
              columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
            }
          />
          <TableCell>No se encontraron resultados</TableCell>
        </TableRow>
      );
    } else if(filtered.length === 0 && rows !== 0) {
      return (
        <TableRow className={classes.row}>
          <TableCell
            colSpan={
              columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
            }
          />
          <TableCell>Presiona la tecla Enter para filtar tu busqueda</TableCell>
        </TableRow>
      )
    } else {
      const filterCounter = havePagination
        ? filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : filtered;
      return filterCounter.map((row, key) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={key}
            className={
              paramToDisable !== undefined &&
                isBoolTheValidationToDisable ? Boolean(row[paramToDisable]) === validateToDisable ? "disabled_row"
                : globalDisabled
                  ? "disabled_row"
                  : "MuiTypography-root" : row[paramToDisable] === validateToDisable ? "disabled_row"
                : globalDisabled
                  ? "disabled_row"
                  : "MuiTypography-root"
            }
          >
            {columns.map((column) => {
              const value = row[column.id];
              return (
                <TableCell classes={{ root: classes.root }} key={column.id} align={column.align} size={"medium"}>
                  {stateSection(column, row, value)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
    }
  };

  const stateSection = (column, row, value) => {
    if (!!column.children) {
      return column.children(value, row);
    }

    if (!!column.component) {
      if (!!column.isVisible) {
        if (column.isVisible(row)) {
          return switchComponent(value, row, column)
        } else {
          return null;
        }
      } else {
        return switchComponent(value, row, column)
      }
    }
    return typographyComponent(value, row, column);
  }

  const RecordNotFound = () => {
    return (
      <TableRow>
        <TableCell
          colSpan={
            columns.length % 2 === 0 ? columns.length / 2 : columns.length / 2
          }
        />
        <TableCell>No se encontraron resultados</TableCell>
      </TableRow>
    );
  };
  
  // metodos de control de paginación
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangePageUser = (_event, newPage, rowsPerPage) => {
    if (typeof handleChangePages === 'function') {
      handleChangePages(_event, newPage, rowsPerPage,search);
    }
    setPage(newPage);
  };

  const handleSearch = (event) => {
    if (typeof handleSearchUsers === 'function') {
      handleSearchUsers(event);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const orderedColumn = useMemo(() => {
    const haveBeenOrdered = orderedColumns.find(
      (item) => item.name === selectedColumn
    );
    if (haveBeenOrdered !== undefined) {
      let orderedData = [];
      if (haveBeenOrdered.naturalOrder) {
        orderedData = data.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] > b[selectedColumn]
              ? 1
              : -1
        );
      } else {
        orderedData = data.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] < b[selectedColumn]
              ? 1
              : -1
        );
      }
      return orderedData;
    } else {
      if (!!orderedColumns.length) {
        return data.sort((a, b) =>
          a[selectedColumn] === b[selectedColumn]
            ? 0
            : a[selectedColumn] > b[selectedColumn]
              ? 1
              : -1
        );
      }
    }
  }, [data, orderedColumns, selectedColumn]);

  const orderByDirection = (a, b, sortBy) => {
    let i = 0,
      result = 0;
    while (i < sortBy.length && result === 0) {
      result =
        sortBy[i].direction *
        (a[sortBy[i].prop] < b[sortBy[i].prop]
          ? -1
          : a[sortBy[i].prop] > b[sortBy[i].prop]
            ? 1
            : 0);
      i++;
    }
    return result;
  };

  const orderByOption = useMemo(() => {

    const { options } = !!buttons.menuOrder ? buttons.menuOrder : {};

    if (option !== null && option !== undefined) {
      for (const iter of options) {
        if (iter.label === options[option].label) {
          if (iter.columns.length === 1) {
            const { prop, direction } = iter.columns[0];
            if (direction === -1) {
              return currentData.sort((a, b) =>
                a[prop] === b[prop] ? 0 : a[prop] > b[prop] ? 1 : -1
              );
            } else {
              return currentData.sort((a, b) =>
                a[prop] === b[prop] ? 0 : a[prop] < b[prop] ? 1 : -1
              );
            }
          } else if (iter.columns.length > 1) {
            return currentData.sort((a, b) =>
              orderByDirection(a, b, iter.columns)
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData, option]);

  const filteredData = useMemo(() => {
    return currentData.filter((item) => {
      for (const key in item) {
        if (
          item[key] !== undefined &&
          item[key] !== null &&
          typeof item[key] === "string"
        ) {
          const filter =
            typeof item[key] === "string" ? item[key].toLowerCase() : item[key];
          if (filter.includes(search.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    });
  }, [currentData, search]);

  if (!!buttons.children) {
    if (!!buttons.children().component) {
      component = buttons.children().component;
    } else {
      component = buttons.children;
    }
    if (!!buttons.children().config) {
      config = buttons.children().config;
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container alignItems="center" direction="row" spacing={0} justifyContent={!!buttons.justify ? buttons.justify : 'flex-start'}>
        {buttons.searchBar ? (
          <Grid item lg={buttons.searchBar.cellSize.lg} md={buttons.searchBar.cellSize.md} sm={buttons.searchBar.cellSize.sm}>
            {isUser ? (
              <InputSearch
                name="CustomersFilter"
                value={search}
                inputRef={searchRef}
                disabled={globalDisabled}
                onChange={(e) => {
                  setSearch(searchRef.current.value);
                  setPage(0);
                  setCurrentFilter({
                    searchBar: false,
                    option: false,
                    dropdown: false,
                  });
                }}
                onFocus={() => {
                  setEditing(true);
                }}
                onBlur={() => {
                  setEditing(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch(search);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.colorSearchAdornment} />
                    </InputAdornment>
                  ),
                  // endAdornment: (
                  //     <InputAdornment position="end"  >
                  //         <ArrowDropDown color="secondary" />
                  //     </InputAdornment>
                  // )
                }}
                variant="outlined"
                margin="dense"
                fullWidth
              />
              ) : (
                <InputSearch
                  name="CustomersFilter"
                  value={search}
                  inputRef={searchRef}
                  disabled={globalDisabled}
                  onChange={(e) => {
                    setSearch(searchRef.current.value);
                    setPage(0);
                    setCurrentFilter({
                      searchBar: true,
                      option: false,
                      dropdown: false,
                    });
                  }}
                  onFocus={() => {
                    setEditing(true);
                  }}
                  onBlur={() => {
                    setEditing(false);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className={classes.colorSearchAdornment} />
                      </InputAdornment>
                    ),
                    // endAdornment: (
                    //     <InputAdornment position="end"  >
                    //         <ArrowDropDown color="secondary" />
                    //     </InputAdornment>
                    // )
                  }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              )}
          </Grid>
        ) : null}
        {(buttons.menuOrder || buttons.exportButton) ?
          <Grid container item lg={3} md={3} sm={2} justifyContent={!!buttons.menuOrder.justify ? buttons.menuOrder.justify : 'flex-start'} spacing={2} >
            {buttons.menuOrder ? (
              <Grid item container
                lg={buttons.menuOrder.cellSize.lg} md={buttons.menuOrder.cellSize.md}
                sm={buttons.menuOrder.cellSize.sm} justifyContent="center">
                <MenuOrder
                  size="small"
                  disabled={globalDisabled}
                  options={
                    !!buttons.menuOrder
                      ? buttons.menuOrder.options.map((item) => item.label)
                      : []
                  }
                  selectItem={(opt) => {
                    setOption(opt);
                    setPage(0);
                    setSearch("");
                    setCurrentFilter({
                      searchBar: false,
                      option: true,
                      dropdown: false,
                    });
                  }}
                />
              </Grid>
            ) : null}
            {buttons.exportButton ? (
              <Grid item lg={buttons.exportButton.cellSize.lg}
                md={buttons.exportButton.cellSize.md}
                sm={buttons.exportButton.cellSize.sm}>
                <IconEspecialButton
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  size={"small"}
                  onClick={null}
                  disabled={globalDisabled}
                  startIcon={<Launch />}
                >
                  Exportar
                </IconEspecialButton>
              </Grid>
            ) : null}
          </Grid> : null
        }
        {!!component ? (
          <Grid
            container
            item
            lg={config.cellSize.lg}
            md={config.cellSize.lg}
            sm={config.cellSize.lg}
            direction="row"
            alignItems="center"
            justifyContent={!!config.justify ? config.justify : "flex-start"}
            spacing={config.spacing}
          >
            {component()}
          </Grid>
        ) : null}
      </Grid>
      <TableContainer className={classes.container}>
      <Table
          stickyHeader
          aria-label="custom table"
          size="small"
          id='customTableScroll'
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="center"
                  style={{ minWidth: column.minWidth }}
                  className={classes.tableCell}
                  onClick={(evt) => {
                    if (column.haveDropDown) {
                      setSelectedColumn(column.id);
                      const haveBeenOrdered = orderedColumns.find(
                        (item) => item.name === column.id
                      );
                      if (haveBeenOrdered !== undefined) {
                        setOrderedColumns([
                          ...orderedColumns.map((item) =>
                            item === haveBeenOrdered
                              ? {
                                name: haveBeenOrdered.name,
                                naturalOrder: !haveBeenOrdered.naturalOrder,
                              }
                              : item
                          ),
                        ]);
                      } else {
                        setOrderedColumns([
                          ...orderedColumns,
                          { name: column.id, naturalOrder: true },
                        ]);
                      }
                      setCurrentFilter({
                        searchBar: false,
                        option: false,
                        dropdown: true,
                      });
                    }
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography className={classes.columnStyle}>
                      {column.label}
                    </Typography>
                    <Typography className={classes.columnStyle}>
                      {column.haveDropDown ? getDropDownState(column.id) : null}
                    </Typography>
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isEditing
              ? currentData.length > 0
                ? getRowData(currentData)
                : RecordNotFound()
              : currentData.length > 0
                ? getRowData(currentData)
                : RecordNotFound()}
          </TableBody>
        </Table>
      </TableContainer>
      {havePagination ? (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          style={{ display: "flex", justifyContent: "center" }}
          count={isUser ? (rows === null || rows === undefined ? 0 : rows) : filtered.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={isUser ? ((e, newpage) => {handleChangePageUser(e, newpage, rowsPerPage);}) : handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count}`
          }
          labelRowsPerPage="Filas por página"
          nextIconButtonText="Siguiente"
          backIconButtonText="Anterior"
        />
      ) : null}
    </Paper>
  );
};

CustomTable.propTypes = {
  data: PropTypes.array,
  paramToDisable: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
      wordLength: PropTypes.number,
      label: PropTypes.string,
      align: PropTypes.string,
      haveDropDown: PropTypes.bool,
      format: PropTypes.func,
      children: PropTypes.func,
      tooltipMessage: PropTypes.string,
      withIcon: PropTypes.bool,
      handleIconClick: PropTypes.func,
      component: PropTypes.shape({
        handleChange: PropTypes.func,
      }),
    })
  ),
  havePagination: PropTypes.bool,
  isUser: PropTypes.bool,
  buttons: PropTypes.shape({
    menuOrder: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          columns: PropTypes.arrayOf(
            PropTypes.shape({
              prop: PropTypes.string,
              direction: PropTypes.number,
            })
          ),
        })
      ),
    }),
    children: PropTypes.elementType,
  }),
  handleCellClick: PropTypes.func,
};

export default CustomTable;
