import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    useTheme,
    Typography
} from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import AuthenticationLayoutUkoBeMyself from '../../../components/AuthenticationLayoutUkoBeMyself/AuthenticationLayoutUkoBeMyself';
import PrimaryButtonUkoBeMyself from '../../../components/PrimaryButtonUkoBeMyself.component';
import { withStyles } from "@material-ui/core/styles";
import { getVersionAppAction } from "../../../actions/applications.action";
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import { dispatchUsers, resetPassword } from '../../../actions/auth.action';


const SelectAccountsUko = props => {

    const {
        getVersionAppAction,
        getVersionApp,
        appName,
        resetPassword,
        userList,
        tenant,
    } = props;

    const SeaWater = withStyles((theme) => ({
        root: {
            color: theme.selectAccount.colorRoot,
            '&$checked': {
                color: theme.selectAccount.colorChecked,
            },
        },
        checked: {},
    }))((props) => <Radio color="default" {...props} />);

    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = React.useState('');
    const [versionApp, setVersionApp] = useState();
    const [isLoading, setisLoading] = useState(false);
    const [t] = useTranslation("global");

    /**
     * Extract first element in list an select it
     */
    useEffect(() => {
        getVersionAppAction(appName, tenant);
        if (userList?.data?.length === undefined) {
            history.push({
                pathname: "/login",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getVersionApp !== null && getVersionApp !== undefined) {
            setVersionApp(getVersionApp.version);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getVersionApp]);

    const handleChange = (event) => {
        setCurrentUser((event.target).value);
    };

    useEffect(() => {
        if (userList?.data?.length > 0) {
            setCurrentUser(userList?.data[0]?.userName);
        }
    }, [userList]);

    const handleClickButton = () => {
        let userData = {
            user: currentUser,
            appname: appName,
            isInvalidLinkScreen: false,
            isByUser: true,
        };
        setisLoading(true);
        resetPassword(userData, history, '', tenant);
    }

    const onClickLogIn = () => {
        dispatchUsers(undefined, dispatch);
        history.push('/login');
    }

    return (
        <AuthenticationLayoutUkoBeMyself title={t("select-accounts.user-want-reset")} logoSignIn={theme.urlLogo.urlSignIn} >
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={currentUser}
                    onChange={handleChange}
                    className={classes.radioGroup}
                >
                    {
                        userList.data?.length > 0 ? (
                            userList.data.map((item) => {
                                return (
                                    <FormControlLabel key={item.userName} value={item.userName} control={<SeaWater checkedIcon={<CheckCircleIcon />} />} label={item.userName} />
                                );
                            })
                        ) : (
                            <h2 style={{ color: "white" }}>{t("select-accounts.users-not-found")}</h2>
                        )
                    }
                </RadioGroup>
            </FormControl>
            <div>
                <br />
            </div>
            <PrimaryButtonUkoBeMyself
                txtBtn={t("select-accounts.restore-password")}
                //disabled={disabledButton}
                loading={isLoading}
                onClick={handleClickButton}
            />
            <div>
                <br />
            </div>
            <Typography
                className={classes.typographyGoBack}
            >
                <Link
                    to="/login"
                    className={classes.linkGoBack}
                    onClick={onClickLogIn}
                >
                    {t("select-accounts.link-sign-in")}
                </Link>
            </Typography>
            <Box className={classes.version}>
                <p>{versionApp}</p>
            </Box>
        </AuthenticationLayoutUkoBeMyself>
    )
}

SelectAccountsUko.propTypes = {
    appName: PropTypes.string.isRequired,
    resetPassword: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    getVersionApp: state.applicationsReducer.getVersionApp,
    getUserList: state.applicationsReducer.getUserList,
    userList: state.authReducer.userList,
});

const mapDispatchToProps = {
    getVersionAppAction,
    resetPassword,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAccountsUko);

const useStyles = makeStyles((theme) => ({
    radioGroup: {
        color: '#BEC8C9',
        maxHeight: '300px'
    },
    linkGoBack: {
        textDecoration: "underline",
        color: theme.linkUko.colorLink,
        marginBottom: '2px',
        fontSize: '15px',
        fontWeight: 700,
        "&:hover": {
            color: theme.linkUko.colorLink,
        },
    },
    version: {
        fontSize: '14px',
        fontWeight: 600,
        color: theme.versionUko.colorVersionLabel,
        [theme.breakpoints.up("md")]: {
            position: 'absolute',
        },
        bottom: '15px',
        left: '0',
        right: '0',
        textAlign: 'center',
        position: 'absolute',
    }
}));

