import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, Grid, makeStyles, Link as LinkMaterial } from "@material-ui/core";
import PrimaryButtonUserCertificate from "../../../components/primaryButtonUserCertificate.component";
import { isEmpty } from "../../../utils/proprietaryHooks";

import { validateMailCodeAction, checkIsValidEmailAction, ResetPasswordUserCertificateAction } from "../../../actions/auth.action";

import Typography from "@material-ui/core/Typography";

export const ValidationCodeCertificate = (props) => {

  const {
    onCancelCode,
    formData,
    setShowLogo,
    validateMailCodeAction,
    validateEmailCode,
    checkIsValidEmailAction,
    ResetPasswordUserCertificateAction,
    resetPasswordUserCertificate
  } = props;

  const validationSchema = Yup.object().shape({
      code: Yup.string().required("Campo obligatorio").matches(/^[0-9]{6,6}$/, "Ingresa un código numérico de 6 caracteres."),
  });

  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isResendEmail, setIsResendEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [getErrors, setErrors] = useState('');

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: ({
        code: "",
    }),
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const classes = useStyles();

  useEffect(() => {
    setLoading(false);
    setErrors('');
    setLoadingResend(false);
  }, []);

  useEffect(() => {
    if (validateEmailCode) {
      if (validateEmailCode.statusCode === 200) {
        async function resetPassword() {
          await ResetPasswordUserCertificateAction(formData[0].username);
        }
        resetPassword();
      } else {
        setErrors(validateEmailCode?.response?.data?.result?.information);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEmailCode]);

  useEffect(() => {
    if (!isEmpty(resetPasswordUserCertificate)) {
      if (resetPasswordUserCertificate.statusCode === 200) {
        setIsPasswordReset(true);
        setIsResendEmail(false);
        setShowLogo({
          passwordReset: true,
          resendEmail: false,
        });
        setErrors('');
      } else {
        setErrors(resetPasswordUserCertificate.response.data.result.information);
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordUserCertificate]);

  const onCancelValidationCode = () => {
    setValue("code", "");
    onCancelCode();
  };

  const onCancelResendEmail = () => {
    reset();
    setIsResendEmail(false);
    setIsPasswordReset(false);
    setShowLogo({
      passwordReset: false,
      resendEmail: false,
    });
    setValue("code", "");
  };

  const handleResendEmail = async () => {
    setLoadingResend(true);
    await checkIsValidEmailAction({ Email: formData[0].email });
    reset();
    setIsResendEmail(false);
    setIsPasswordReset(false);
    setShowLogo({
      passwordReset: false,
      resendEmail: false,
    });
    setValue("code", "");
  };

  const secureEmail = (email) => {
    let index = email.indexOf('@');
    if (index < 0)
      index = 0;
    let cant = index / 2;
    return "***" + email.substring(index - cant);
  };

  const handleClickResendEmail = () => {
    setIsResendEmail(true);
    setIsPasswordReset(true);
    setShowLogo({
      passwordReset: false,
      resendEmail: true,
    });
  };

  const setValidationCode = async (data) => {
    setLoading(true);
    await validateMailCodeAction(formData[0].email,data.code);
  };

  return (
    <>
    {
      !isPasswordReset ? (
        <Typography style={{ textAlign: "center", color: '#4F4F4F', fontSize: 16 }}>
          Para restablecer tu contraseña debes ingresar el código que te enviamos a <b>{secureEmail(formData[0].email)}</b>. Pudo haber llegado a tu correo no deseado.
        </Typography>
      ) : (
        isResendEmail ? (
          <Typography style={{ textAlign: "center", color: '#4F4F4F', fontSize: 16 }}>
            Enviaremos un nuevo código de verificación a <b>{secureEmail(formData[0].email)}</b>
          </Typography>
        ) : (
          <Typography style={{ textAlign: "center", color: '#4F4F4F', fontSize: 16 }}>
            A partir de este momento deberás ingresar con la contraseña asignada inicialmente a tu certificado.
          </Typography>
        )
      )
    }
    {
      !isPasswordReset ? (
        <>
        <br />
        <form
          id="resetpassword-certificate-code"
          autoComplete="off"
        >
        <Controller
          as={(
            <TextField
              size="medium"
              variant="outlined"
              placeholder="Ingresar código"
              required
              fullWidth
              FormHelperTextProps={{ style: { marginRight: 10 } }}
              helperText={errors.code ? errors.code.message : ''}
              InputProps={{
                classes
              }}
            />
          )}
          name="code"
          control={control}
          error={Boolean(errors.code)}
          defaultValue=""
          inputProps={{
            maxLength: 6,
            style: { textAlign: 'center', fontSize: '23px', fontWeight: 700, borderRadius: "9.63px" },
          }}
        />
        {
          getErrors && (
            <Typography style={{color: '#d61103', fontFamily: 'Muli, Arial', fontSize: 13, textAlign: 'center'}}>
              {getErrors}
            </Typography>
          )
        }
        <Grid container spacing={3} style={{ margin: "15px" }}>
            <Grid container item xs={5}>
            <PrimaryButtonUserCertificate
                txtBtn={"Regresar"}
                onClick={onCancelValidationCode}
            />
            </Grid>
            <Grid container item xs={5}>
              <PrimaryButtonUserCertificate
                txtBtn={"Continuar"}
                nameComponent={"resetPasswordCertificate"}
                loading={loading}
                onClick={handleSubmit(setValidationCode)}
              />
            </Grid>
        </Grid>
        <LinkMaterial onClick={handleClickResendEmail}>
          <Typography
          style={{ fontSize: "16px", textDecorationLine: "underline", cursor: "pointer", fontWeight: 600, textAlign: "center" }}
          >
          ¿No has recibido el email?
          </Typography>
        </LinkMaterial>
        </form>
        </>
      ) : (
        isResendEmail ? (
          <Grid container spacing={3} style={{ margin: "15px" }}>
            <Grid container item xs={5}>
              <PrimaryButtonUserCertificate
                txtBtn={"Regresar"}
                onClick={onCancelResendEmail}
              />
            </Grid>
          <Grid container item xs={5}>
            <PrimaryButtonUserCertificate
              txtBtn={"Enviar"}
              loading={loadingResend}
              onClick={handleResendEmail}
            />
          </Grid>
        </Grid>
        ) : (
          <>
          <br />
            <Link to="/Login" onClick={() => window.location.reload()}>
              <PrimaryButtonUserCertificate
                txtBtn={"Entendido"}
              />
            </Link>
          </>
        )
      )
    }
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "9.63px",
    "& .MuiInputBase-input::placeholder": {
      fontSize: "16px",
    },
  },
}));

ValidationCodeCertificate.propTypes = {
  validateMailCodeAction: PropTypes.func.isRequired,
  checkIsValidEmailAction: PropTypes.func.isRequired,
  ResetPasswordUserCertificateAction: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
  validateEmailCode: state.authReducer.validateEmailCode,
  resetPasswordUserCertificate: state.authReducer.resetPasswordUserCertificate,
});

export default connect(mapStateToProps, {validateMailCodeAction, checkIsValidEmailAction, ResetPasswordUserCertificateAction} )(ValidationCodeCertificate);
