import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import update from "react-addons-update";
import {
  createPrivilege,
  modifyPrivilege,
  getApplicationById,
} from "../../actions/privileges.action";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import { FormControl, Radio, RadioGroup } from "@material-ui/core";
import SwitchForm from "../../components/Switch/switchForm.component";
import FormLayout from "../../components/layouts/FormLayout";
import CustomTable from "../../components/Table/CustomTable.component";
import { ButtonSaveChanges } from "../../components/ButtonForm/ButtonSaveChanges";
import { validateEquals } from "../../utils/proprietaryHooks";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { isEmpty } from "../../utils/proprietaryHooks";
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";

/**
 * PrivilegeForm Component ( full view for form to create/modify Privilege )
 *
 * @export Class Component
 * @class PrivilegeForm
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: "10px 15px",
  },
  paperTable: {
    width: "100%",
    border: `2px solid ${theme.palette.primary.light}`,
  },
  container: {
    maxHeight: 440,
  },
  searchTextField: {
    maxHeight: "25px",
    padding: "10px 23px",
    marginBottom: "12px",
  },
  buttonSave: {
    margin: "15px 3px 20px 3px",
    flex: 1,
    float: "right",
    background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
    boxShadow: "0px 4px 3px #0000004D",
    borderRadius: 10,
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main + "D4",
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  errorText: {
    padding: "10px 15px",
  },
}));

const PrivilegeForm = ({
  history,
  Id,
  privilegeInfo,
  setCardState,
  updateChecked,
  modifyPrivilege,
  createPrivilege,
  getApplicationById,
  getPrivilegeChangedResponse,
  getApplicationsResponse,
  setPrivilegeErrorResponse,
  setPrivilegeId,
  setLoading,
}) => {
  const classes = useStyles();
  const privilegeId = Id;
  const initialStateForm = {
    id: 0,
    name: "",
    description: "",
    idstate: 0,
    licence: false,
    isAuditable: false,
    application: [],
  };
  const [formPrivData, setFormPrivData] = useState(
    !isEmpty(privilegeInfo)
      ? {
          id: privilegeInfo.id,
          name: privilegeInfo.name,
          description: privilegeInfo.description,
          idstate: privilegeInfo.idstate === 0 ? true : false,
          licence: privilegeInfo.licence,
          isAuditable: privilegeInfo.isAuditable,
          application: [],
        }
      : initialStateForm
  );
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
  });
  const [applications, setApplications] = useState([]);
  const [, forceUpdate] = useState();
  const [statePrivilege] = useState(true);
  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().max(50).required(requiredMessage),
    description: Yup.string(),
    licence: Yup.boolean(),
    isAuditable: Yup.boolean(),
  });
  const {
    handleSubmit,
    errors,
    formState,
    trigger,
    getValues,
    setValue,
    control,
  } = useForm({
    defaultValues: { ...formPrivData },
    resolver: yupResolver(validationSchema),
  });

  //#endregion
  const [formContent, setFormContent] = useState({
    value: "Por credenciales",
    values: [
      { name: "Por credenciales", disabled: false },
      { name: "Validación 1", disabled: true },
      { name: "Validación 2", disabled: true },
      { name: "Validación 3", disabled: true },
    ],
  });
  const [allRoles, setAllRoles] = useState(false);
  const [viewErrors, setErrors] = useState("");

  // const [charactersToEnd, setCharactersToEnd] = useState(!!privilegeInfo.description ? 100-privilegeInfo.description.length : 100);
  const [charactersToEnd] = useState(
    !!privilegeInfo.description ? 100 - privilegeInfo.description.length : 100
  );
  const [privilegeOption, setPrivilegeOption] = useState(null);
  const [enabledForm, setEnabledForm] = useState(
    !isEmpty(privilegeInfo) ? false : true
  );
  const originalData = useRef({ originalData: formPrivData, originalApp: {} });
  const dispatch = useDispatch();
  const [isCancelEdit, setIsCancelEdit] = useState(false);
  const columns = [
    {
      id: "title",
      label: "Aplicacion",
      minWidth: 140,
      haveDropDown: true,
      align: "left",
      internalRow: {
        align: "left",
        marginLeft: "20px",
      },
    },
    {
      id: "assigned",
      label: "Estado",
      haveDropDown: true,
      minWidth: 90,
      align: "center",
      component: {
        handleChange: (e, value, id, row) => handleConfirmApplication(e, row),
      },
      tooltipMessage: "privilegio",
      format: (value) => value,
    },
  ];

  /**
   *
   * @param {*} e  corresponde al evento, del cual se va a extraer la información contenida en el formulario
   * se verifica que cumpla con las condiciones de negocio para guardarlo
   * el filtrado se realiza para saber si tiene al menos una aplicación seleccionada
   */
  const savePrivilege = async (e) => {
    if (!!e) {
      e.preventDefault();
    }
    setErrors("");
    await trigger();
    let selectedApps = applications.filter(
      (app) => app.assigned === true
    ).length;
    if (isEmpty(formState.errors) && selectedApps > 0) {
      setLoading(true);
      let privilegeData = {};
      let appList = getApplications();
      if (privilegeId === 0) {
        const values = getValues();
        privilegeData = {
          licence: values.licence === undefined ? false : values.licence,
          application: appList,
          name: values.name,
          description:
            values.description === undefined ? "" : values.description,
          isAuditable:
            values.isAuditable === undefined ? false : values.isAuditable,
        };
        createPrivilege(
          privilegeData,
          history,
          updateChecked,
          "IdState",
          true,
          restartFields
        );
      } else {
        privilegeData = createObjectModify(appList);
        if (!validateEquals(originalData.current.originalData, privilegeData)) {
          modifyPrivilege(
            privilegeData,
            history,
            updateChecked,
            "IdState",
            false,
            restartFields
          );
        } else {
          NotChange();
        }
      }
      setConfirmInactivate({
        ...confirmInactivate,
        open: false,
      });
    } else if (selectedApps === 0) {
      setErrors("Se requiere minimo una aplicación");
    }
  };

  const NotChange = (roleSaveData) => {
    dispatch({
      type: "GET_PRIVILEGE_CHANGED",
      payload: "No hay cambios para guardar",
    });
    setLoading(false);
  };

  const createObjectModify = (appList) => {
    const values = getValues();
    return {
      idstate: statePrivilege,
      id: privilegeId,
      licence: values.licence === undefined ? false : values.licence,
      application: appList,
      name: values.name,
      description: values.description === undefined ? "" : values.description,
      isAuditable:
        values.isAuditable === undefined ? false : values.isAuditable,
    };
  };

  const getApplications = (data) => {
    let appList = [];
    if (data !== undefined) {
      data.forEach(function (app) {
        if (app.assigned === true) {
          appList.push(app.name);
        }
      });
    } else {
      applications.forEach(function (app) {
        if (app.assigned === true) {
          appList.push(app.name);
        }
      });
    }
    return appList;
  };

  const backButton = () => {
    setIsCancelEdit(false);
    if (enabledForm && privilegeId !== 0) {
      let appList = getApplications();
      let privilegeData = createObjectModify(appList);
      if (
        !validateEquals(originalData.current.originalData, privilegeData) &&
        enabledForm
      ) {
        setConfirmInactivate({
          ...confirmInactivate,
          open: true,
          message: (
            <div>
              Hiciste modificaciones
              <br />
              ¿Deseas actualizarlas?
            </div>
          ),
          showBtnAccept: true,
          showBtnCancel: true,
        });
      } else {
        setCardState(false);
        restartFields();
        forceUpdate();
      }
    } else {
      setCardState(false);
      restartFields();
      forceUpdate();
    }
  };
  useEffect(() => {
    if (!!getPrivilegeChangedResponse) {
      if (
        getPrivilegeChangedResponse ===
        "El privilegio fue modificado exitosamente"
      ) {
        originalData.current = {
          originalData: {
            ...originalData.current.originalData,
            ...getValues(),
            application: getApplications(),
          },
          originalApp: applications,
        };
      } else if (
        getPrivilegeChangedResponse ===
          "El privilegio fue creado exitosamente" ||
        getPrivilegeChangedResponse === "No hay cambios para guardar"
      ) {
        restartFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPrivilegeChangedResponse]);

  useEffect(() => {
    // getPrivilegeById(privilegeId);
    getApplicationById(privilegeId);
    setLoading(true);
    handleAllRolesChecked();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privilegeId]);

  useEffect(() => {
    if (!!getApplicationsResponse) {
      const orderList = getApplicationsResponse.sort((a, b) => {
        return a.assigned ? -1 : 1;
      });
      if (
        getApplicationsResponse !== undefined &&
        getApplicationsResponse !== null &&
        getApplicationsResponse.length > 0
      ) {
        originalData.current = {
          originalData: {
            ...originalData.current.originalData,
            application: getApplications(orderList),
          },
          originalApp: orderList,
        };
        setLoading(false);
      }
      setApplications(orderList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationsResponse]);

  useEffect(() => {
    if (
      !Array.isArray(setPrivilegeErrorResponse) &&
      !!setPrivilegeErrorResponse
    ) {
      setLoading(false);
      setErrors(setPrivilegeErrorResponse.result.information);
    }
  }, [setLoading, setPrivilegeErrorResponse]);

  useEffect(() => {
    return () => {
      restartFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmApplication = (event, row) => {
    var index = applications.findIndex((element) => element.name === row.name);
    setApplications(
      update(applications, {
        [index]: {
          $merge: { assigned: event.target.checked },
        },
      })
    );
    if (event.target.checked) {
      let selected = applications.filter((item) => item.assigned === true);
      if (selected.length === applications.length - 1) {
        setAllRoles(true);
      }
    } else {
      setAllRoles(false);
    }
  };

  /**
   *  administrar el valor de los radios de identidad
   * @param {*} event
   */
  const handleChangeRadios = (event) => {
    setFormContent({
      ...formContent,
      value: event.target.value,
    });
  };

  /**
   * @param {*} event
   * evento de administración de cambios de estado en los roles
   */
  const handleAllRolesChecked = (event) => {
    if (event === undefined) {
      const checked = applications.filter((item) => item.assigned === true);
      if (checked.length === applications.length && applications.length !== 0) {
        setAllRoles(true);
        //  setEnabledForm(true)
      } else {
        setAllRoles(false);
      }
    } else {
      const checked = event.target.checked;
      applications.map((item) => (item.assigned = checked));
      setApplications([...applications]);
      setAllRoles(checked);
    }
  };

  const restartFields = () => {
    if (!isCancelEdit || privilegeId === 0) {
      setPrivilegeId(0);
      setCardState(false);
      applications.map((item) => (item.assigned = false));
      setApplications([]);
      setAllRoles(false);
      setErrors("");
      dispatch({
        type: "GET_PRIVILEGE_CHANGED",
        payload: [],
      });
      dispatch({
        type: "SET_PRIVILEGE_ERROR",
        payload: [],
      });
      dispatch({
        type: "GET_APPLICATIONS",
        payload: [],
      });
      dispatch({
        type: "GET_PRIVILEGE",
        payload: [],
      });
      setFormPrivData(initialStateForm);
      setEnabledForm(true);
      originalData.current = {
        originalData: initialStateForm,
        originalApp: {},
      };
    }
  };

  const buttons = {
    justify: "space-between",
    searchBar: {
      cellSize: {
        lg: 7,
        md: 7,
        sm: 7,
      },
    },
    children: () => {
      const config = {
        cellSize: {
          lg: 4,
          md: 5,
          sm: 2,
        },
      };
      const component = () => (
        <>
          <Grid container item lg={8} md={8} sm={6} justifyContent="flex-start">
            <Box flexWrap="wrap">
              <Typography variant="subtitle2" align="right">
                Asignar todas las aplicaciones
              </Typography>
            </Box>
          </Grid>
          <Grid container item lg={4} md={4} sm={12} justifyContent="flex-end">
            <Tooltip title="Seleccionar todas las aplicaciones">
              <FormControlLabel
                control={
                  <SwitchForm
                    name="allRoles"
                    disabled={!enabledForm}
                    onChange={handleAllRolesChecked}
                    checked={allRoles}
                  />
                }
              />
            </Tooltip>
          </Grid>
        </>
      );

      return { config, component };
    },
  };

  const handleChangeEdit = (response) => {
    if (!response) {
      let appList = getApplications();
      let privilegeData = createObjectModify(appList);
      if (!validateEquals(originalData.current.originalData, privilegeData)) {
        setIsCancelEdit(true);
        setConfirmInactivate({
          ...confirmInactivate,
          open: true,
          message: (
            <div>
              Hiciste modificaciones
              <br />
              ¿Deseas actualizarlas?
            </div>
          ),
          showBtnAccept: true,
          showBtnCancel: true,
        });
      }
    }
    setEnabledForm(response);
  };

  const handleCancel = () => {
    if (enabledForm) {
      setCardState(false);
      restartFields();
      forceUpdate();
    }
    SetPrivilegeValue(originalData.current.originalData);
    setApplications(originalData.current.originalApp);
    setConfirmInactivate({
      ...confirmInactivate,
      open: false,
      message: "",
      item: "",
      checked: false,
    });
  };

  const SetPrivilegeValue = useCallback(
    (data) => {
      if (isEmpty(data)) {
        data = formPrivData;
      }
      for (const key in data) {
        setValue(key, formPrivData[key]);
      }
    },
    [setValue, formPrivData]
  );

  return (
    <FormLayout
      subheader={
        !!privilegeId
          ? enabledForm
            ? " Editar Privilegio"
            : " Detalles del privilegio"
          : " Nuevo Privilegio"
      }
      handleIconClick={() => {
        backButton();
        forceUpdate(0);
      }}
      isEditing={privilegeId !== 0}
      handleSubmit={(e) => {
        e.preventDefault();
        setIsCancelEdit(false);
        handleSubmit(savePrivilege(e));
      }}
      isPrivilege={true}
      handleCancel={handleCancel}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      handleAccept={savePrivilege}
      enabledForm={enabledForm}
      setEnabledForm={handleChangeEdit}
    >
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={1}
      >
        <Grid item lg={5} sm={12}>
          <div className={classes.paper}>
            <ControlledInputRoundedForm
              id="name"
              name="name"
              inputProps={{ maxLength: 60 }}
              label="Nombre Privilegio"
              fullWidth
              control={control}
              pattern={/[^a-zA-Z0-9_]/g}
              disabled={!!privilegeId ? true : false}
              error={errors.name}
              helperText={errors.name?.message}
            />
            <ControlledInputRoundedForm
              id="description"
              name="description"
              label="Descripción Privilegio"
              multiline
              rows={5}
              inputProps={{ maxLength: 100 }}
              helperText={charactersToEnd}
              fullWidth
              disabled={enabledForm ? false : true}
              control={control}
            />
            <Grid>
              <FormControlLabel
                className={classes.textField}
                label="Tiene licencia"
                variant="outlined"
                margin="dense"
                labelPlacement="start"
                control={
                  <Tooltip
                    title="Activar/Inactivar"
                    aria-label="Activar/Inactivar"
                  >
                    <ControlledSwitchComponent
                      id="licence"
                      name="licence"
                      color="primary"
                      isDisabled={!enabledForm}
                      control={control}
                    />
                  </Tooltip>
                }
              />
              <FormControlLabel
                className={classes.textField}
                label="Es Auditable"
                variant="outlined"
                margin="dense"
                labelPlacement="start"
                control={
                  <Tooltip
                    title="Activar/Inactivar"
                    aria-label="Activar/Inactivar"
                  >
                    <ControlledSwitchComponent
                      id="isAuditable"
                      name="isAuditable"
                      color="primary"
                      isDisabled={!enabledForm}
                      control={control}
                      checked={getValues.isAuditable}
                    />
                  </Tooltip>
                }
              />
            </Grid>
            <hr />
            <Typography color="inherit" variant="h6">
              Validacion de identidad
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="identidad"
                name="identidad"
                value={formContent.value}
                onChange={handleChangeRadios}
              >
                {formContent.values.map((value, index) => (
                  <FormControlLabel
                    disabled={value.disabled}
                    key={index}
                    value={value.name}
                    control={<Radio />}
                    label={value.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <hr />
            <Grid item lg={12} md={12} sm={12}>
              {viewErrors !== "" && (
                <div className={classes.errorText}>
                  <Typography color="error" variant="subtitle2">
                    {viewErrors}
                  </Typography>
                </div>
              )}
            </Grid>
          </div>
        </Grid>

        <Grid
          container
          lg={7}
          item
          sm={12}
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={1}
        >
          <Paper className={classes.paperTable}>
            <CustomTable
              columns={columns}
              data={applications}
              buttons={buttons}
              mainParam={"name"}
              havePagination={false}
              handleChange={handleConfirmApplication}
              option={privilegeOption}
              setOption={setPrivilegeOption}
              globalDisabled={!enabledForm}
            />
          </Paper>
        </Grid>

        <Grid item lg={12}>
          <ButtonSaveChanges
            type="submit"
            id="buttonSave"
            style={classes.buttonSave}
            margin="dense"
            title={privilegeId === 0 ? "Guardar" : "Actualizar"}
            isDisabled={!enabledForm}
          />
        </Grid>
      </Grid>
    </FormLayout>
  );
};

PrivilegeForm.propTypes = {
  createPrivilege: PropTypes.func.isRequired,
  modifyPrivilege: PropTypes.func.isRequired,
  getPrivilegeById: PropTypes.func.isRequired,
  getApplicationById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getPrivilegesResponse: state.privilegesReducer.getPrivilegesResponse,
  getApplicationsResponse: state.privilegesReducer.getApplicationsResponse,
  setPrivilegeErrorResponse: state.privilegesReducer.setPrivilegeErrorResponse,
  getPrivilegeChangedResponse:
    state.privilegesReducer.getPrivilegeChangedResponse,
});

const mapDispatchToProps = {
  createPrivilege,
  modifyPrivilege,
  getApplicationById,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivilegeForm);
