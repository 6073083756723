import { GET_ALL_SUBJECTS } from "../actions/types";

const initialState = {
  getAllSubjects: []
};

export default function subjectReducer (state = initialState, action) {
  switch (action.type) {
    
    case GET_ALL_SUBJECTS:
      return {
        ...state,
        getAllSubjects: action.payload
      };

    default:
      return state;
  }
}