import {
  GET_ROLE,
  GET_USER_CHANGED,
  SET_USER_ERROR,
} from "../actions/types";

const initialState = {
  optResponseCode: [],
  optResponseData: []
};

/**
 * reducerOtp (set reducer otp)
 *
 * @export function
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns {state, boolean, user}
 */
export default function resetOtpReducer (state = initialState, action) {
  switch (action.type) {
    case GET_ROLE:
      return {
        ...state,
        optResponseCode: action.payload,
      };
    case GET_USER_CHANGED:
      return {
        ...state,
        optResponseData: action.payload,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        optResponseData: action.payload,
      };
    default:
      return state;
  }
}
