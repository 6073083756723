import {
  alpha,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SearchIcon from "@material-ui/icons/Search";
import Button from '@material-ui/core/Button';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GET_UPLOAD_IMAGE_MENU } from "../../actions/types";
import Box from '@material-ui/core/Box';
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import CustomModal from "../../components/Modal/modalLogin";
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";
import ProgressBar from '../../components/Progress/ProgressBar';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "../../utils/proprietaryHooks";
import { PrimaryButton } from "../../components/ButtonForm/PrimaryButton";
import { uploadImageMenuAction } from '../../actions/menu.action';

export const ModalFormSuite = forwardRef((props, ref) => {
  const {
    initialValues,
    open,
    setOpen,
    handleSave,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const classes = useStyles();
  const [fileSupport, setFileSupport] = useState([]);
  const [fileDark, setFileDark] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [disabledInputSupport, setDisabledInputSupport] = useState(false);
  const [disabledInputDark, setDisabledInputDark] = useState(false);
  const [disabledButtonIcon, setDisabledButtonIcon] = useState(false);
  const [disabledButtonIconDark, setDisabledButtonIconDark] = useState(false);
  const [invalidImage, setInvalidImage] = useState('');
  const [startBarProgress, setStartBarProgress] = useState(false);
  const [startBarProgressDark, setBarProgressDark] = useState(false);
  const [fileNameSupport, setFileNameSupport] = useState('');
  const [fileNameDark, setFileNameDark] = useState('');
  const [isLight, setIsLight] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const menuImageResponse = useSelector(
    ({ menuReducer }) => menuReducer.getUploadImageMenu
  );
  const [header, setHeader] = useState({
    tab0: {
      header: "Nuevo ítem",
      subtitle: "Ingresa los datos del ítem",
    },
  });

  const requiredMessage = "Campo obligatorio";

  const schema = yup.object().shape({
    name: yup.string().required(requiredMessage),
    urlLanding: yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingrese una url correcta!"
    )
    .required(requiredMessage),
    urlApplication: yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingrese una url correcta!"
    )
    .required(requiredMessage),
    description: yup.string(),
    sizeIcon: yup.number(),
  });
  const form = useForm({
    defaultValues: initialValues,
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });
  const {
    control,
    errors,
    trigger,
    getValues,
    handleSubmit,
    formState,
    setValue,
    watch,
    clearErrors,
    reset,
  } = form;

  /**
   * set the values to inputs when view is triggered
   */
  useEffect(() => {
    if (initialValues && !isEmpty(initialValues.name)) {
      Object.keys(initialValues).forEach((key) => {
        setValue(key, initialValues[key]);
      });
      setFileNameSupport(initialValues.iconName);
      setFileNameDark(initialValues.iconNameDark);
      setHeader({
        tab0: {
          header: `Edición - ${initialValues["name"]}`,
          subtitle: "Modifica los datos del ítem",
        },
      });
    } else {
      if (initialValues) {
        setHeader({
          tab0: {
            header: "Nuevo ítem",
            subtitle: "Ingresa los datos del ítem",
          },
        });
        setFileNameSupport('');
        setFileNameDark('');
        Object.keys(initialValues).forEach((key) => {
          setValue(key, initialValues[key]);
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, setValue]);

  useEffect(() => {
    const errorCount = Object.getOwnPropertyNames(formState.errors);

    if (errorCount?.length > 0) setDisabledButton(true);
    
    if (!watch("name") || !watch("urlLanding") || !watch("urlApplication")) {
      setDisabledButton(true);
    } else if (errorCount?.length === 0) {
      setDisabledButton(false);
    }
    if (!disabledButtonIcon || !disabledButtonIconDark) setDisabledButton(true);

    if (initialValues && !isEmpty(initialValues.name)) {
      if (!isEmpty(initialValues.iconName) && !isEmpty(initialValues.iconNameDark)) {
        setDisabledButton(false);
        if (errorCount?.length > 0) setDisabledButton(true);
        if (!watch("name") || !watch("urlLanding") || !watch("urlApplication")) {
          setDisabledButton(true);
        } else if (errorCount?.length === 0) {
          setDisabledButton(false);
        }
      }
      if (fileSupport.length) {
        if (!disabledButtonIcon) setDisabledButton(true);
      }
      if (fileDark.length) {
        if (!disabledButtonIconDark) setDisabledButton(true);
      }
    }

  }, [disabledButtonIcon, disabledButtonIconDark, fileDark.length, fileSupport.length, formState, initialValues, startBarProgress, watch]);

  useEffect(() => {
    if (fileSupport.length && !startBarProgress) {
      dispatch(uploadImageMenuAction({
        ApplicationName: "Ibuhoo",
        Extension: `.png`,
        ImageBase64: fileSupport[0].uploaded_file,
      }));
    }
  }, [dispatch, fileSupport, startBarProgress]);

  useEffect(() => {
    if (fileDark.length && !startBarProgressDark) {
      dispatch(uploadImageMenuAction({
        ApplicationName: "Ibuhoo",
        Extension: `.png`,
        ImageBase64: fileDark[0].uploaded_file,
      }));
    }
  }, [dispatch, fileDark, startBarProgressDark]);

  useEffect(() => {
    if (!isEmpty(menuImageResponse)) {
      if (isLight) {
        setDisabledButtonIcon(true);
        setDisabledInputDark(false);
        initialValues.iconName = menuImageResponse;
        setValue("iconName", menuImageResponse);
      } else {
        setDisabledButtonIconDark(true);
        setDisabledInputSupport(false);
        initialValues.iconNameDark = menuImageResponse;
        setValue("iconNameDark", menuImageResponse);
      }
      dispatch({
        type: GET_UPLOAD_IMAGE_MENU,
        payload: {},
      });
    }
  }, [dispatch, initialValues, isLight, menuImageResponse, setValue]);

  const handleClickModal = async () => {
    await trigger();
    if (!isEmpty(formState.errors)) {
      return;
    }
    const isEdit = initialValues && initialValues.name !== "" ? "Edit" : undefined;
    const itemValues = getValues();
    itemValues.iconName = initialValues.iconName;
    itemValues.iconNameDark = initialValues.iconNameDark;
    setDisabledButtonIcon(false);
    setDisabledButtonIconDark(false);
    setFileSupport([]);
    setFileDark([]);
    setDisabledInputDark(false);
    setDisabledInputSupport(false);
    handleSave(isEdit, itemValues);
  };

  const handleFileSelected = (event) => {
    const file = event.target.files[0];
    const { id } = event.target;

    setInvalidImage('');
    // Get the file Id
    const file_reader = new FileReader();
    if (!file.name.toLowerCase().match(/\.(png)$/)) {
      setInvalidImage('Debe cargar un archivo valido (.png).');
      return false;
    }
    if (file.name.length > 200) {
      setInvalidImage('El nombre del archivo seleccionado no es valido.');
      return false;
    }
    if (file.size > 2097152) {
      setInvalidImage('El tamaño del archivo supera el máximo permitido (2MB).');
      return false;
    }
    if (id === 'light') {
      setDisabledInputDark(true);
      setDisabledButtonIcon(false);
      setIsLight(true);
      setStartBarProgress(true);
      setFileNameSupport('');
    }
    if (id === 'dark') {
      setDisabledInputSupport(true);
      setDisabledButtonIconDark(false);
      setIsLight(false);
      setBarProgressDark(true);
      setFileNameDark('');
    }
    file_reader.onload = () => {
      // After uploading the file
      // appending the file to our state array
      // set the object keys and values accordingly
      if (id === 'light') {
        setFileSupport([{
          file_id: id, file_name: file.name, uploaded_file: file_reader.result, supportType_file: 'LightSupport',
        }]);
      }
      if (id === 'dark') {
        setFileDark([{
          file_id: id, file_name: file.name, uploaded_file: file_reader.result, supportType_file: 'DarkSupport',
        }]);
      }
    };
    if (id === 'light') {
      setFileNameSupport(file !== null && file.name !== null ? file.name : '');
      setTimeout(() => {
        if (file && file.name !== '') {
          setStartBarProgress(false);
        }
      }, 2000);
    }
    if (id === 'dark') {
      setFileNameDark(file !== null && file.name !== null ? file.name : '');
      setTimeout(() => {
        if (file && file.name !== '') {
          setBarProgressDark(false);
        }
      }, 2000);
    }
    // reading the actual uploaded file
    file_reader.readAsDataURL(file);
  };

  /**
   * make body of modal depending tab
   * @returns {JSX.Element} body
   */
  const getBody = () => {
    let modal = {};
    if (currentTab === 0) {
      modal = {
        header: (
          <Typography
            variant="h6"
            color="primary"
            className={classes.modalHeader}
          >
            {header.tab0.header}
          </Typography>
        ),
        body: (
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.bodyTitle}
              >
                {header.tab0.subtitle}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledInputRoundedForm
                id="name"
                fullWidth
                control={control}
                name="name"
                label="Nombre ítem"
                error={errors.name}
                helperText={errors.name?.message}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledInputRoundedForm
                id="urlLanding"
                fullWidth
                control={control}
                name="urlLanding"
                label="Url landing"
                error={errors.urlLanding}
                helperText={errors.urlLanding?.message}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledInputRoundedForm
                id="urlApplication"
                fullWidth
                control={control}
                name="urlApplication"
                label="Url aplicación"
                error={errors.urlApplication}
                helperText={errors.urlApplication?.message}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ControlledInputRoundedForm
                id="description"
                fullWidth
                control={control}
                name="description"
                multiline
                rows={5}
                label="Descripción (opcional)"
                error={errors.description}
                helperText={errors.description?.message}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.uploadImage}>
              <label htmlFor="light">
                <input
                  id="light"
                  type="file"
                  accept=".png"
                  onChange={handleFileSelected}
                  style={{ display: 'none' }}
                  disabled={disabledInputSupport}
                />
                  <Button variant="contained" component="span" color="primary" startIcon={<SearchIcon />} className={classes.btnAddSupport} disabled={disabledInputSupport}>
                    <span style={{ color: '#6D6E71', width: '85%', fontSize: '14px', fontWeight: 700 }}>
                      Cargar logo light
                    </span>
                  </Button>
                {invalidImage && (
                  <Typography className={classes.formErrors}>
                    {invalidImage}
                  </Typography>
                )}
              </label>
              <Box style={{ width: '50%', paddingLeft: 15 }}>
                <Typography
                  className={classes.formLabel}
                >
                  {fileNameSupport}
                </Typography>
                <Box style={{ width: '35%' }}>
                  <ProgressBar
                    startBarProgress={startBarProgress}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.uploadImage}>
              <label htmlFor="dark">
                <input
                  id="dark"
                  type="file"
                  accept=".png"
                  onChange={handleFileSelected}
                  style={{ display: 'none' }}
                  disabled={disabledInputDark}
                />
                  <Button variant="contained" component="span" color="primary" startIcon={<SearchIcon />} className={classes.btnAddSupport} disabled={disabledInputDark}>
                    <span style={{ color: '#6D6E71', width: '85%', fontSize: '14px', fontWeight: 700 }}>
                      Cargar logo dark
                    </span>
                  </Button>
                {invalidImage && (
                  <Typography className={classes.formErrors}>
                    {invalidImage}
                  </Typography>
                )}
              </label>
              <Box style={{ width: '50%', paddingLeft: 15 }}>
                <Typography
                  className={classes.formLabel}
                >
                  {fileNameDark}
                </Typography>
                <Box style={{ width: '35%' }}>
                  <ProgressBar
                    startBarProgressDark={startBarProgressDark}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              alignItems="center"
            >
              <Grid item lg={2} md={2} xs={3} sm={3}>
                <Typography>Activo</Typography>
              </Grid>
              <Grid item lg={3} md={3} xs={3} sm={3}>
                <ControlledSwitchComponent
                  name="isActivate"
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        ),
        actions: (
          <Grid container direction="row-reverse">
            <Grid container item lg={6} md={6} sm={6} xs={6} justifyContent="flex-end">
              <PrimaryButton
                text={
                  initialValues && initialValues.name !== "" ? "Actualizar ítem" : "Crear item"
                }
                disabled={disabledButton}
                onClick={handleClickModal}
              />
            </Grid>
          </Grid>
        ),
      };
    }
    return modal;
  };

  const restartModal = () => {
    setCurrentTab(0);
  };

  useImperativeHandle(
    ref,
    () => ({
      form: form,
      save: handleClickModal,
      restartModal,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <CustomModal
      open={open}
      handleClose={() => {
        clearErrors();
        handleClose();
        setDisabledButtonIcon(false);
        setDisabledButtonIconDark(false);
        reset();
        setFileSupport([]);
        setFileDark([]);
        setDisabledInputDark(false);
        setDisabledInputSupport(false);
        setOpen(false);
      }}
      //  header={}
      body={
        <div>
          {getBody().header}
          {getBody().body}
        </div>
      }
      actions={getBody().actions}
      width={"503px"}
      handleSubmit={handleSubmit(handleClickModal)}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.secondary.main,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
  modalHeader: {
    fontWeight: theme.typography.fontWeightBold,
  },
  bodyTitle: {
    color: theme.palette.primary.light,
  },
  paperSelectIcon: {
    height: "350px",
    marginBottom: 120,
  },
  closeIcon: {
    color: theme.palette.primary.light,
    fontSize: "15px",
  },
  inputHiddenLabel: {
    color: "#b2b2b2",
  },
  autoComplete: {
    root: {
      border: "1px solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 10,
      backgroundColor: "#FFFFFF",
      boxShadow: `0px 1px 1px #00000026`,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: "#fff",
      },
      "&$focused": {
        backgroundColor: "#fff",
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
      "&$error": {
        borderColor: theme.palette.error.main,
      },
      "& .MuiInputBase-input.Mui-error": {
        borderColor: theme.palette.error.main,
      },
    },
    inputHiddenLabel: {
      color: "#b2b2b2",
    },
    focused: {
      fontSize: 14,
    },
    error: {},
  },
  uploadImage: {
    paddingBottom: 10,
    paddingTop: 19,
    display: 'flex',
  },
  btnAddSupport: {
    "& .MuiButton-startIcon": {
      color: '#009BDD',
    },
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#DBE2E6',
    },
    border: `1px solid #D5D5D5`,
    marginLeft: '0px',
    width: '205px',
    justifyContent: 'space-between',
  },
  formLabel: {
    fontSize: '10px',
  },
}));
