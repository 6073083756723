/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import LayouthInfoUko from '../../../components/AuthenticationLayoutUkoBeMyself/LayouthInfoUkoBeMyself';
import { CHECKEMAIL, ERRORINFO, PASSWORDUPDATED, SENDEMAIL, ASSIGNINITIALPASSWORD } from '../../../actions/auth.action';
import ShowAlertUko from "../../../components/alertUko.component";
import SuccessAlertUko from './SuccessAlertUko';
import { useTheme } from '@material-ui/core';
import { enmaskEmail } from '../../../utils/utils';
import { TIME_REDIRECT_LOGIN } from '../../../config/config';

const InfoUko = (props) => {

    const {
        appName,
        history,
        tenant,
    } = props;

    const CHECKEMAILAGAIN = 'CHECK_EMAIL_AGAIN';
    const INVALIDLINK = 'INVALID_LINK';

    const theme = useTheme();
    const [messageTypeFromHistory, setmessageTypeFromHistory] = useState(history.location.state?.messageType);
    const [messageCheckEmail, setmessageCheckEmail] = useState("");
    const [countdown, setCountdown] = useState(-1);
    const [applyCountdown, setApplyCountdown] = useState(false);
    const [t] = useTranslation("global");

    const cutMessage = (oldMessage) => {
        const indexOfA = oldMessage.indexOf("a ");
        const indexOfComa = oldMessage.indexOf(", ");
        const emailExtract = oldMessage.substring(indexOfA + 1, indexOfComa);

        return <div> <center>{t("info.have-sent-email.label-one")}<b>{emailExtract}</b>{t("info.have-sent-email.label-two")}<br />
            {t("info.have-sent-email.label-three")}</center> </div>;
    }

    const handleClick = () => {
        if (history) {
            history.push('/login');
        }
    }

    const onClickLinkEmail = () => {
        setmessageTypeFromHistory(CHECKEMAILAGAIN);
        setCountdown(60);
    }

    /**
     * 
     * @param {Parametro contador} countdown 
     * @returns retorna tiempo formateado
     */
    const showCountdown = (countdown) => {
        if (countdown.toString().length === 1) {
            return `${'0' + countdown}`;
        }
        else {
            return countdown;
        }
    }

    /**
     * Extract new message with current email from history
     * Remember: history is updated eith backend services
     */
    useEffect(() => {
        const userEmail = history.location.state?.emailFromUser;
        switch (messageTypeFromHistory) {
            case INVALIDLINK:
                setmessageCheckEmail(<div> <center>{t("info.time-countdown-finished.label-one")}<br />{t("info.time-countdown-finished.label-two")}</center> </div>);
                break;
            case CHECKEMAILAGAIN:
                setmessageCheckEmail(<div> <center>{t("info.enmask-email.label-one")}<b>{enmaskEmail(userEmail)}</b><br />
                    {t("info.enmask-email.label-two")}</center> </div>);
                break;
            case ASSIGNINITIALPASSWORD:
                setCountdown(TIME_REDIRECT_LOGIN);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageTypeFromHistory])


    useEffect(() => {
        if (countdown > 0) {
            setTimeout(
                () => setCountdown(countdown - 1),
                1000
            );
            setApplyCountdown(true);
        }

        if (applyCountdown && countdown === 0) {
            switch (messageTypeFromHistory) {
                case ASSIGNINITIALPASSWORD:
                    window.location.replace(history.location.state.pagename);
                    break;
                default:
                    break;
            }
        }
    }, [countdown])

    const renderInfo = (messageType) => {
        switch (messageType) {
            case CHECKEMAIL:
                return (
                    <LayouthInfoUko
                        appName={appName}
                        firstImage={"urlSignIn"}
                        title={t("info.label-check-email")}
                        // secondImage={"urlCheckEmailMoreBlue"}
                        widthOfSecondImage={"306px"}
                        textBeforeEnd={cutMessage(history.location.state.message)}
                        tenant={tenant}
                    >
                    </LayouthInfoUko>
                );
            case SENDEMAIL:
                return (
                    <LayouthInfoUko
                        appName={appName}
                        firstImage={"urlSignIn"}
                        title={t("info.invalid-link.label")}
                        infoText={t("info.invalid-link.re-enter-email")}
                        hasInput={true}
                        tenant={tenant}
                    ></LayouthInfoUko>
                );
            case ERRORINFO:
                return <ShowAlertUko
                    logo={theme.urlLogo.urlSignIn}
                    image={theme.alertUko.imageUko}
                    value={history.location.state?.message}
                    onClick={handleClick}
                    appName={appName}
                />;
            case PASSWORDUPDATED:
                return <SuccessAlertUko tenant={tenant} />;
            case ASSIGNINITIALPASSWORD:
                return <LayouthInfoUko
                    appName={appName}
                    firstImage={"urlSignIn"}
                    title={t("info.password-created.label")}
                    textInTheEnd={countdown > 0 ? <div>{t("info.password-created.show-countdown")}<b>00:{showCountdown(countdown)}</b></div> : ''}
                    countdown={countdown}
                    setCountdown={setCountdown}
                    showLoading={countdown > 0 ? false : true}
                    tenant={tenant}
                />;
            case INVALIDLINK:
                return <LayouthInfoUko
                    appName={appName}
                    firstImage={"urlSignIn"}
                    title={t("info.activation-time-expired.label")}
                    // secondImage={"urlExpireTime"}
                    textBeforeEnd={messageCheckEmail}
                    onClickLink={onClickLinkEmail}
                    textInLink={t("info.activation-time-expired.re-link")}
                    tenant={tenant}
                />;
            case CHECKEMAILAGAIN:
                return <LayouthInfoUko
                    appName={appName}
                    firstImage={"urlSignIn"}
                    title={t("info.check-email-again.label")}
                    // secondImage={"urlCheckEmailAgain"}
                    textBeforeEnd={messageCheckEmail}
                    textInTheEnd={countdown > 0 ? <div>{t("info.check-email-again.try-again")}<b>00:{showCountdown(countdown)}</b></div> : ''}
                    onClickLink={onClickLinkEmail}
                    textInLink={countdown === 0 ? t("info.check-email-again.not-received-email") : ''}
                    countdown={countdown}
                    setCountdown={setCountdown}
                    tenant={tenant}
                />;
            default:
                return (
                    <LayouthInfoUko
                        appName={appName}
                        firstImage={"urlSignIn"}
                        title={t("info.default.link-no-valid")}                        
                        infoText={<div><center>{t("info.default.enter-email-again")}</center></div>}
                        onClickLink={handleClick}
                        textInLink={t("info.default.link-sign-in")}
                        hasInput={true}
                        tenant={tenant}
                    ></LayouthInfoUko>
                );
        }
    }

    return (
        <div>
            {renderInfo(messageTypeFromHistory)}
        </div>
    );
}

export default InfoUko;
