import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getRoles, modifyRole } from "../../actions/roles.action";
import RoleForm from "../roles/rolesForm.view";
import ViewLayout from "../../components/layouts/ViewLayout";
import CustomTable from "../../components/Table/CustomTable.component";
import {
  Fab,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import NewUserIcon from "../../components/IconsLibrary/NewUserIcon";
import { useDispatch } from "react-redux";

/**
 * Role Component ( full view for list of Roles)
 *
 * @export Class Component
 * @class Role
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  btnNewUser: (props) => ({
    flex: 1,
    textAlign: "right",
    paddingRight: "8px",
    width: "32px",
    height: "32px",
    marginRight: 40,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 2px #0000003D",
    border: `1px solid ${props.background}`,
    opacity: "1",
  }),
  newUserIcon: {
    stroke: theme.palette.secondary.main,
  },
  styleFab: {
    boxShadow: "none",
  },
}));

const Roles = ({ component: Component, container, ...props }) => {
  const { rolesReducer, getRoles, modifyRole, getRoleChanged } = props;
  const [cardState, setCardState] = useState(true);
  const [confirmInactivate, setConfirmInactivate] = useState({
    item: "",
    open: false,
    checked: false,
    message: "",
  });
  const theme = useTheme();
  const [showRoleAlert, setShowRoleAlert] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [rol, setRol] = useState({});
  const [isFormShowed, setFormShowed] = useState(false);
  const [option, setOption] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const columns = [
    {
      id: "name",
      label: "Nombre Rol",
      minWidth: 40,
      align: "center",
      haveDropDown: true,
      wordLength: 40,
      key: " "
    },
    {
      id: "createdon",
      label: "Fecha de creación",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
      format: (value) => {
        let splited = value.split("T")[0];
        let newDate = splited.split("-");
        return `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
      },
    },
    {
      id: "sharedApps",
      label: "Aplicación",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
      children: (value) => {
        let result = value.split(',');
        result = result.map((item) => item)
        const typographyStyle ={
          textDecoration: (result.length > 2) ? 'underline': '',
          color: theme.palette.primary.main,
          font: "normal normal normal 14px/19px Muli",
          letterSpacing: "0px",
          cursor: "pointer",
        };
        return (
          <>
            {result.length > 2 ? (
              <Tooltip title={result.join(", ")}>
                <Typography
                  className={classes.labels}
                  align={"center"}
                  style={typographyStyle}
                >
                  {value.includes(",")
                    ? `${result[0]}, ${result[1]} ...`
                    : result[0]}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                className={classes.labels}
                align={"center"}
                style={typographyStyle}
              >
                {value.includes(",") ? `${result[0]}, ${result[1]}` : result[0]}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      id: "idstate",
      label: "Estado",
      minWidth: 140,
      align: "center",
      haveDropDown: true,
      component: {
        handleChange: (e, value, id) => handleConfirmClick(e, value, id),
      },
      handleIconClick: (evt, id) => handleDeleteClick(evt, id),
      tooltipMessage: "rol",
      withIcon: false,
      format: (value) => (value === 1 || value === false ? false : true),
    },
  ];

  const handleConfirmClick = (e, id) => {
    setConfirmInactivate({
      item: id,
      open: true,
      showBtnAccept: true,
      showBtnCancel: true,
      checked: e.target.checked,
      message: `¿Está seguro que desea ${
        e.target.checked ? "activar" : "inactivar"
      } este Rol?`,
    });
  };
  const classes = useStyles();

  const updateChecked = () => {
    getRoles();
  };

  const handleConfirmChecked = () => {
    if (confirmInactivate.item !== "inactivate") {
      modifyRole(
        {
          id: confirmInactivate.item,
          idstate: confirmInactivate.checked ? "0" : "1",
        },
        updateChecked,
        true
      );
    }
    dispatch({
      type: "GET_ROLE_CHANGED",
      payload: [],
    });
    setConfirmInactivate({
      item: "",
      open: false,
      checked: false,
      message: "",
    });
  };

  useEffect(() => {
    if (!Array.isArray(getRoleChanged)) {
      setConfirmInactivate({
        open: true,
        message: getRoleChanged,
        item: "inactivate",
      });
      dispatch({
        type: "GET_ROLE_CHANGED",
        payload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoleChanged]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  useEffect(() => {
    if (!!rolesReducer.length) {
      setLoading(false);
    }
  }, [rolesReducer]);

  /**
   *  Maneja el evento click de el icono basurero
   * @param {*} evt
   * @param {*} id
   */
  const handleDeleteClick = (evt, id) => {
    setConfirmInactivate({
      open: true,
      item: id,
      checked: evt.target.checked,
      showBtnAccept: true,
      showBtnCancel: true,
      delete: true,
      message: `¿Deseas eliminar este rol?`,
    });
  };

  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          lg: 7,
          md: 12,
          sm: 12,
        },
      },
      menuOrder: {
        options: [
          {
            label: "Activo",
            columns: [
              {
                prop: "idstate",
                direction: -1,
              },
            ],
          },
          {
            label: "Inactivo",
            columns: [
              {
                prop: "idstate",
              },
            ],
          },
          {
            label: "Más reciente",
            columns: [
              {
                prop: "createdon",
                direction: 1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
          {
            label: "Más antiguo",
            columns: [
              {
                prop: "createdon",
                direction: -1,
                format: (date) => {
                  return new Date(date).getTime();
                },
              },
            ],
          },
        ],
        cellSize: {
          lg: 6,
          md: 2,
          sm: 2,
        },
      },
      // exportButton: {
      //   cellSize: {
      //     lg: 6,
      //     md: 2,
      //     sm: 2
      //   }
      // },
      children: () => {
        return (
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={2}
            xs={2}
            direction="row-reverse"
          >
            <p className={classes.buttonNewUser}>
              <Tooltip title={`crear rol`} aria-label={`Nuevo rol`}>
                <Fab
                  size="small"
                  className={[classes.fab, classes.styleFab]}
                  onClick={() => {
                    setRol({});
                    setFormShowed(true);
                    setRoleId(0);
                    if (cardState === true) {
                      setCardState(false);
                    }
                  }}
                >
                  <NewUserIcon className={classes.newUserIcon} />
                </Fab>
              </Tooltip>
            </p>
          </Grid>
        );
      },
    };

    return button;
  };

  const handleCellClick = (evt, row) => {
    setRoleId(row.id);
    setRol(row);
    setFormShowed(true);
    setLoading(true);
  };

  return (
    <ViewLayout
      headerTitle={"Roles"}
      handleAccept={handleConfirmChecked}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      showAlert={showRoleAlert}
      setShowAlert={setShowRoleAlert}
      isLoading={isLoading}
    >
      {isFormShowed ? (
        <RoleForm
          roleId={roleId}
          rol={rol}
          setRoleId={setRoleId}
          setCardState={setFormShowed}
          updateChecked={updateChecked}
          history={props.history}
          setShowRoleAlert={setShowRoleAlert}
          setLoading={setLoading}
          isLoading={isLoading}
        />
      ) : (
        <CustomTable
          data={rolesReducer}
          columns={columns}
          mainParam={"name"}
          havePagination={true}
          buttons={getButtons()}
          handleCellClick={handleCellClick}
          option={option}
          setOption={setOption}
          paramToDisable={"idstate"}
        />
      )}
    </ViewLayout>
  );
};

Roles.propTypes = {
  getRoles: PropTypes.func.isRequired,
  modifyRole: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  rolesReducer: state.rolesReducer.getRolesResponse,
  modifyRole: state.rolesReducer.getRolesResponse,
  getRoleChanged: state.rolesReducer.getRoleChangedResponse,
});

const mapDispatchToProps = {
  getRoles,
  modifyRole,
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
