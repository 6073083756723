import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getRolesList } from "../../actions/user.action";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SwitchForm from "../../components/Switch/switchForm.component";
import CustomTable from "../../components/Table/CustomTable.component";
import { GET_USER_ROLES } from "../../actions/types";
import { toPascalCase } from "../../utils/proprietaryHooks";
import { getApplications } from "../../actions/user.action";
import { Fragment } from "react";
import ButtonApps from "../../components/ButtonForm/ButtonApps.component";

/**
 * UserRoles Component ( view Roles from a user )
 *
 * @export Class Component
 * @class UserRoles
 * @extends {Component}
 * @returns Redux connect
 */

const UserRoles = forwardRef((props, ref) => {
  const theme = useTheme();
  const {
    selectedAppID,
    userId,
    setLoading,
    sortData,
    isEnabled,
    setErrors,
    rolAppCounter,
    errors,
    rolesData,
    applicationList,
    setApplicationList,
    setSelectedAppId,
    setOriginalRoles,
  } = props;
  const classes = useStyles();
  const {
    getRolesListResponse,
    getRolesList,
    getApplications,
    getApplicationsResponse,
  } = props;

  // administradores de estado
  const [userOption, setUserOption] = useState(null);

  const dispatch = useDispatch();

  const {
    allItems: allRoles,
    editedItems: editedRoles,
    items: roles,
    addAppItems: addAppRoles,
    addItem: addRole,
    appCounter: appRolCounter,
    assignAllItems: assignAllRoles,
  } = rolesData;

  /**
   * send info about edited roles to parent component
   */
  useImperativeHandle(ref, () => ({
    editedRoles,
    roles,
    appRolCounter,
  }));

  /**
   * table columns definition
   */
  const columns = [
    {
      id: "name",
      label: "Rol",
      minWidth: 280,
      haveDropDown: true,
      format: (value) => (value.length >= 40 ? `${value}...` : value),
      wordLength: 40,
      key: " ",
      customClass: {
        color: theme.palette.common.black,
        fontStyle: "Regular",
        //fontWeight: '600'
      },
    },
    {
      id: "sharedApps",
      label: "Compartido con",
      align: "center",
      children: (value) => {
        const result = value.split(",");
        return (
          <Tooltip title={value}>
            <Typography
              className={classes.labels}
              align={"center"}
              style={{
                textDecoration: "underline",
                color: theme.palette.primary.light,
                font: "normal normal normal 12px/19px Muli,Roman",
                letterSpacing: "0px",
              }}
            >
              {value.includes(",")
                ? `${toPascalCase(result[0])}, ${toPascalCase(result[1])}...`
                : ""}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "assigned",
      haveDropDown: true,
      label: "Asignado",
      minWidth: 100,
      align: "center",
      format: (value) => value,
      children: (value, row) => {
        return (
          <Grid
            container
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <SwitchForm
              checked={value}
              name={"assigned"}
              disabled={!isEnabled}
              onChange={(e) => {
                handleChangeChecked(e, row);
              }}
            />
          </Grid>
        );
      },
    },
  ];

  /**
   * monitorea los cambios en los switch y asigna la cantidad de roles a las aplicaciones
   * @author kolarte
   * @param {*} event
   * @param {*} selectedRole
   */
  const handleChangeChecked = (event, selectedRole) => {
    if (event.target.checked === true) {
      setErrors("");
    }
    const transformRole = (role) => {
      let result = {};
      if (role.id === selectedRole.id) {
        result = { ...role, assigned: event.target.checked };
      } else {
        result = role;
      }
      return result;
    };
    addRole(transformRole(selectedRole), selectedAppID);
  };

  /**
   *
   * @param {*} event
   */

  const handleAllRolesChecked = (event) => {
    const checked = event.target.checked;
    if (checked === true) {
      setErrors("");
    }
    assignAllRoles(checked);
  };

  const findRolesByAppId = (appIdSelected) => {
    if (
      Object.keys(roles).some((item) => parseInt(item, 10) === appIdSelected)
    ) {
      setSelectedAppId(appIdSelected);
    } else {
      setLoading(true);
      getRolesList(
        appIdSelected,
        userId,
        rolAppCounter,
        setLoading,
        setSelectedAppId
      );
    }
  };

  //#region effects
  useEffect(() => {
    if (!applicationList.length) {
      console.log(rolesData);
      if (ref.current.roles === undefined) {
        // setLoading(true);
        getApplications(userId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * asignación de respuesta de aplicaciones a reducer de usuario
   */
  useEffect(() => {
    if (!!getApplicationsResponse.length && !appRolCounter.length) {
      if (ref.current.roles.length === 0) {
        const rolApp = getApplicationsResponse.map((item) => ({
          id: item.id,
          name: item.name,
          count: !!item.rolCount ? item.rolCount : 0,
          title: item.title,
          countUnique: !!item.rolCountUnique ? item.rolCountUnique : 0,
          hasInfo: false
        }));
        const idApp = rolApp.sort((a, b) =>
          (a.count + a.countUnique) === (b.count + b.countUnique) ? 0 : (a.count + a.countUnique) > (b.count + b.countUnique) ? -1 : 1
        )[0].id;
        // setLoading(true)
        getRolesList(idApp, userId, rolApp, setLoading, setSelectedAppId);
        setSelectedAppId(idApp);
        setApplicationList(rolApp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApplicationsResponse]);

  /**
   * asignación de respuesta de roles a reducer de usuario roles
   */
  useEffect(() => {
    if (!!getRolesListResponse.length) {
      const sortedResponse = sortData(getRolesListResponse);
      addAppRoles(selectedAppID, sortedResponse);
      setOriginalRoles((oldRoles) => ({
        ...oldRoles,
        [selectedAppID]: JSON.parse(JSON.stringify(sortedResponse)),
      }));

      dispatch({
        type: GET_USER_ROLES,
        payload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRolesListResponse]);
  //#endregion

  const buttons = {
    justify: "space-between",
    searchBar: {
      cellSize: {
        lg: 8,
        md: 2,
        sm: 2,
      },
    },
    children: () => {
      const config = {
        cellSize: {
          lg: 3,
          md: 2,
          sm: 2,
        },
      };
      const component = () => (
        <>
          <Grid container item lg={8} md={8} sm={6} justifyContent="flex-end">
            <Box flexWrap="wrap">
              <Typography variant="subtitle2" align="center">
                Asignar todos los roles
              </Typography>
            </Box>
          </Grid>
          <Grid container item lg={3} md={3} sm={12} justifyContent="flex-end">
            <Tooltip title="Asignar todos los roles">
              <SwitchForm
                name="allRoles"
                disabled={!isEnabled}
                onChange={handleAllRolesChecked}
                checked={allRoles}
              />
            </Tooltip>
          </Grid>
        </>
      );
      return { config, component };
    },
  };

  const defineList = () => {
    const list = appRolCounter.length > 0 ? appRolCounter : applicationList;
    return list.map((row) => {
      if (row.name.toUpperCase() !== "RESETOTP") {
        const roles = !!row.count ? row.count : 0;
        const uniqueRoles = !!row.countUnique ? row.countUnique : 0
        return (
          <ButtonApps
            isActive={selectedAppID === row.id}
            key={row.id + "uss"}
            onClick={() => findRolesByAppId(row.id)}
            fullWidth
            title={row.title}
            appCounter={`${row.hasInfo ? roles : roles + uniqueRoles} Roles`}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <Fragment>
      <Grid item lg={4} md={4} sm={12}>
        {defineList()}
      </Grid>
      <Grid container item lg={8} md={8} justifyContent="flex-end" sm={12}>
        <Card className={`${classes.card} 'UserRoles-Card'`}>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
            <Grid item lg={3} md={3} sm={12}>
              <div
                className={classes.logo}
                style={{ backgroundImage: `url('${selectedAppID}Logo.png')` }}
                alt="Logo"
              ></div>
            </Grid>
          </Grid>
          <CustomTable
            columns={columns}
            data={roles[selectedAppID] ? roles[selectedAppID] : []}
            buttons={buttons}
            mainParam={"name"}
            havePagination={false}
            handleChange={handleChangeChecked}
            option={userOption}
            setOption={setUserOption}
            globalDisabled={!isEnabled}
          />
        </Card>
        {errors !== "" && (
          <div className={classes.errorText}>
            <Typography color="error" variant="subtitle2">
              {errors}
            </Typography>
          </div>
        )}
      </Grid>
    </Fragment>
  );
});

UserRoles.propTypes = {
  getRolesList: PropTypes.func.isRequired,
  getApplications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getRolesListResponse: state.userReducer.getRolesListResponse,
  getApplicationsResponse: state.userReducer.getApplicationsResponse,
});

const mapDispatchToProps = {
  getRolesList,
  getApplications,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UserRoles);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  card: {
    margin: "0px 0px 0px 15px",
    background: `background: ${theme.palette.common.white} 0% 0% no-repeat padding-box`,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 10,
    opacity: 1,
    width: "100%",
  },
  paper: {
    width: "100%",
    padding: "23px 20px",
  },
  searchTextField: {
    width: "368px",
    maxHeight: "25px",
    paddingLeft: "23px",
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 2,
    "&:nth-child(1)": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:nth-last-child(1)": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  logo: {
    width: "87%",
    height: "37px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    marginTop: "10px",
    marginLeft: "22px",
    marginRight: "1px",
    marginBottom: "10px",
  },
}));
