import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import PageBackground from "./pageBackground.component";
import PrimaryButton from "./primaryButton.component";

/**
 * Alert constant ( function component used as Alert )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */
const minWidth = 285;
const maxWidth = 320;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "285px",
    minWidth: minWidth,
    maxWidth: maxWidth,

   // position: "absolute",
    margin: "0 auto",
    padding: "20px",
    borderRadius: 12
  },
  message: {
    color: "gray",
    padding: "10px",
    textAlign: "center",
    fontSize: "16px",
  },
}));

const Alert = ({ ...props }) => {
  const { value, onClick } = props;
  const classes = useStyles();
  return (
    <div className="card_alert">
      <PageBackground></PageBackground>
      <Card className={classes.root}>
        <Typography className={classes.message}>{value}</Typography>
        <br />
        <PrimaryButton txtBtn={"Aceptar"} onClick={onClick}></PrimaryButton>
      </Card>
    </ div>
  );
};

export default Alert;
