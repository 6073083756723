import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Get a color from text
 * example test: "url('#fffaef')"
 * @param {*} value with a color "url('#fffaef')"
 * @returns only a color "#fffaef"
 */
const getColorFromUrl = (value) => {
  if (value !== undefined && value !== null && value !== ''){
    const parts = value.split("'");
    return parts[1];
  }
  return "";
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    //  minWidth: "1024px",
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    backgroundColor:
      getColorFromUrl(theme.pageBackground.backgroundImage).startsWith('#') ||
      getColorFromUrl(theme.pageBackground.backgroundImage).startsWith('rgb')
        ? getColorFromUrl(theme.pageBackground.backgroundImage) // If is a color
        : 'transparent',
    backgroundImage:
      theme.pageBackground.backgroundImage.startsWith('#') ||
      theme.pageBackground.backgroundImage.startsWith('rgb') 
        ? 'none' // If not a color
        : theme.pageBackground.backgroundImage,
    backgroundSize: theme.pageBackground.backgroundSize,
    zIndex: '-999999',
  },
}));

const PageBackground = ({ ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={`${classes.root} 'Page-background'`}></div>
    </div>
  );
};

export default PageBackground;
