// import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import ViewLayout from "../../components/layouts/ViewLayout";
import { GET_MENU_CHANGE } from "../../actions/types";
import { getMenuList, createMenu, ModifyMenu } from "../../actions/menu.action";
import FormLayout from "../../components/layouts/FormLayout";
import TabComponent from "../../components/TabsComponent/TabComponent";
import { CustomMenuSettings, appliCustomMenu } from "../../components/MenuSettings/MenuTabs/CustomMenuSetting";
import { isEmpty, validateEquals } from "../../utils/proprietaryHooks";
import { UserMenuSettings, appliUserMenu } from "../../components/MenuSettings/MenuTabs/UserMenuSetting";
import { SuiteMenuSetting, appliSuiteMenu } from "../../components/MenuSettings/MenuTabs/SuiteMenuSetting";
import { UseDialog } from "@dg-bucaramanga/react-components-dg-qa";
const MenuSettings = ({ component: Component, container, ...props }) => {
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: "",
  });

  const [currentTab, setCurrentTab] = useState(0);
  const [currentTabKey, setCurrentTabKey] = useState(0);
  const [isLoading,] = useState(false);
  const [leftMenu, setLeftMenu] = useState([]);
  const [topMenu, setTopMenu] = useState([]);
  const [userMenu, setUserMenu] = useState([]);
  const [suiteMenu, setSuiteMenu] = useState([]);
  const [leftMenuDragDrop, setLeftMenuDragDrop] = useState([]);
  const [topMenuDragDrop, setTopMenuDragDrop] = useState([]);
  const [suiteMenuDragDrop, setSuiteMenuDragDrop] = useState([]);
  const leftMenuRef = useRef(null);
  const topMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const suiteMenuRef = useRef(null);

  const idMenu = useRef(0)

  const menuLocations = {
    LEFT_MENU: 1,
    TOP_MENU: 2,
    USER_MENU: 3,
    SUITE_MENU: 4
  };

  const dispatch = useDispatch();

  const [dialog, setDialog] = React.useState({
    bodyText: "",
    cancelText: "",
    acceptText: "",
    action: "",
  });

  const handleConfirmClick = () => {
    onAcceptClose();
    if (currentTab === 0) {
      leftMenu.forEach((item) => {
        if (item.isFunction === "url") {
          item.isFunction = false;
        }
        if (item.isFunction === "funcion") {
          item.isFunction = true;
        }
      });
      saveMenu(!isEmpty(leftMenuDragDrop) ? leftMenuDragDrop : leftMenu, appliCustomMenu, menuLocations.LEFT_MENU);
    }
    if (currentTab === 1) {
      topMenu.forEach((item) => {
        if (item.isFunction === "url") {
          item.isFunction = false;
        }
        if (item.isFunction === "funcion") {
          item.isFunction = true;
        }
      });
      saveMenu(!isEmpty(topMenuDragDrop) ? topMenuDragDrop : topMenu, appliCustomMenu, menuLocations.TOP_MENU);
    }
    if (currentTab === 2) {
      let result = {
        application: removeTitle(userMenu.application),
        user: removeTitle(userMenu.user),
      };
      saveMenu(result, appliUserMenu, menuLocations.USER_MENU);
    }
    if (currentTab === 3) {
      saveMenu(!isEmpty(suiteMenuDragDrop) ? suiteMenuDragDrop : suiteMenu, appliSuiteMenu, menuLocations.SUITE_MENU);
    }
  };

  const onCancelClick = () => {
    switch (currentTab) {
      case 0:
        setLeftMenu(reloadMenu(menuLeftResponse));
        if (!isEmpty(leftMenuDragDrop)) setLeftMenuDragDrop([]);
        break;
      case 1:
        setTopMenu(reloadMenu(menuTopResponse));
        if (!isEmpty(topMenuDragDrop)) setTopMenuDragDrop([]);
        break;
      case 2:
        let infoReloaded = reloadMenu(menuUserResponse);
        if (infoReloaded.length === 0) {
          setUserMenu({ application: [], user: [] });
        } else {
          setUserMenu(infoReloaded);
        }
        break;
      case 3:
        setSuiteMenu(reloadMenu(menuSuiteResponse));
        if (!isEmpty(suiteMenuDragDrop)) setSuiteMenuDragDrop([]);
        break;
      default:
        break;
    }
    setCurrentTab(currentTabKey);
    onAcceptClose();
  };

  /**
   * Call to back and return info before any change
   * @param {*} infoMenu inf to reload
   * @returns infoU reloaded
   */
  const reloadMenu = (infoMenu) => {
    if (!isEmpty(infoMenu) && infoMenu.records && infoMenu.records[0]) {
      const value = JSON.parse(infoMenu.records[0].menuJson) || [];
      idMenu.current = infoMenu.records[0].id;
      return value;
    } else if (!isEmpty(infoMenu) && infoMenu.records.length === 0) {
      idMenu.current = 0;
      return [];
    }
    return [];
  }

  const {
    Dialog,
    onOpen,
    onClose: onAcceptClose,
  } = UseDialog({
    bodyText: dialog.bodyText,
    cancelButtonText: dialog.cancelText,
    confirmationButtonText: dialog.acceptText,
    onConfirmClick: handleConfirmClick,
    onCancelClick: onCancelClick,
  });

  //#region  selectors
  const menuLeftResponse = useSelector(
    ({ menuReducer }) => menuReducer.getLeftMenuResponse
  );
  const menuTopResponse = useSelector(
    ({ menuReducer }) => menuReducer.getTopMenuResponse
  );

  const menuUserResponse = useSelector(
    ({ menuReducer }) => menuReducer.getUserMenuResponse
  );

  const menuSuiteResponse = useSelector(
    ({ menuReducer }) => menuReducer.getSuiteMenuResponse
  );

  const verifyChanges = (menuToVerify) => {
    if (!isEmpty(menuToVerify) && menuToVerify.records && menuToVerify.records[0]) {
      const value = JSON.parse(menuToVerify.records[0].menuJson) || [];
      idMenu.current = menuToVerify.records[0].id;
      return value;
    } else if (!isEmpty(menuToVerify) && menuToVerify.records.length === 0) {
      idMenu.current = 0;
      return [];
    }
    return [];
  }

  //#endregion

  //#region  effects section
  useEffect(() => {
    setLeftMenu(verifyChanges(menuLeftResponse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuLeftResponse]);

  document.body.style.overflowY = "scroll";
  // should bee useEffect but doesn´t work

  useEffect(() => {
    setTopMenu(verifyChanges(menuTopResponse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuTopResponse]);

  /**
   * Obtencion de menus
   */
  useEffect(() => {
    let changes = verifyChanges(menuUserResponse);
    if (changes.length === 0) {
      setUserMenu({ application: [], user: [] });
    } else {
      setUserMenu(changes);
    }
  }, [menuUserResponse]);

  useEffect(() => {
    setSuiteMenu(verifyChanges(menuSuiteResponse));
  }, [menuSuiteResponse]);

  const removeTitle = (menus) => {
    let objectMenus = [];
    if (menus !== undefined && menus.length > 0) {
      for (const menu of menus) {
        let objectMenuChildren = [];
        if (menu.children !== undefined && menu.children.length > 0) {
          objectMenuChildren = removeTitle(menu.children);
        }
        const { title, ...rest } = menu;
        if (objectMenuChildren.length !== 0) {
          objectMenus.push({ ...rest, children: objectMenuChildren });
        } else {
          objectMenus.push(rest);
        }
      }
    }
    return objectMenus;
  };
  /**
   * Compare old a new changes when edit info in any aplication
   * @param {*} newChanges Changes unsaved
   * @param {*} oldChanges changes of data base
   * @param {} typeOfMenu  can contain: aplication or user
   * @returns 
   */
  const compareMenus = (newChanges, oldChanges, typeOfMenu = '') => {
    if (!isEmpty(newChanges)) {
      if (!isEmpty(oldChanges) && oldChanges.records && oldChanges.records[0]) {
        if (typeOfMenu !== '') {
          let infoToJson = JSON.parse(oldChanges.records[0].menuJson);
          return validateEquals(newChanges, infoToJson[typeOfMenu]);
        } else {
          return validateEquals(newChanges, JSON.parse(oldChanges.records[0].menuJson));
        }
      }
      else if (!isEmpty(oldChanges) && oldChanges.records.length === 0) {
        return validateEquals(newChanges, oldChanges.records);
      }
    }
    return true;
  }
  /**
   * Depending of current tab compare changes
   * @returns false if current tab in current menu has been changed
   */
  const validateChanges = () => {
    switch (currentTab) {
      case 0:
        return compareMenus(!isEmpty(leftMenuDragDrop) ? leftMenuDragDrop : leftMenu, menuLeftResponse);
      case 1:
        return compareMenus(!isEmpty(topMenuDragDrop) ? topMenuDragDrop : topMenu, menuTopResponse);
      case 2:
        if (!(compareMenus(userMenu.application, menuUserResponse, 'application'))
          || !(compareMenus(userMenu.user, menuUserResponse, 'user'))) {
          return false;
        } else {
          return true;
        }
      case 3:
        return compareMenus(!isEmpty(suiteMenuDragDrop) ? suiteMenuDragDrop : suiteMenu, menuSuiteResponse);
      default:
        break;
    }
  };

  const saveMenu = async (data, applicationLocation, locationMenu) => {
    let promiseCreateOrModidy;
    const objectCreateOrModify = {
      id: idMenu.current,
      idapplication: applicationLocation,
      idmenulocation: locationMenu,
      numberRows: locationMenu === 4 ? 4 : 0,
      menujson: locationMenu === 3 ? JSON.stringify(data) : JSON.stringify(removeTitle(data)),
    };
    if (idMenu.current === 0) {
      promiseCreateOrModidy = new Promise(createMenu(objectCreateOrModify));
      changeMenu(promiseCreateOrModidy, "¡Menú guardado exitosamente!");
    } else {
      promiseCreateOrModidy = new Promise((ModifyMenu(objectCreateOrModify)));
      changeMenu(promiseCreateOrModidy, "¡Menú modificado exitosamente!");
    }
    if (locationMenu === 1) {
      if (!isEmpty(leftMenuDragDrop)) setLeftMenuDragDrop([]);
      if (!isEmpty(menuLeftResponse) && menuLeftResponse.records && menuLeftResponse.records[0]) menuLeftResponse.records[0] = [];
      setLeftMenu([]);
    }
    if (locationMenu === 2) {
      if (!isEmpty(topMenuDragDrop)) setTopMenuDragDrop([]);
      if (!isEmpty(menuTopResponse) && menuTopResponse.records && menuTopResponse.records[0]) menuTopResponse.records[0] = [];
      setTopMenu([]);
    }
    if (locationMenu === 3) {
      if (!isEmpty(menuUserResponse) && menuUserResponse.records && menuUserResponse.records[0]) menuUserResponse.records[0] = [];
      setUserMenu({ application: [], user: [] });
    }
    if (locationMenu === 4) {
      if (!isEmpty(suiteMenuDragDrop)) setSuiteMenuDragDrop([]);
      if (!isEmpty(menuSuiteResponse) && menuSuiteResponse.records && menuSuiteResponse.records[0]) menuSuiteResponse.records[0] = [];
      setSuiteMenu([]);
    }
    setCurrentTab(currentTabKey);
  };

  /**
   * Save menu, show message and update data
   * @param {*} promise any promise
   * @param {*} message message to show in alert
   */
  const changeMenu = async (promise, message) => {
    try {
      await promise;
    } catch (error) {
      console.error(error);
    }
    setDialog({
      bodyText: message,
    });
    onOpen();
    dispatch({
      type: GET_MENU_CHANGE,
      payload: {},
    });
  }

  //#endregion

  /**
   * If there are unsaved changes
   * Show a message when switching tabs
   * @param {*} key of current tab 
   */
  const validateAndShowMessage = (key) => {
    let validate = validateChanges();
    if (!validate) {
      setDialog({
        bodyText: "Debes dar clic en el botón Guardar Menú para aplicar los cambios",
        cancelText: "No guardar",
        acceptText: "Guardar menú",
        action: key,
      });
      onOpen();
    } else {
      setCurrentTab(key);
    }
  }

  const handleChangeTab = async (evt, key) => {
    validateAndShowMessage(key);
    setCurrentTabKey(key);
  };

  const onCancelButton = (locationMenu) => {
    if (locationMenu === 1) {
      setLeftMenu(reloadMenu(menuLeftResponse));
      if (!isEmpty(leftMenuDragDrop)) setLeftMenuDragDrop([]);
    }
    if (locationMenu === 2) {
      setTopMenu(reloadMenu(menuTopResponse));
      if (!isEmpty(topMenuDragDrop)) setTopMenuDragDrop([]);
    }
    if (locationMenu === 3) {
      let infoReloaded = reloadMenu(menuUserResponse);
      if (infoReloaded.length === 0) {
        setUserMenu({ application: [], user: [] });
      } else {
        setUserMenu(infoReloaded);
      }
    }
    if (locationMenu === 4) {
      setSuiteMenu(reloadMenu(menuSuiteResponse));
      if (!isEmpty(suiteMenuDragDrop)) setSuiteMenuDragDrop([]);
    }
  };

  return (
    <ViewLayout
      headerTitle={"Ajustes de menú"}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      isLoading={isLoading}
    >
      <FormLayout isMenuSettings={true}>
        <TabComponent
          tab={currentTab}
          handleChangeTab={handleChangeTab}
          children={[
            {
              label: "Menú lateral",
              tabContent: (
                <CustomMenuSettings
                  ref={leftMenuRef}
                  menuLocation={menuLocations.LEFT_MENU}
                  menu={{
                    currentMenu: leftMenu,
                    setCurrentMenu: setLeftMenu,
                    idMenu: idMenu.current,
                    setCurrentLeftDragDrop: setLeftMenuDragDrop,
                  }}
                  onCancelButton={onCancelButton}
                />
              ),
            },
            {
              label: "Menú superior",
              tabContent: (
                <CustomMenuSettings
                  ref={topMenuRef}
                  menuLocation={menuLocations.TOP_MENU}
                  menu={{
                    currentMenu: topMenu,
                    setCurrentMenu: setTopMenu,
                    idMenu: idMenu.current,
                    setCurrentTopDragDrop: setTopMenuDragDrop,
                  }}
                  onCancelButton={onCancelButton}
                />
              ),
            },
            {
              label: "Menú de usuario",
              tabContent: (
                <UserMenuSettings
                  ref={userMenuRef}
                  menuLocation={menuLocations.USER_MENU}
                  menu={{ currentMenu: userMenu, setCurrentMenu: setUserMenu, idMenu: idMenu.current }}
                  onCancelButton={onCancelButton}
                />
              ),
            },
            {
              label: "Menú suite aplicaciones",
              tabContent: (
                <SuiteMenuSetting
                  ref={suiteMenuRef}
                  menuLocation={menuLocations.SUITE_MENU}
                  menu={{
                    currentMenu: suiteMenu,
                    setCurrentMenu: setSuiteMenu,
                    idMenu: idMenu.current,
                    setCurrentMenuDragDrop: setSuiteMenuDragDrop,
                  }}
                  onCancelButton={onCancelButton}
                />
              ),
            },
          ]}
        />,
      </FormLayout>
      <Dialog />
    </ViewLayout>
  );
};

MenuSettings.propTypes = {
  getMenuList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getMenuListResponse: state.menuReducer.getMenuListResponse,
});

const mapDispatchToProps = {
  getMenuList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSettings);