import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import {
  SET_CURRENT_USER,
  GET_ERRORS,
  DEFAULTRESPONSE,
  GET_MENU_LIST,
  GET_MENU_CHANGE,
  GET_MENU_IMAGE_CHANGE,
  GET_MENU_ERROR_CHANGE,
  GET_MENU_BY_USER_LIST_RESPONSE,
  GET_MENU_LEFT_RESPONSE,
  GET_MENU_TOP_RESPONSE,
  GET_MENU_USER_RESPONSE,
  GET_SUITE_MENU_RESPONSE,
  GET_UPLOAD_IMAGE_MENU,
} from "./types";

export /**
 * AuthAction getMenu
 *
 * @method GET
 * @param {*}
 */
const getMenu = (userId) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      userid: userId,
    });
    const response = await axios.get(
      API_SECURITY_ENDPOINT + "/api/menu/list",
      myConfig
    );
    dispatch({
      type: SET_CURRENT_USER,
      payload: response.data.result.records,
    });
  } catch (error) {
    const { response } = error;
    dispatch({
      type: GET_ERRORS,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
};

export const getMenuList = (username, appname) => async (
  dispatch,
  getState
) => {
  try {
    const res = await axios.get(
      API_SECURITY_ENDPOINT + "/api/menu/list",
      defaultHeaders()
    );

    dispatch({
      type: GET_MENU_LIST,
      payload: res.data.result.records,
    });
  } catch (error) {
    console.error("Error", error);
  }
};

export const createMenu = (body) => async (dispatch) => {
  try {
    var res = await axios.post(
      API_SECURITY_ENDPOINT + "/api/menu/Create",
      body,
      defaultHeaders()
    );
    dispatch({
      type: GET_MENU_CHANGE,
      payload: res.data.result,
    });
  } catch (error) {
    console.error(error);
  }
};

export const ModifyMenu = (body) => async (dispatch) => {
  try {
    const url = API_SECURITY_ENDPOINT + "/api/menu/Modify";
    var res = await axios.post(
      url,
      body,
      defaultHeaders()
    );
    dispatch({
      type: GET_MENU_CHANGE,
      payload: res.data.result,
    });
  } catch (error) {
    console.error(error);
  }
};

export const GetMenuByApp = (idApplication, idMenuLocation) => async (
  dispatch
) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      idapplication: idApplication,
      idmenulocation: idMenuLocation,
    });
    const res = await axios.get(
      API_SECURITY_ENDPOINT + "/api/menu/GetMenuByApp",
      myConfig
    );

    const types = {
      1: GET_MENU_LEFT_RESPONSE,
      2: GET_MENU_TOP_RESPONSE,
      3: GET_MENU_USER_RESPONSE,
      4: GET_SUITE_MENU_RESPONSE
    };

    if (res.status === 200) {
      dispatch({
        type: types[idMenuLocation],
        payload: res.data.result,
      });
    }
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const GetMenuByUser = (idMenuLocation, appName) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      idmenulocation: idMenuLocation,
      appName,
    });
    const res = await axios.get(
      API_SECURITY_ENDPOINT + "/api/menu/GetMenuByUser",
      myConfig
    );
    if (res.status === 200) {
      dispatch({
        type: GET_MENU_BY_USER_LIST_RESPONSE,
        payload:
          res.data.result.records[0] !== undefined
            ? JSON.parse(res.data.result.records[0].menuJson)
            : [],
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveImage = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      "https://qanet.colfactura.com/imagemanager/api/image",
      data,
      defaultHeaders()
    );

    if (res.status === 200) {
      dispatch({
        type: GET_MENU_IMAGE_CHANGE,
        payload: res.data.result.relativePath,
      });
    }
  } catch (error) {
    const response = !!error.response
      ? error.response.data
      : DEFAULTRESPONSE.noResponseFromApi;
    dispatch({
      type: GET_MENU_ERROR_CHANGE,
      payload: response,
    });
  }
};

export const uploadImageMenuAction = (data) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    const res = await axios.post(
      API_SECURITY_ENDPOINT + "/api/menu/UploadImageMenu",
      data,
      myConfig,
    );

    if (res.status === 200) {
      dispatch({
        type: GET_UPLOAD_IMAGE_MENU,
        payload: res.data.relativePath,
      });
    }
  } catch (error) {
    console.error(error.response);
  }
};