import { connect } from "react-redux";
import {
    Grid,
    useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

const AuthenticationLayoutUkoBeMyself = (props) => {
    const { children, title, logoSignIn } = props;
    const theme = useTheme();
    const classes = useStyles();
    return (
        <Grid container style={{ minHeight: "93vh" }}>
            <Grid item md={12} xs={12} className={classes.gridOrderData} style={{ background: theme.palette.secondary.light, display:"flex", justifyContent:"center" }}>
                <Box style={{ display: "flex", alignItems:"center", justifyContent:"center", height:"98%" }} >
                    <Box style={{ textAlign:"center", maxWidth:"550px", width:"100%", padding:"32px" }}>
                        <img src={logoSignIn} alt="Logo" style={{ width: theme.widthImageLogo }} />
                        <h1 style={{ fontWeight: "700", fontStyle:'normal', fontSize: "28px", marginTop: "20px", color: theme.palette.secondary.titleText}}>
                            {title}
                        </h1>
                        {children}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

AuthenticationLayoutUkoBeMyself.propTypes = {
};
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps, {
})(AuthenticationLayoutUkoBeMyself);

const useStyles = makeStyles((theme) => ({
    gridOrderFirst: {
        [theme.breakpoints.up('md')]: {
            order: 1,
        },
        [theme.breakpoints.down('md')]: {
            order: 2,
        },
    },
    gridOrderData: {
        [theme.breakpoints.down('md')]: {
            order: 1,
        },
        [theme.breakpoints.up('md')]: {
            order: 2,
        },
    },
}));