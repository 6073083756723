import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  useTheme,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import Box from '@material-ui/core/Box';
import { Link, useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ReCAPTCHA from "react-google-recaptcha";
import { SET_IS_AUTHENTICATED, SET_CURRENT_USER } from "../../../actions/types";
import { loginUser, generateTokens } from "../../../actions/auth.action";
import { getInfoApplicationIsFreeTrial, getVersionAppAction, getParmeterConfigAction } from '../../../actions/applications.action';
import { getEnterprise } from "../../../actions/enterprise.action";
import AuthenticationLayoutUko from "../../../components/AuthenticationLayoutUko/AuthenticationLayoutUko";
import CustomInput from "../../../components/AuthenticationLayoutUko/CustomInput";
import PrimaryButtonUko from "../../../components/primaryButtonUko.component";
import ErrorBoxUko from "../../../components/errorBoxUko.component";
import { isEmpty } from "../../../utils/proprietaryHooks";
import { makeStyles } from "@material-ui/core/styles";
import MaintenanceView from "../../notifications/MaintenanceView";

const LoginCertificationUko = (props) => {
  const {
    appName,
    loginUser,
    getAuthenticationResponse,
    authIsFreeTrialApp,
    generateTokensResponse,
    getInfoApplicationIsFreeTrial,
    getVersionAppAction,
    isAuthenticated,
    getVersionApp,
    getParmeterConfigAction,
    parameterConfig,
  } = props;
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [errorsApp, setErrorsApp] = useState();
  const [blocked, setBlocked] = useState({
    timeStatus: "",
    timeBlocked: "",
  });
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showIconPassword, setShowIconPassword] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [messageAttempts, setMessageAttempts] = useState("");
  const [versionMessage, setVersionMessage] = useState("");
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const watchUserName = watch('username');
  const watchPassword = watch('password');
  const classes = useStyles();

  //#region
  useEffect(() => {
    return () => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: undefined,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      //window.location.assign(isAuthenticated.urlAuth);
    }
    if (appName && appName !== "") {
      setLoading(true);
      getInfoApplicationIsFreeTrial(appName, setLoading);
      getVersionAppAction(appName);
      getParmeterConfigAction(appName, 'ParameterApps');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authIsFreeTrialApp.url !== "") {
      setLoading(false);
    }
  }, [authIsFreeTrialApp]);

  useEffect(() => {
    if (!isEmpty(getVersionApp)) {
      setVersionMessage(getVersionApp.version);
    }
  }, [getVersionApp]);

  useEffect(() => {
    if (parameterConfig && parameterConfig.captchaApply){
      if (!watchUserName || !watchPassword || !isVerified) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    } else {
      if (!watchUserName || !watchPassword) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }
    
    if (watchPassword) {
      setShowIconPassword(true);
    } else {
      setShowIconPassword(false);
    }
    
  }, [isVerified, watchPassword, watchUserName, parameterConfig]);

  useEffect(() => {
    if (!!getAuthenticationResponse) {
      if (getAuthenticationResponse.responseCode === 200) {
        reset();
      } else {
        setLoading(false);
        setErrorsApp(getAuthenticationResponse.message);
      }

      if (getAuthenticationResponse.code === "A10" || getAuthenticationResponse.code === "A20") {
        window.location.assign(getAuthenticationResponse.url);
      }

      if (getAuthenticationResponse.isBlocked !== undefined && getAuthenticationResponse.isBlocked) {
        setBlocked({
          timeBlocked:
            "El usuario se encuentra bloqueado.",
          timeStatus: false,
        });
        setMessageAttempts(`Para solucionarlo, comunícate al ${getAuthenticationResponse.messageParameter}`);
      }

      if (getAuthenticationResponse.failedLoginInformation !== undefined
        && getAuthenticationResponse.failedLoginInformation?.failedAttempts !== undefined
        && getAuthenticationResponse.failedLoginInformation?.failedAttempts === 1) {
        setBlocked({
          timeBlocked: "",
          timeStatus: false,
        });
      }

      if (getAuthenticationResponse.code === "A20") {
        setBlocked({
          timeBlocked:
            "El usuario se encuentra bloqueado.",
          timeStatus: false,
        });
      }
    }

    if (!!generateTokensResponse) {
      if (generateTokensResponse.informationCode === "A10" || generateTokensResponse.informationCode === "A20") {
        window.location.assign(generateTokensResponse.urlApp);
        dispatch({
          action: SET_IS_AUTHENTICATED,
          payload: {
            authenticated: true,
            urlAuth: generateTokensResponse.urlApp,
          },
        });
      }
    }
  }, [getAuthenticationResponse, generateTokensResponse, dispatch, reset]);
  //#endregion
  const onChange = (value) => {
    if (value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }

  const setLogin = async (data) => {
    let userData = {
      user: data.username,
      password: data.password,
      appname: appName,
      page: window.location.search.substring(1),
    };
    setLoading(true);
    loginUser(userData, history);
  };

  const getRemainingTime = (deadline) => {
    let now = new Date(),
      remainTime = (new Date(deadline) - now + 1000) / 1000,
      remainSeconds = ("0" + Math.floor(remainTime % 60)).slice(-2),
      remainMinutes = ("0" + Math.floor((remainTime / 60) % 60)).slice(-2),
      remainHours = ("0" + Math.floor((remainTime / 3600) % 24)).slice(-2);
    return {
      remainSeconds,
      remainMinutes,
      remainHours,
      remainTime,
    };
  };

  const ClockCountsDown = (finaMinute) => {
    if (!!getAuthenticationResponse && !getAuthenticationResponse.isBlocked) {
      let FechaHora = "";
      let fechaActual = new Date();
      let faltanMinutos = finaMinute * 1000;
      let fechaFuturo = fechaActual.getTime() + faltanMinutos;
      if (blocked.timeStatus !== true) {
        let timerUpdate = setInterval(() => {
          let currentTime = getRemainingTime(new Date(fechaFuturo));
          FechaHora = currentTime.remainMinutes + ":" + currentTime.remainSeconds;
          setBlocked({
            timeBlocked:
              "El usuario se encuentra bloqueado, vuelve a intentarlo en " +
              FechaHora,
            timeStatus: true,
          });
          setMessageAttempts("Si olvidaste la contraseña, puedes dar clic en la opción \"Recordar contraseña\"");
          if (currentTime.remainTime <= 0) {
            clearInterval(timerUpdate);
            setBlocked({
              timeBlocked:
                "El usuario ya fue liberado por favor intente acceder de nuevo.",
              timeStatus: false,
            });
            setMessageAttempts("");
          }
        }, 1000);
      }
    }
  };

  const getErrors = () => {
    return !!getAuthenticationResponse && getAuthenticationResponse.failedLoginInformation
      ? (<>
        {getAuthenticationResponse.responseStat === "R13"
          ? errorsApp &&
          (blocked.timeBlocked === ""
            ? ClockCountsDown(
              getAuthenticationResponse.failedLoginInformation.lockTime
            )
            : "",
            (
              <ErrorBoxUko
                value={
                  <>
                    {blocked.timeBlocked === "" ? errorsApp : blocked.timeBlocked}
                    {messageAttempts !== "" && <br></br>}
                    {messageAttempts !== "" && <br></br>}
                    {blocked.timeBlocked !== "" && (messageAttempts !== "" && messageAttempts)}
                  </>
                }
              ></ErrorBoxUko>
            ))
          : getAuthenticationResponse.responseStat !== "R21"
            ? errorsApp &&
            (getAuthenticationResponse.responseStat === "R11" &&
              getAuthenticationResponse.failedLoginInformation.failedAttempts >
              0 ? (
              <ErrorBoxUko
                value={
                  <>
                    {errorsApp}
                    {!!getAuthenticationResponse && getAuthenticationResponse.failedLoginInformation.failedAttempts === 1 ? "tienes dos (" : "tienes solo un ("}
                    {!!getAuthenticationResponse && 3 - getAuthenticationResponse.failedLoginInformation.failedAttempts}
                    {!!getAuthenticationResponse && getAuthenticationResponse.failedLoginInformation.failedAttempts === 1 ? ") intentos más." : ") intento más."}
                  </>
                }
              ></ErrorBoxUko>
            ) : (
              <ErrorBoxUko value={errorsApp}></ErrorBoxUko>
            ))
            : !!errorsApp && <ErrorBoxUko value={errorsApp}></ErrorBoxUko>}
      </>
      ) : (!!getAuthenticationResponse &&
        (getAuthenticationResponse.responseStat === "R21"
          || getAuthenticationResponse.responseStat === "R22"
          || getAuthenticationResponse.responseStat === "R23"
        )) && <ErrorBoxUko value={getAuthenticationResponse.message}></ErrorBoxUko>;
  };

  const onLinkGoToResetPassword = (e) => {
    e.preventDefault();
    dispatch({
      type: SET_CURRENT_USER,
      payload: undefined,
    });
    history.push('/resetpassword');
  };

  return (
    <div>
      <AuthenticationLayoutUko title="Iniciar Sesión" logoApp={theme.urlLogo.url} logoSignIn={theme.urlLogo.urlSignIn} >
        <form id="loginUko-validation" autoComplete="off" onSubmit={handleSubmit(setLogin)}>
          <fieldset disabled={isVisible ? true : false}>
            <Grid container direction="column" style={{ marginTop: "40px", gap: "16px" }}>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CustomInput
                  label="Usuario*"
                  name="username"
                  control={control}
                  error={!isEmpty(getAuthenticationResponse) ?
                    getAuthenticationResponse.responseCode === 200 ?
                      (getAuthenticationResponse.code === "A10" || getAuthenticationResponse.code === "A20") ?
                        {} :
                        {
                          username: {
                            message: "",
                            type: "required"
                          },
                          password: {
                            message: "",
                            type: "required"
                          }
                        } :
                      {
                        username: {
                          message: "",
                          type: "required"
                        },
                        password: {
                          message: "",
                          type: "required"
                        }
                      } :
                    {}
                  }
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  classes={isVisible ? classes.typeInputOpacity : classes.typeInput}
                  autoFocus={isVisible ? false : true}
                />
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CustomInput
                  label="Contraseña*"
                  name="password"
                  control={control}
                  error={!isEmpty(getAuthenticationResponse) ?
                    getAuthenticationResponse.responseCode === 200 ?
                      (getAuthenticationResponse.code === "A10" || getAuthenticationResponse.code === "A20") ?
                        {} :
                        {
                          username: {
                            message: "",
                            type: "required"
                          },
                          password: {
                            message: "",
                            type: "required"
                          }
                        } :
                      {
                        username: {
                          message: "",
                          type: "required"
                        },
                        password: {
                          message: "",
                          type: "required"
                        }
                      } :
                    {}
                  }
                  type={showPassword ? "text" : "password"}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  InputPropsParam={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onMouseDown={() => setShowPassword(true)}
                          onMouseUp={() => setShowPassword(false)}
                          onTouchStart={() => setShowPassword(true)}
                          onTouchEnd={() => setShowPassword(false)}
                          onMouseLeave={() => setShowPassword(false)}
                          size="small"
                        >
                          {showIconPassword && (showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />)}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  classes={isVisible ? classes.typeInputOpacity : classes.typeInput}
                  autoFocus={false}
                />
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }} className={classes.gridReset}>
                <Typography>
                  <Link
                    onClick={onLinkGoToResetPassword}
                    style={{
                      textDecoration: "underline",
                      color: theme.palette.primary.main,
                      marginBottom: '2px',
                      fontSize: '15px',
                      fontWeight: 700             
                    }}
                    to="/resetpassword"
                  >
                    Recordar contraseña
                  </Link>
                </Typography>
              </Grid>
              {getErrors()}
              
              {parameterConfig && parameterConfig.captchaApply && (
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                  <ReCAPTCHA
                    sitekey="6Lc675cpAAAAAA9QU8AecdiDpFLP1TD8Ht1hpmf1"
                    onChange={onChange}
                    size="normal"
                  />
                </Grid>
                )
              }
              <PrimaryButtonUko
                txtBtn={"Ingresar"}
                disabled={showButton}
                loading={loading}
              />
            </Grid>
          </fieldset>
        </form>
        <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'space-between', marginTop: '24px', marginBottom: '24px' }} ></div>
        <Box className={classes.version}>
          <p>{versionMessage}</p>
        </Box>
      </AuthenticationLayoutUko>
      <MaintenanceView appName={appName} setIsVisible={setIsVisible} isVisible={isVisible} ></MaintenanceView>
    </div>
  );
};

LoginCertificationUko.propTypes = {
  loginUser: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
  getEnterprise: PropTypes.func.isRequired,
  enterpriseReducer: PropTypes.object.isRequired,
  generateTokens: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
  enterpriseReducer: state.enterpriseReducer,
  getAuthenticationResponse: state.authReducer.getAuthenticationResponse,
  generateTokensResponse: state.authReducer.generateTokensResponse,
  isAuthenticated: state.authReducer.isAuthenticated,
  authIsFreeTrialApp: state.authReducer.authIsFreeTrialApp,
  getVersionApp: state.applicationsReducer.getVersionApp,
  parameterConfig: state.applicationsReducer.parameterConfig,
});
export default connect(mapStateToProps, {
  loginUser,
  getEnterprise,
  generateTokens,
  getInfoApplicationIsFreeTrial,
  getVersionAppAction,
  getParmeterConfigAction,
})(LoginCertificationUko);

const useStyles = makeStyles((theme) => ({
  typeInput: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '8px',
      width: '486px',
      height: '56px',
      backgroundColor: theme.InputUko.backgroundColor,
      [theme.breakpoints.down("xs")]: {
        width: "320px",
      },
    },
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.InputUko.colorInputLetter,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      borderColor: theme.InputUko.borderInput,
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      color: theme.InputUko.colorLabel,
      fontSize: '17px',
      marginTop: '7px',
    },
    "& .MuiInputLabel-root.Mui-focused": {
      fontWeight: 600,
      color: theme.InputUko.colorLabelFocus,
    },
    "& .MuiSvgIcon-root": {
      color: theme.InputUko.colorIcon,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.dark,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.error.dark,
    },
    "& .MuiInputLabel-shrink": {
      margin: "0 auto",
      position: "absolute",
      top: "1px",
    },
  },
  typeInputOpacity: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '8px',
      width: '486px',
      height: '56px',
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down("xs")]: {
        width: "320px",
      },
    },
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.InputUko.colorInputLetter,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      borderColor: theme.InputUko.borderInput,
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.2)',
      fontSize: '17px',
      marginTop: '7px',
    },
    "& .MuiInputLabel-root.Mui-focused": {
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.2)',
    },
    "& .MuiSvgIcon-root": {
      color: theme.InputUko.colorIcon,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.dark,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.error.dark,
    },
    "& .MuiInputLabel-shrink": {
      margin: "0 auto",
      position: "absolute",
      top: "1px",
    },
  },
  gridReset: {
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },
  version: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.versionUko.colorVersionLabel,
    position: 'absolute',
    bottom: '15px',
    left: '50%',
    right: '0',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
    },
  },
}));

