import { Box, Grid, makeStyles, useTheme, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getMaintenanceWindowAction } from '../../actions/applications.action';
import { isEmpty } from "../../utils/proprietaryHooks";
import { connect, } from "react-redux";

const MaintenanceView = ({
    appName = '',
    urlFirstImage = 'urlMaintenanceImage',
    urlLastImage = 'urlWhiteGseLogo',
    setIsVisible,
    isVisible,
    getMaintenanceWindow,
    getMaintenanceWindowAction,
}) => {

    const classes = useStyles();
    const theme = useTheme();

    const [infoMaintenance, setInfoMaintenance] = useState({});

    const onCloseButton = () => {
        setIsVisible(false);
    }
    
    useEffect(() => {
        if (appName && appName !== "") {
            getMaintenanceWindowAction(appName);
        }
        // eslint-disable-next-line
    }, []);
    

    useEffect(() => {
        if ( ! isEmpty(getMaintenanceWindow)) {
            if (getMaintenanceWindow.isMaintenance) {
                setInfoMaintenance({ __html: `${getMaintenanceWindow.message}`});
                setIsVisible(true);
            }
        }
        // eslint-disable-next-line
    }, [getMaintenanceWindow]);
    


    return (
        <div>
            {isVisible &&
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.mainComponent}
                >                    
                    <Box m={1} pt={1} className={classes.boxItem}>
                        <img src={theme.urlLogo[`${urlFirstImage}`]} alt="Maintenance Logo" />
                    </Box>                    
                    <Box m={1} pt={1} className={classes.boxItem}>
                        <Typography className={classes.infoText}>
                            <div dangerouslySetInnerHTML={infoMaintenance} />
                        </Typography>
                    </Box>                    
                    <Box m={1} pt={1} className={`${classes.boxItem} ${classes.boxButton}`}>
                        <Button
                            className={classes.button}
                            onClick={onCloseButton}                          
                        >
                            Entendido
                        </Button>
                    </Box>                    
                    <Box m={1} pt={1} className={`${classes.boxItem} ${classes.boxLogo}`}>                        
                        <img src={theme.urlLogo[`${urlLastImage}`]} alt="White Gse Logo" />
                    </Box>
                </Grid>
            }
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    mainComponent: {
        position: 'absolute',
        top: '0',
        lefth: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
    },
    boxItem: {
        width: '100%',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    infoText: {
        color: theme.palette.common.white,
        fontSize: '18px',
        fontWeight: 700,
        textAlign: 'center',
        [theme.breakpoints.down("md")]: {
            fontSize: '12px',
        },
    },
    boxButton: {
      marginTop: '30px',
    },
    button: {
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.primary.iconSend,
        width: "130px",
        height: "40px",
        display: "block",
        color: theme.palette.common.white,
        borderRadius: "4px",
        "& .MuiButton-label": {
            textTransform: "none",
            fontSize: "15px",
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.iconSend,
        },
        [theme.breakpoints.down("md")]: {
            width: "90px",
            height: "30px",
            "& .MuiButton-label": {
                textTransform: "none",
                fontSize: "12px",
            },
        },
    },
    boxLogo: {
      marginTop: '80px',
    },
    fistImage: {
        [theme.breakpoints.down("md")]: {
            width: '12px',
            height: '12px',
        },
    }
}));

const mapStateToProps = (state) => ({
    getMaintenanceWindow: state.applicationsReducer.getMaintenanceWindow,
});

const mapDispatchToProps = {
    getMaintenanceWindowAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceView);