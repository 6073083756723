import axios from 'axios';
import { API_AUTHENTICATION, APP_HEADER_NAME } from '../../config/config';
export const revokeTokenUser = async () => {
    
    try {
        
        let revokeToken = {
            UserName: JSON.parse(localStorage.getItem("userTokenData")).nameid,
            Quantity: 1,
            AppName: JSON.parse(localStorage.getItem("_appName")),
            RefreshToken: localStorage.getItem("refreshToken")
        }
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*"
            },
        }
        const res = await axios.put(API_AUTHENTICATION + "/api/login/RevokeToken", revokeToken, headers)
        if (res.status === 200) {
            
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('userTokenData');
            localStorage.removeItem('encryptedToken');
            const { data } = res;
            window.location.href = data.result.information;
        } else {
            return false;
        }

    } catch (error) {
        console.log("Error", error)
        

    }



}

export const getAppName = () => {
    axios.get();
}

export const readAppNameFromHeaders = () => {
    var request = new XMLHttpRequest();
    request.open("GET", document.location, false);
    request.send(null);
    return request.getResponseHeader(APP_HEADER_NAME);    
}