import { useEffect, useState } from "react";
import PropTypes from "prop-types";

/**
 *
 * @param {*} applicationList - list of applications
 * @param {number} appId - id of current application selected
 * @returns
 */
const useCounter = ({ applicationList, appId, sortData }) => {
  const [items, setItems] = useState([]);

  const [editedItems, setEditedItems] = useState([]);
  const [allItems, setAllItems] = useState(false);
  const [appCounter, setAppCounter] = useState([]);

  const addAppItems = (appKey, newItems) => {
    const temporalItems = {
      ...items,
      [appKey]: Object.keys(items).length
        ? sortData(findPreviousItems(items, newItems))
        : sortData(newItems),
    };

    setItems(temporalItems);
    setInitialAppCounter(temporalItems[appKey], appKey, temporalItems);
    verifyAllItems(newItems);
  };

  const clearState = () => {
    setItems([]);
  };

  const clearAll = (originalItems, appID) => {
    // if (appID === undefined) {
    //   setItems([]);
    //   setEditedItems([]);
    //   setAppCounter([])
    // } else {
    setItems(originalItems);
    for (const key in originalItems) {
      setInitialAppCounter(originalItems[key], key, originalItems);
    }
    setEditedItems([]);
    setAllItems(
      originalItems.length !== 0 ? originalItems[appID !== undefined ? appID : appId].every(
        (item) => item.assigned
      ) : []
    );
    // }
  };

  /**
   *
   * @param {Object} items - object which contains all items
   * @param {Array} currentItems - list of item that are currently shows
   * @param {boolean} reAssigned -  boolean indicating if everything will be assigned
   * @returns {Object} finalList
   */
  const findPreviousItems = (items, currentItems, reAssigned) => {
    // sub function to transform the list according to the currentItems array
    const getMappedDifferences = (list) => {
      return list.map((item) => {
        const newItem = currentItems.find(
          (current) =>
            current.id === item.id && current.assigned !== item.assigned
        );
        if (newItem) {
          return newItem;
        } else {
          return item;
        }
      });
    };

    // currentItems bring new values to be assigned to previous items
    if (reAssigned !== undefined) {
      for (const key in items) {
        items[key] = getMappedDifferences(items[key]);
      }
      return items;
    } else {
      // create temporary list
      let temporaryList = [];
      for (const iterator of currentItems) {
        for (const key in items) {
          let temp = items[key].find(
            (r) => r.id === iterator.id && r.assigned !== iterator.assigned
          );
          if (temp) {
            temporaryList.push(temp);
          }
        }
      }
      temporaryList = temporaryList.reduce(
        (unique, item) =>
          unique.some((u) => u.id === item.id) ? unique : [...unique, item],
        []
      );
      const finalList = currentItems.map((r) => {
        const result = temporaryList.find((s) => s.id === r.id);
        if (result) {
          return result;
        } else {
          return r;
        }
      });
      return finalList;
    }
  };

  /**
   * set allItems to true if  all items in the list are selected
   * set allItems to false if all items in the list are not selected
   * check if current app items are all selected
   * @param {*} list
   * @returns {void}
   */
  const verifyAllItems = (list) => {
    if (list.filter((item) => item.assigned).length === list.length) {
      setAllItems(true);
    } else {
      setAllItems(false);
    }
  };

  /**
   *
   * @param {*} newItem - item to be modified and replaced in global items object
   * @param {number} key  - app id to find in object items
   */
  const addItem = (newItem, key) => {
    /**
     *  replace newItem in items
     */
    const editedItem = items[key].map((item) =>
      item.id === newItem.id ? newItem : item
    );

    // clone existing items object
    let itemsCopy = { ...items };
    // loop through all items in itemsCopy to find selected newItem in other apps
    for (const key in itemsCopy) {
      for (const iterator of itemsCopy[key]) {
        const selected = editedItem.some(
          (item) => item.id === iterator.id && item.assigned
        );
        if (selected) {
          iterator.assigned = true;
        }
      }
      const indexEdited = itemsCopy[key].findIndex(
        (item) => item.id === newItem.id
      );
      if (indexEdited !== -1) {
        itemsCopy[key][indexEdited] = {
          ...itemsCopy[key][indexEdited],
          assigned: newItem.assigned,
        };
      }
    }
    // set edited array in items object
    setItems({
      ...itemsCopy,
      [key]: editedItem,
    });
    setEditedItems((oldItems) => addEditedItem(oldItems, [newItem]));
    updateAppCounter(newItem.sharedApps, newItem.assigned);
    verifyAllItems(editedItem);
  };

  /**
   *
   * @param {*} newItem
   */
  const addEditedItem = (oldItems, newItems) => {
    //  find if newItem exist in edited items array if exist remove if not exist add
    let result = [];
    let copyOldItems = [...oldItems];
    for (const iterator of newItems) {
      const exist = copyOldItems.findIndex(
        (item) => item.name === iterator.name
      );
      if (exist === -1) {
        result.push(iterator);
      } else {
        copyOldItems.splice(exist, 1);
      }
    }
    result = [...copyOldItems, ...result];
    return result;
  };

  /**
   *@param {*} list list of current items
   *
   */
  const setInitialAppCounter = (list, appID, itemObject) => {
    defineAppCounter(true, false, true, list, appID, itemObject);
  };

  /**
   *
   * @param {*} checked
   * @param {*} condition condicion de comparación -> varía dependiendo el resultado esperado
   * @returns {void}
   */
  const defineAppCounter = (
    checked,
    isAllSelected,
    isNew,
    currentList,
    appID,
    itemObject
  ) => {
    let tempItemCounter = [];
    const currentCounter = !!appCounter.length ? appCounter : applicationList;
    for (const app of currentCounter) {
      for (const key in itemObject) {
        // find  current applicacion
        const id = typeof app.id === "number" ? parseInt(key) : key;
        if (app.id === id && app.id === appID) {
          // get copy of current appItemsList
          const appList = currentList ? currentList : itemObject[key];
          // get number of items which fullfil a condition
          const itemsSelected = appList.filter(
            (ItemApp) =>
              ItemApp.sharedApps.split(",").includes(app.title) &&
              ItemApp.assigned === true
          ).length;
          if (itemsSelected !== -1) {
            tempItemCounter.push({
              ...app,
              hasInfo: true,
              count: isNew
                ? itemsSelected
                : checked
                ? app.count + itemsSelected
                : app.count - itemsSelected,
            });
          }
        }
      }
      if (!tempItemCounter.some((item) => item.id === app.id)) {
        // if list already exists in object set current counter
        // if (Object.keys(itemObject).length > 1 && !isAllSelected) {
        //   ;
        //   // if (!!itemObject[app.id]) {
        //   //   const appCounter = itemObject[app.id].filter(
        //   //     (item) =>
        //   //       item.sharedApps.includes(app.title) && item.assigned === true
        //   //   ).length;
        //   //   tempItemCounter.push({ ...app, count: appCounter });
        //   // }else{
        //   //   tempItemCounter.push(originalCounter.find(item => item.id === app.id));
        //   // }
        //   tempItemCounter.push(app);
        // } else
        if (itemObject.hasOwnProperty(app.id)) {
          const appCounter = itemObject[app.id].filter(
            (item) =>
              item.sharedApps.split(",").includes(app.title) &&
              item.assigned === true
          ).length;
          tempItemCounter.push({ ...app, count: appCounter });
        } else {
          // else if list is not in object find in shared apps to define counter
          let list = [];
          Object.keys(itemObject).map(key => {
            list = [...list, ...itemObject[key]]
            return key
          });
          let listSimplificada = [];
          list.map((item) => {
            if (!listSimplificada.find((x) => x.id === item.id)) {
              listSimplificada.push(item);
            }
            return item
          })

          const appCounter = listSimplificada.filter(
            (item) =>
              item.sharedApps.split(",").includes(app.title) &&
              item.assigned === true
          ).length;
          tempItemCounter.push({
            ...app,
            count: appCounter < app.count ? app.count : appCounter,
          });
        }
      }
    }
    setAppCounter(tempItemCounter);
  };

  /**
   * find all shared apps in item and increment or decrement in appItemCounter
   * @param {*} sharedApps - string list of applications
   * @param {boolean} checked - used for increment or decrement the counter
   */
  const updateAppCounter = (sharedApps, checked) => {
    const listSharedApp = sharedApps.split(",");
    const sharedAppsDetails = applicationList.map((app) =>
      listSharedApp.includes(app.title) ? app.id : null
    );
    setAppCounter(
      appCounter.map((app) =>
        sharedAppsDetails.includes(app.id)
          ? {
              ...app,
              count: checked ? app.count + 1 : app.count - 1,
            }
          : app
      )
    );
  };

  /**
   *
   * @param {boolean} assignAll - define if check or uncheck all
   */
  const assignAllItems = (assignAll) => {
    const currentList = items[appId].map((item) => ({
      ...item,
      assigned: assignAll,
    }));
    let temporalItems = {};
    const editedItems = currentList.filter((item) =>
      items[appId].some(
        (current) =>
          item.id === current.id && item.assigned !== current.assigned
      )
    );
    temporalItems = findPreviousItems(items, currentList, assignAll);
    setItems(temporalItems);
    setEditedItems((oldItems) => addEditedItem(oldItems, editedItems));
    defineAppCounter(assignAll, true, true, currentList, appId, temporalItems);
    setAllItems(assignAll);
  };

  /**
   * check if appId change to setAllItems
   */
  useEffect(() => {
    if (items[appId]) {
      const isAllSelected = items[appId].every((item) => item.assigned);
      setAllItems(isAllSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  return {
    addItem,
    items,
    editedItems,
    addAppItems,
    allItems,
    assignAllItems,
    appCounter,
    clearAll,
    setAppCounter,
    clearState,
  };
};

useCounter.propTypes = {
  applicationList: PropTypes.array.isRequired,
  appId: PropTypes.number.isRequired,
};

export default useCounter;
