// Se actualizan los limites de caracteres en los parametros de tokens

import React, { useEffect, useRef, useState } from 'react'
import FormLayout from "../../components/layouts/FormLayout";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import ButtonSaveChanges from "../../components/ButtonForm/ButtonSaveChanges";
import { ArrowIcon } from "../../components/IconsLibrary/ArrowIcon";
import { HelpOutline, ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import TabComponent from "../../components/TabsComponent/TabComponent";
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";
import StyledRadioButton from "../../components/ButtonForm/StyledRadioButton.component";
import { ErrorMessage } from "@hookform/error-message";
import { FormControl, FormControlLabel, Grid, IconButton, makeStyles, RadioGroup, Tooltip, Typography, useTheme, MenuItem, Divider } from "@material-ui/core";
import { createApplication, getApplications } from "../../actions/applications.action";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import { connect, useDispatch } from 'react-redux';
import { isEmpty, makeId, validateEquals } from '../../utils/proprietaryHooks';
import { actions } from '../../utils/constants';
import { APPLICATION_CREATED, APPLICATION_ERROR, APPLICATION_MODIFIED, GET_APPLICATIONS } from '../../actions/types';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: theme.palette.common.black,
    borderBottom: `2px solid ${theme.palette.common.grey}`,
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary.main.main,
      fontWeight: "bold",
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {
    color: theme.palette.secondary.main.main,
    fontWeight: "bold",
  },
  card: {
    //margin: "0px 15px",
    borderRadius: "10px",
  },
  tabsStyle: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  tabsItem: {
    fontSize: "12px",
    textTransform: "capitalize",
    padding: "21px",
    marginTop: "0px",
  },
  btnApplications: {
    marginBottom: "9px",
    boxShadow: "0px 3px 3px #0000001A",
    borderRadius: "5px",
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  buttonSave: {
    margin: "10px 15px",
    flex: 1,
    float: "right",
    boxShadow: `0px 4px 3px ${theme.palette.common.grey}`,
    borderRadius: 10,
  },
  errorText: {
    padding: "10px 15px",
  },
  categoryButtonFalse: { background: "#FFFFFF 0% 0% no-repeat padding-box" },
  categoryButtonTrue: {
    background: "#6D6E71 0% 0% no-repeat padding-box",
    color: "white !important",
  },
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.text.disabled,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
  helpIcon: {
    color: theme.palette.secondary.main,
  },
  GridAdornmentIcon: {
    height: "24px",
  }
}));

export const ApplicationForm2 = ({ app, setApp, setCardState, setLoading, ...props }) => {

  const {
    createApplication,
    applicationCreated,
    applicationModified,
    getApplications,
    appsReducer,
    applicationError,
  } = props;

  const initialState = {
    id: 0,
    name: "",
    title: "",
    urlAssignPassword: "",
    landing: "",
    isVisible: true,
    urlApp: "",
    // isFreeTrial: false,
    // urlFreeTrial: "",
    // codeFreeTrial: "",
    description: '',
    idState: 0,
    amountTime: 0,
    timeUnit: 0,
    userActivateTokenTime: 0,
    userActivateTokenTimeUnit: 0,
    assignPasswordTokenTime: 0,
    assignPasswordTokenTimeUnit: 0,
    maxRefreshTokens: -1,
    maxRefreshTokensByUser: 0,
    maxConcurrentTokens: 1,
    maxConcurrentTokensByUser: -1,
    isConcurrentSession: false,
    refreshTokenExpirationAmountTime: 0,
    refreshTokenExpirationTimeUnit: 0,
    numberPasswordChanges: 0,
    passwordExpiration: 0,
    passwordTypeExpiration: 0,
    mechanismAfterSetPass: 0,
    typeApplication: false
  };

  const classes = useStyles();

  const theme = useTheme();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialState)
  const originalInfo = useRef(formData);
  const initialValidate = {
    basicValidation: true,
    tokenValidation: false,
    passwordValidation: false,
    settingsValidation: false
  }
  const validationsTabs = useRef(initialValidate)
  const [tab, setTab] = useState(0);
  const [enabledForm, setEnabledForm] = useState(app.id === 0 ? true : false);
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
    back: false,
    cancelEdit: false,
    changeTab: false,
    newTab: 0,
  });
  const [appError, setAppError] = useState(false);
  const requiredMessage = "Campo obligatorio";
  const validationSchema = Yup.object()
    .shape({
      id: Yup.number(),
      name: validationsTabs.current.basicValidation
        ? Yup.string().required(requiredMessage)
        : Yup.string(),
      title: validationsTabs.current.basicValidation
        ? Yup.string().required(requiredMessage).test('len', 'Debe tener más de 2 caracteres', (value) => value.length >= 2)
        : Yup.string(),
      urlApp: validationsTabs.current.basicValidation
        ? Yup.string().url("Debes asignar una url válida").required(requiredMessage)
        : Yup.string(),
      urlAssignPassword: validationsTabs.current.basicValidation
        ? Yup.string().url("Debes asignar una url válida").required(requiredMessage)
        : Yup.string(),
      landing: validationsTabs.current.basicValidation
        ? Yup.string().url("Debes asignar una url válida").required(requiredMessage)
        : Yup.string(),
      // isFreeTrial: Yup.boolean(),
      // urlFreeTrial: Yup.lazy((value) => validationsTabs.current.basicValidation
      //     ? watch('isFreeTrial')
      //         ? Yup.string().url("Debes asignar una url válida").required(requiredMessage)
      //         : Yup.string()
      //     : Yup.string()),
      // codeFreeTrial: Yup.lazy((value) => validationsTabs.current.basicValidation
      //     ? watch('isFreeTrial')
      //         ? Yup.string().required(requiredMessage)
      //         : Yup.string()
      //     : Yup.string()),
      description: Yup.string(),
      isVisible: Yup.boolean(),
      // optionaly fields valid only if first fields are filled
      amountTime: Yup.lazy((value) =>
        validationsTabs.current.tokenValidation
          ? Yup.number().min(-1).test("isNotZero", "El valor no puede ser 0", (value) => value !== 0)
            .test("maxminutes", "El valor no puede ser mayor a 525600 ", value => watch('timeUnit') === 1 ? value <= 525600 : value)
            .test("maxhours", "El valor no puede ser mayor a 8760  ", value => watch('timeUnit') === 2 ? value <= 8760 : value)
            .test("maxdays", "El valor no puede ser mayor a 365 ", value => watch('timeUnit') === 3 ? value <= 365 : value)
            .typeError(requiredMessage)
            .required(requiredMessage)
          : Yup.number()
      ),
      userActivateTokenTime: Yup.lazy((value) =>
        validationsTabs.current.tokenValidation
          ? Yup.number().min(0).test("isNotZero", "El valor no puede ser 0", (value) => value !== 0)
            .test("maxminutes", "El valor no puede ser mayor a 525600 ", value => watch('userActivateTokenTimeUnit') === 1 ? value <= 525600 : value)
            .test("maxhours", "El valor no puede ser mayor a 8760  ", value => watch('userActivateTokenTimeUnit') === 2 ? value <= 8760 : value)
            .test("maxdays", "El valor no puede ser mayor a 365 ", value => watch('userActivateTokenTimeUnit') === 3 ? value <= 365 : value)
            .typeError(requiredMessage)
            .required(requiredMessage)
          : Yup.number()
      ),
      assignPasswordTokenTime: Yup.lazy((value) =>
        validationsTabs.current.tokenValidation
          ? Yup.number().min(0).test("isNotZero", "El valor no puede ser 0", (value) => value !== 0)
            .test("maxminutes", "El valor no puede ser mayor a 525600 ", value => watch('assignPasswordTokenTimeUnit') === 1 ? value <= 525600 : value)
            .test("maxhours", "El valor no puede ser mayor a 8760  ", value => watch('assignPasswordTokenTimeUnit') === 2 ? value <= 8760 : value)
            .test("maxdays", "El valor no puede ser mayor a 365 ", value => watch('assignPasswordTokenTimeUnit') === 3 ? value <= 365 : value)
            .typeError(requiredMessage)
            .required(requiredMessage)
          : Yup.number()
      ),
      refreshTokenExpirationAmountTime: Yup.lazy((value) =>
        validationsTabs.current.tokenValidation
          ? Yup.number().min(-1).test("isNotZero", "El valor no puede ser 0", (value) => value !== 0)
            .test("maxminutes", "El valor no puede ser mayor a 525600 ", value => watch('refreshTokenExpirationTimeUnit') === 1 ? value <= 525600 : value)
            .test("maxhours", "El valor no puede ser mayor a 8760  ", value => watch('refreshTokenExpirationTimeUnit') === 2 ? value <= 8760 : value)
            .test("maxdays", "El valor no puede ser mayor a 365 ", value => watch('refreshTokenExpirationTimeUnit') === 3 ? value <= 365 : value)
            .typeError(requiredMessage).required(requiredMessage)
          : Yup.number()
      ),
      idState: Yup.boolean(),
      timeUnit: validationsTabs.current.tokenValidation
        ? Yup.number().test("isNotZero", "Campo obligatorio", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
        : Yup.number(),
      userActivateTokenTimeUnit: validationsTabs.current.tokenValidation
        ? Yup.number().test("isNotZero", "Campo obligatorio", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
        : Yup.number(),
      assignPasswordTokenTimeUnit: validationsTabs.current.tokenValidation
        ? Yup.number().test("isNotZero", "Campo obligatorio", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
        : Yup.number(),
      refreshTokenExpirationTimeUnit: validationsTabs.current.tokenValidation
        ? Yup.number().test("isNotZero", "Campo obligatorio", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
        : Yup.number(),
      maxRefreshTokens: Yup.lazy((value) =>
        validationsTabs.current.tokenValidation
          ? Yup.number().min(-1).test("isNotZero", "El valor no puede ser 0", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
          : Yup.number()
      ),
      maxRefreshTokensByUser: Yup.lazy((value) =>
        validationsTabs.current.tokenValidation
          ? Yup.number().min(-1).max(255).test('max', 'El valor no puede ser mayor a 255', (value) => value <= 255)
            .test('min', 'El valor no puede ser menor a 0', (value) => value >= 0).typeError(requiredMessage).required(requiredMessage)
          : Yup.number()
      ),
      maxConcurrentTokens: Yup.lazy((value) =>
        validationsTabs.current.tokenValidation
          ? watch('isConcurrentSession')
            ? Yup.number().min(1).max(255).test('max', 'El valor no puede ser mayor a 255', (value) => value <= 255)
              .test('min', 'El valor no puede ser menor a 1', (value) => value >= 1)
              .typeError(requiredMessage).required(requiredMessage)
            : Yup.number()
          : Yup.number()
      ),
      maxConcurrentTokensByUser: Yup.lazy((value) =>
        validationsTabs.current.tokenValidation
          ? Yup.number().min(-1).test("isNotZero", "El valor no puede ser 0", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
          : Yup.number()
      ),
      isConcurrentSession: Yup.boolean(),
      numberPasswordChanges: Yup.lazy((value) =>
        validationsTabs.current.passwordValidation
          ? Yup.number().typeError(requiredMessage).required(requiredMessage)
          : Yup.number()
      ),
      passwordExpiration: Yup.lazy((value) =>
        validationsTabs.current.passwordValidation
          ? Yup.number().min(-1).test("isNotZero", "El valor no puede ser 0", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
          : Yup.number()
      ),
      passwordTypeExpiration: validationsTabs.current.passwordValidation
        ? Yup.number().test("isNotZero", "Campo obligatorio", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
        : Yup.number(),
      mechanismAfterSetPass: validationsTabs.current.settingsValidation
        ? Yup.number().test("isNotZero", "Campo obligatorio", (value) => value !== 0).typeError(requiredMessage).required(requiredMessage)
        : Yup.number(),
      typeApplication: Yup.boolean()
    })
    .typeError(requiredMessage);

  const {
    control,
    handleSubmit,
    errors,
    formState,
    trigger,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: "onChange",
  });

  useEffect(() => {
    if (app.id !== 0 && !isEmpty(app)) {
      let newData = {};
      for (const key in initialState) {
        setValue(key, app[key] !== null ? app[key] : "")
        newData = { ...newData, [key]: app[key] !== null ? app[key] : "" }
      }
      setFormData(newData);
      originalInfo.current = newData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  useEffect(() => {
    if (!isEmpty(applicationCreated)) {
      setLoading(false);
      setConfirmInactivate({
        open: true,
        message: "La aplicación fue creada con éxito",
        item: actions.CREATED,
        checked: false,
      });
      dispatch({
        type: APPLICATION_CREATED,
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationCreated, setLoading]);

  useEffect(() => {
    if (!isEmpty(applicationModified)) {
      setLoading(false);

      const message = tab === 0
        ? "Los datos básicos"
        : tab === 1
          ? "Las opciones de token"
          : tab === 2
            ? "Las opciones de contraseña"
            : "Las opciones de configuración";
      setConfirmInactivate({
        ...confirmInactivate,
        open: true,
        message: `${message} han sido actualizados`,
        item: actions.UPDATE,
        showBtnAccept: false,
        showBtnCancel: false,
      });
      dispatch({
        type: APPLICATION_MODIFIED,
        payload: {},
      });
      dispatch({
        type: GET_APPLICATIONS,
        payload: [],
      });
      setApp({});
      getApplications();
      activeValidations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationModified, setLoading]);

  useEffect(() => {
    if (!!appsReducer.length && isEmpty(app)) {
      setApp(appsReducer.find((item) => item.id === originalInfo.current.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appsReducer]);


  useEffect(() => {
    if (!isEmpty(applicationError)) {
      setAppError(applicationError.result.information);
      setLoading(false);
      dispatch({
        type: APPLICATION_ERROR,
        payload: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationError]);

  const activeValidations = () => {
    if (confirmInactivate.changeTab) {
      setTab(confirmInactivate.newTab);
      changeValidator(confirmInactivate.newTab)
    }

    if (confirmInactivate.back) {
      if (tab === 0) {
        restartFields();
      } else {
        setTab(tab - 1);
      }
    }

    if (confirmInactivate.cancelEdit) {
      setEnabledForm(false);
    }
  };

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    setAppError("");
    if (app.id === 0) {
      await trigger();
      const responseError = validateErrorManual();
      if (!isEmpty(formState.errors) || responseError) {
        return;
      }
      setTab(newTab)
      changeValidator(newTab)
    } else if (enabledForm) {
      if (!validateEquals(changeStringToNumber(), originalInfo.current)) {
        showMessageSaveOrUpdate({
          newTab: newTab,
          changeTab: true,
          cancelEdit: false,
          back: false,
          item: actions.PARTIAL_UPDATED
        });
      } else {
        setTab(newTab)
        changeValidator(newTab)
      }

    } else {
      setTab(newTab)
      changeValidator(newTab)
    }
  }

  const changeStringToNumber = () => {
    const data = { ...getValues() };
    for (const key in data) {
      if (parseInt(data[key])) {
        data[key] = parseInt(data[key]);
      }
    }
    return data;
  }

  const showMessageSaveOrUpdate = (props) => {
    setConfirmInactivate({
      ...confirmInactivate,
      ...props,
      open: true,
      message: props.item !== actions.NOT_CHANGES ? (
        <div>
          Hiciste modificaciones
          <br />
          ¿Deseas actualizarlas?
        </div>
      ) : "No hay cambios para guardar",
      showBtnAccept: props.item !== actions.NOT_CHANGES ? true : false,
      showBtnCancel: props.item !== actions.NOT_CHANGES ? true : false,
    });
  };

  const validateErrorManual = () => {
    if (tab === 1) {
      if (getValues().timeUnit === 0) {
        setError("timeUnit", {
          type: "manual",
          message: requiredMessage,
        });
        return true;
      }

      if (getValues().refreshTokenExpirationTimeUnit === 0) {
        setError("refreshTokenExpirationTimeUnit", {
          type: "manual",
          message: requiredMessage,
        });
        return true;
      }

      if (getValues().userActivateTokenTimeUnit === 0) {
        setError("userActivateTokenTimeUnit", {
          type: "manual",
          message: requiredMessage,
        });
        return true;
      }

      if (getValues().assignPasswordTokenTimeUnit === 0) {
        setError("assignPasswordTokenTimeUnit", {
          type: "manual",
          message: requiredMessage,
        });
        return true;
      }
    }
    if (tab === 2) {
      if (getValues().passwordTypeExpiration === 0) {
        setError("passwordTypeExpiration", {
          type: "manual",
          message: requiredMessage,
        });
        return true;
      }
    }

    if (tab === 3) {
      if (getValues().mechanismAfterSetPass === 0) {
        setError("mechanismAfterSetPass", {
          type: "manual",
          message: requiredMessage,
        });
        return true;
      }
    }
    return false;
  }

  const saveApplication = async (e) => {
    e.preventDefault();
    setAppError("");
    await trigger();
    const responseError = validateErrorManual();
    if (!isEmpty(formState.errors) || responseError) {
      return;
    }
    setLoading(true);
    createApplication(changeStringToNumber());
  }

  const changeValidator = (newTab) => {
    Object.keys(initialValidate).forEach((key, index) => {
      if (index === newTab) {
        validationsTabs.current = { ...validationsTabs.current, [key]: true }
      } else {
        validationsTabs.current = { ...validationsTabs.current, [key]: false }
      }
    });
  }

  const restartFields = () => {
    setEnabledForm(true);
    setCardState(false);
    getApplications();
    changeValidator(0)
  };

  const backButton = async (e) => {
    if (tab === 0) {
      if (app.id === 0) {
        restartFields()
      } else if (enabledForm) {
        if (!validateEquals(changeStringToNumber(), originalInfo.current)) {
          showMessageSaveOrUpdate({
            newTab: false,
            changeTab: false,
            cancelEdit: false,
            back: true,
            item: actions.PARTIAL_UPDATED
          });
        } else {
          restartFields()
        }
      } else {
        restartFields()
      }
    }

    if (tab === 1 || tab === 2 || tab === 3) {
      if (app.id === 0) {
        handleChangeTab(e, tab - 1)
      } else if (enabledForm) {
        if (!validateEquals(changeStringToNumber(), originalInfo.current)) {
          showMessageSaveOrUpdate({
            newTab: false,
            changeTab: false,
            cancelEdit: false,
            back: true,
            item: actions.PARTIAL_UPDATED
          });
        } else {
          setTab(tab - 1)
        }
      } else {
        setTab(tab - 1)
      }
      changeValidator(tab - 1)
    }
  };

  const handleChangeRadio = (evt) => {
    setValue(evt.target.name, parseInt(evt.target.value));
    clearErrors(evt.target.name)
    // if (evt.target.name === 'refreshTokenExpirationTimeUnit') {
    //     clearErrors('refreshTokenExpirationAmountTime')
    // }
  };

  const handleChangeEdit = (response) => {
    if (!response) {
      if (!validateEquals(changeStringToNumber(), originalInfo.current)) {
        showMessageSaveOrUpdate({
          newTab: false,
          changeTab: false,
          cancelEdit: true,
          back: false,
          item: actions.PARTIAL_UPDATED
        });
      } else {
        setEnabledForm(response);
      }
    } else {
      setEnabledForm(response);
    }
  }

  const handleAccept = (e) => {
    if (confirmInactivate.item === actions.CREATED) {
      restartFields();
    }

    if (confirmInactivate.item === actions.PARTIAL_UPDATED) {
      saveApplication(e);
      setConfirmInactivate((state) => ({
        ...state,
        open: false,
      }));
    }

    if (confirmInactivate.item === actions.UPDATE) {
      closeAlertDefault()
    }

    if (confirmInactivate.item === actions.NOT_CHANGES) {
      setConfirmInactivate((state) => ({
        ...state,
        open: false,
      }));
      if (tab === 2) {
        restartFields();
      }
    }
  }

  const handleCancel = () => {
    if (confirmInactivate.item === actions.PARTIAL_UPDATED) {
      for (const key in initialState) {
        setValue(key, originalInfo.current[key]);
      }
    }
    clearErrors();
    activeValidations();
    closeAlertDefault();
  }

  const closeAlertDefault = () => {
    setConfirmInactivate({
      open: false,
      checked: false,
      back: false,
      cancelEdit: false,
      changeTab: false,
      newTab: 0,
      item: ""
    });
  };

  const handleChangeCounter = async (field, action) => {
    if (action === "up") {
      if (field !== "") {
        setValue(field, parseInt(getValues()[field]) + 1, { shouldValidate: true });
      }
    }

    if (action === "down") {
      if (field !== "") {
        if (getValues()[field] >= 1) {
          setValue(field, parseInt(getValues()[field]) - 1, { shouldValidate: true });
        } else {
          setValue(field, parseInt(getValues()[field]), { shouldValidate: true });
        }
      }
    }
  }

  const defineSubHeader = () => {
    let subheader = "";
    if (app.id !== 0) {
      switch (tab) {
        case 0:
          subheader = enabledForm
            ? "Editar datos básicos"
            : "Detalle de datos básicos";
          break;
        case 1:
          subheader = enabledForm ? "Editar token" : "Detalles de token";
          break;
        case 2:
          subheader = enabledForm
            ? "Editar contraseña"
            : "Detalles de contraseña";
          break;
        default:
          subheader = "Nueva aplicación";
          break;
      }
    } else {
      subheader = "Nueva aplicación";
    }
    return subheader;
  };

  return (
    <FormLayout
      handleSubmit={(e) => e.preventDefault()}
      handleIconClick={(e) => {
        backButton(e);
      }}
      subheader={defineSubHeader()}
      isEditing={!!app.id}
      enabledForm={enabledForm}
      setEnabledForm={handleChangeEdit}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      handleAccept={handleAccept}
      handleCancel={handleCancel}>
      <TabComponent
        aria={"application tabs"}
        handleChangeTab={handleChangeTab}
        tab={tab}
        children={[
          {
            label: "Datos Básicos",
            tabContent: (
              <Grid container spacing={2}>
                <Grid container item lg={6} md={12} sm={12} xs={12}>
                  <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                      <ControlledInputRoundedForm
                        id="name"
                        name="name"
                        label="Nombre aplicación"
                        fullWidth
                        pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s]/g}
                        inputProps={{ maxLength: 50 }}
                        disabled={!enabledForm}
                        error={errors.name}
                        helperText={errors.name?.message}
                        control={control}
                      />
                    </Grid>
                    <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justifyContent="center">
                      <Tooltip title={"Hace referencia a como es nombrada la aplicación internamente"}>
                        <HelpOutline className={classes.helpIcon} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container item lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={11} md={11} sm={11} xs={11}>
                      <ControlledInputRoundedForm
                        id="title"
                        name="title"
                        label="Nombre comercial de la aplicación"
                        fullWidth
                        pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s]/g}
                        inputProps={{ maxLength: 60 }}
                        disabled={!enabledForm}
                        error={errors.title}
                        helperText={errors.title?.message}
                        control={control}
                      />
                    </Grid>
                    <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justifyContent="center">
                      <Tooltip title={"Hace referencia a como será nombrada la aplicación comercialmente"}>
                        <HelpOutline className={classes.helpIcon} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid lg={11} md={11} sm={11} xs={11}>
                    <ControlledInputRoundedForm
                      id="urlAssignPassword"
                      name="urlAssignPassword"
                      label="URL asignación de contraseña"
                      disabled={!enabledForm}
                      fullWidth
                      control={control}
                      error={errors.urlAssignPassword}
                      helperText={errors.urlAssignPassword?.message}
                    />
                  </Grid>
                  <Grid lg={11} md={11} sm={11} xs={11}>
                    <ControlledInputRoundedForm
                      id="landing"
                      name="landing"
                      label="URL Landing"
                      fullWidth
                      disabled={!enabledForm}
                      control={control}
                      error={errors.landing}
                      helperText={errors.landing?.message}
                    />
                  </Grid>
                  <Grid lg={11} md={11} sm={11} xs={11}>
                    <ControlledInputRoundedForm
                      id="typeApplication"
                      name="typeApplication"
                      select
                      label="Seleccione el tipo de aplicación"
                      fullWidth
                      control={control}
                      disabled={!enabledForm}
                      error={errors.typeApplication}
                      helperText={errors.typeApplication?.message}
                    >
                      <MenuItem key={makeId(10)} value={true}>
                        Transversal
                      </MenuItem>
                      <MenuItem key={makeId(10)} value={false}>
                        Producto
                      </MenuItem>
                    </ControlledInputRoundedForm>
                  </Grid>
                  <Grid container item lg={12} md={12} sm={12} xs={12} style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                    <Grid lg={11} md={11} sm={11} xs={11}>
                      <FormControlLabel
                        className={classes.textField}
                        label="Es visible en el componente de la lista de aplicaciones"
                        variant="outlined"
                        margin="dense"
                        labelPlacement="end"
                        control={
                          <ControlledSwitchComponent
                            id="isVisible"
                            name="isVisible"
                            color="primary"
                            isDisabled={!enabledForm}
                            control={control}
                          />
                        }
                      />
                    </Grid>
                    <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justifyContent="center">
                      <Tooltip title={"Este campo indica si el nombre de la aplicación aparece o no en el componente de la lista de aplicaciones"}>
                        <HelpOutline className={classes.helpIcon} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Grid item lg={12} md={11} sm={11} xs={11}>
                    <ControlledInputRoundedForm
                      id="urlApp"
                      name="urlApp"
                      label="URL aplicación"
                      control={control}
                      fullWidth
                      disabled={!enabledForm}
                      error={errors.urlApp}
                      helperText={errors.urlApp?.message}
                    />
                  </Grid>
                  <Grid item lg={12} md={11} sm={11} xs={11}>
                    <ControlledInputRoundedForm
                      id="description"
                      name="description"
                      label="Descripción"
                      control={control}
                      multiline
                      fullWidth
                      rows={7}
                      inputProps={{ maxLength: 100 }}
                      disabled={!enabledForm}
                      error={errors.description}
                      helperText={errors.description?.message}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container item lg={6} md={12} sm={12} xs={12}>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} style={{ paddingLeft: "10px", paddingTop: "5px" }}>
                                        <Grid lg={11} md={11} sm={11} xs={11}>
                                            <FormControlLabel
                                                className={classes.textField}
                                                label="Tiene prueba gratis"
                                                variant="outlined"
                                                margin="dense"
                                                labelPlacement="end"
                                                control={
                                                    <ControlledSwitchComponent
                                                        id="isFreeTrial"
                                                        name="isFreeTrial"
                                                        color="primary"
                                                        isDisabled={!enabledForm}
                                                        control={control}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid container item lg={1} md={1} sm={1} xs={1} alignItems="center" justifyContent="center">
                                            <Tooltip title={"Este campo indica si la aplicación tiene prueba gratis"}>
                                                <HelpOutline className={classes.helpIcon} />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item lg={6} md={12} sm={12} xs={12}>
                                    <br></br>
                                </Grid>
                                <Grid container item lg={6} md={12} sm={12} xs={12}>
                                    <Grid item lg={11} md={11} sm={11} xs={11}>
                                        <ControlledInputRoundedForm
                                            id="urlFreeTrial"
                                            name="urlFreeTrial"
                                            label="URL de prueba gratis"
                                            control={control}
                                            fullWidth
                                            disabled={!enabledForm}
                                            error={errors.urlFreeTrial}
                                            helperText={errors.urlFreeTrial?.message}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item lg={6} md={12} sm={12} xs={12}>
                                    <Grid item lg={12} md={11} sm={11} xs={11}>
                                        <ControlledInputRoundedForm
                                            id="codeFreeTrial"
                                            name="codeFreeTrial"
                                            label="Código de prueba gratis"
                                            control={control}
                                            fullWidth
                                            disabled={!enabledForm}
                                            error={errors.codeFreeTrial}
                                            helperText={errors.codeFreeTrial?.message}
                                        />
                                    </Grid>
                                </Grid> */}
                {app.id !== 0 ? (
                  <Grid container item direction="row" lg={12} md={12} sm={12} xs={12} justifyContent="center">
                    <Grid container item style={{ marginTop: "10px" }} lg={4} md={4} sm={6} xs={8}>
                      <ButtonSaveChanges
                        handleClick={async (e) => {
                          setAppError("");
                          await trigger();
                          if (!isEmpty(formState.errors)) {
                            return;
                          }
                          if (!validateEquals(changeStringToNumber(), originalInfo.current)) {
                            saveApplication(e)
                          } else {
                            showMessageSaveOrUpdate({
                              newTab: false,
                              changeTab: false,
                              cancelEdit: false,
                              back: false,
                              item: actions.NOT_CHANGES
                            });
                          }
                        }}
                        color="primary"
                        title={"Actualizar datos"}
                        variant="contained"
                        id="buttonSave"
                        className={classes.buttonSave}
                        margin="dense"
                        isDisabled={!enabledForm}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            ),
          },
          {
            label: "Token",
            tabContent: (
              <Grid container spacing={1}>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={4} md={3} sm={6} xs={6}>
                    <Typography margin="dense">
                      Duración token
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={3} sm={6} xs={6}>
                    <ControlledInputRoundedForm
                      id="amountTime"
                      name="amountTime"
                      control={control}
                      fullWidth
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value) ? parseInt(e.target.value) : 0).toString().slice(0, 6);
                      }}
                      inputProps={{
                        min: "1",
                        max: "999999",
                        maxLength: 6,
                      }}
                      disabled={!enabledForm}
                      error={errors.amountTime}
                      helperText={errors.amountTime?.message}
                      adornment={{
                        endAdornment: (
                          <Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button Up"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("amountTime", "up")} >
                                <ArrowDropUp />
                              </IconButton>
                            </Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button down"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("amountTime", "down")} >
                                <ArrowDropDown />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item direction="column" lg={5} md={6} sm={12} xs={12}>
                    <FormControl component="fieldset" disabled={!enabledForm}>
                      <RadioGroup
                        row
                        aria-label="Amount Time"
                        value={watch('timeUnit')}
                        onChange={handleChangeRadio}>
                        <FormControlLabel
                          name="timeUnit"
                          InputRef={control}
                          value={1}
                          control={<StyledRadioButton />}
                          label="Minutos"
                        />
                        <FormControlLabel
                          name="timeUnit"
                          InputRef={control}
                          value={2}
                          control={<StyledRadioButton />}
                          label="Horas"
                        />
                        <FormControlLabel
                          name="timeUnit"
                          InputRef={control}
                          value={3}
                          control={<StyledRadioButton />}
                          label="Dias"
                        />
                      </RadioGroup>
                    </FormControl>
                    <ErrorMessage
                      errors={errors}
                      name="timeUnit"
                      as="p"
                      style={{ color: "#D80000" }}
                      color="error"
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={11} md={12} sm={12} xs={12}>
                    <Divider style={{ height: "1px", width: "100%" }} />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={4} md={3} sm={6} xs={6}>
                    <Typography margin="dense">
                      Duración refresh token
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={3} sm={6} xs={6}>
                    <ControlledInputRoundedForm
                      id="refreshTokenExpirationAmountTime"
                      name="refreshTokenExpirationAmountTime"
                      control={control}
                      fullWidth
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value) ? parseInt(e.target.value) : 0).toString().slice(0, 6);
                      }}
                      inputProps={{
                        min: "1",
                        max: "999999",
                        maxLength: 6,
                      }}
                      disabled={!enabledForm}
                      error={errors.refreshTokenExpirationAmountTime}
                      helperText={errors.refreshTokenExpirationAmountTime?.message}
                      adornment={{
                        endAdornment: (
                          <Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button UP"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("refreshTokenExpirationAmountTime", "up")} >
                                <ArrowDropUp />
                              </IconButton>
                            </Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button down"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("refreshTokenExpirationAmountTime", "down")} >
                                <ArrowDropDown />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item direction="column" lg={5} md={6} sm={12} xs={12}>
                    <FormControl component="fieldset" disabled={!enabledForm}>
                      <RadioGroup
                        row
                        aria-label="Refreshtoken time Unit"
                        value={watch('refreshTokenExpirationTimeUnit')}
                        onChange={handleChangeRadio}>
                        <FormControlLabel
                          name="refreshTokenExpirationTimeUnit"
                          InputRef={control}
                          value={1}
                          control={<StyledRadioButton />}
                          label="Minutos"
                        />
                        <FormControlLabel
                          name="refreshTokenExpirationTimeUnit"
                          InputRef={control}
                          value={2}
                          control={<StyledRadioButton />}
                          label="Horas"
                        />
                        <FormControlLabel
                          name="refreshTokenExpirationTimeUnit"
                          InputRef={control}
                          value={3}
                          control={<StyledRadioButton />}
                          label="Dias"
                        />
                      </RadioGroup>
                    </FormControl>
                    <ErrorMessage
                      errors={errors}
                      name="refreshTokenExpirationTimeUnit"
                      as="p"
                      style={{ color: "#D80000" }}
                      color="error"
                    ></ErrorMessage>
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={11} md={12} sm={12} xs={12}>
                    <Divider style={{ height: "1px", width: "100%" }} />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={4} md={3} sm={6} xs={6}>
                    <Typography margin="dense">
                      Duración del token para activar usuario
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={3} sm={6} xs={6}>
                    <ControlledInputRoundedForm
                      id="userActivateTokenTime"
                      name="userActivateTokenTime"
                      control={control}
                      fullWidth
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value) ? parseInt(e.target.value) : 0).toString().slice(0, 6);
                      }}
                      inputProps={{
                        min: "1",
                        max: "999999",
                        maxLength: 6,
                      }}
                      disabled={!enabledForm}
                      error={errors.userActivateTokenTime}
                      helperText={errors.userActivateTokenTime?.message}
                      adornment={{
                        endAdornment: (
                          <Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button UP"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("userActivateTokenTime", "up")} >
                                <ArrowDropUp />
                              </IconButton>
                            </Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button down"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("userActivateTokenTime", "down")} >
                                <ArrowDropDown />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item direction="column" lg={5} md={6} sm={12} xs={12}>
                    <FormControl component="fieldset" disabled={!enabledForm}>
                      <RadioGroup
                        row
                        aria-label="Amount Time"
                        value={watch('userActivateTokenTimeUnit')}
                        onChange={handleChangeRadio}>
                        <FormControlLabel
                          name="userActivateTokenTimeUnit"
                          InputRef={control}
                          value={1}
                          control={<StyledRadioButton />}
                          label="Minutos"
                        />
                        <FormControlLabel
                          name="userActivateTokenTimeUnit"
                          InputRef={control}
                          value={2}
                          control={<StyledRadioButton />}
                          label="Horas"
                        />
                        <FormControlLabel
                          name="userActivateTokenTimeUnit"
                          InputRef={control}
                          value={3}
                          control={<StyledRadioButton />}
                          label="Dias"
                        />
                      </RadioGroup>
                    </FormControl>
                    <ErrorMessage
                      errors={errors}
                      name="userActivateTokenTimeUnit"
                      as="p"
                      style={{ color: "#D80000" }}
                      color="error"
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={11} md={12} sm={12} xs={12}>
                    <Divider style={{ height: "1px", width: "100%" }} />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={4} md={3} sm={6} xs={6}>
                    <Typography margin="dense">
                      Duración del token para restablecer contraseña
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={3} sm={6} xs={6}>
                    <ControlledInputRoundedForm
                      id="assignPasswordTokenTime"
                      name="assignPasswordTokenTime"
                      control={control}
                      fullWidth
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value) ? parseInt(e.target.value) : 0).toString().slice(0, 6);
                      }}
                      inputProps={{
                        min: "1",
                        max: "999999",
                        maxLength: 6,
                      }}
                      disabled={!enabledForm}
                      error={errors.assignPasswordTokenTime}
                      helperText={errors.assignPasswordTokenTime?.message}
                      adornment={{
                        endAdornment: (
                          <Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button UP"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("assignPasswordTokenTime", "up")} >
                                <ArrowDropUp />
                              </IconButton>
                            </Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button down"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("assignPasswordTokenTime", "down")} >
                                <ArrowDropDown />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item direction="column" lg={5} md={6} sm={12} xs={12}>
                    <FormControl component="fieldset" disabled={!enabledForm}>
                      <RadioGroup
                        row
                        aria-label="Amount Time"
                        value={watch('assignPasswordTokenTimeUnit')}
                        onChange={handleChangeRadio}>
                        <FormControlLabel
                          name="assignPasswordTokenTimeUnit"
                          InputRef={control}
                          value={1}
                          control={<StyledRadioButton />}
                          label="Minutos"
                        />
                        <FormControlLabel
                          name="assignPasswordTokenTimeUnit"
                          InputRef={control}
                          value={2}
                          control={<StyledRadioButton />}
                          label="Horas"
                        />
                        <FormControlLabel
                          name="assignPasswordTokenTimeUnit"
                          InputRef={control}
                          value={3}
                          control={<StyledRadioButton />}
                          label="Dias"
                        />
                      </RadioGroup>
                    </FormControl>
                    <ErrorMessage
                      errors={errors}
                      name="assignPasswordTokenTimeUnit"
                      as="p"
                      style={{ color: "#D80000" }}
                      color="error"
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={11} md={12} sm={12} xs={12}>
                    <Divider style={{ height: "1px", width: "100%" }} />
                  </Grid>
                </Grid>
                {/* <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={4} md={3} sm={6} xs={6}>
                    <Typography margin="dense">
                      Máximo de refresh tokens por usuario
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={3} sm={5} xs={5}>
                    <ControlledInputRoundedForm
                      id="maxRefreshTokensByUser"
                      name="maxRefreshTokensByUser"
                      control={control}
                      fullWidth
                      type="number"
                      inputProps={{ min: "0", step: "1", max: "255" }}
                      onInput={(e) => {
                        e.target.value = Math.max(
                          0,
                          parseInt(e.target.value) ? parseInt(e.target.value) : 0
                        )
                          .toString()
                          .slice(0, 10);
                      }}
                      disabled={!enabledForm}
                      error={errors.maxRefreshTokensByUser}
                      helperText={errors.maxRefreshTokensByUser?.message}
                      adornment={{
                        endAdornment: (
                          <Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button UP"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("maxRefreshTokensByUser", "up")} >
                                <ArrowDropUp />
                              </IconButton>
                            </Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button down"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: enabledForm ? 1 : 0.5 }}
                                disabled={!enabledForm}
                                onClick={(e) => handleChangeCounter("maxRefreshTokensByUser", "down")} >
                                <ArrowDropDown />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={11} md={12} sm={12} xs={12}>
                    <Divider style={{ height: "1px", width: "100%" }} />
                  </Grid>
                </Grid> */}
                <Grid container lg={12}>
                  <br></br>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12}>
                  <Grid item lg={4} md={6} sm={11} xs={11} style={{ paddingLeft: "15px" }}>
                    <FormControlLabel
                      className={classes.textField}
                      label="Sesiones concurrentes"
                      variant="outlined"
                      margin="dense"
                      labelPlacement="end"
                      control={
                        <ControlledSwitchComponent
                          id="isConcurrentSession"
                          name="isConcurrentSession"
                          color="primary"
                          isDisabled={!enabledForm}
                          control={control}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={4} md={3} sm={6} xs={6}>
                    <Typography margin="dense">
                      Máximo número de sesiones concurrentes
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={3} sm={5} xs={5}>
                    <ControlledInputRoundedForm
                      id="maxConcurrentTokens"
                      name="maxConcurrentTokens"
                      control={control}
                      fullWidth
                      inputProps={{ min: "1", step: "1", max: "255" }}
                      onInput={(e) => {
                        e.target.value = Math.max(1, parseInt(e.target.value) ? parseInt(e.target.value) : 0).toString().slice(0, 3);
                      }}
                      disabled={!!app.id ? !watch('isConcurrentSession') || !enabledForm : !watch('isConcurrentSession')}
                      error={errors.maxConcurrentTokens}
                      helperText={errors.maxConcurrentTokens?.message}
                      adornment={{
                        endAdornment: (
                          <Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button UP"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: (enabledForm && watch('isConcurrentSession')) ? 1 : 0.5 }}
                                disabled={!!app.id ? !watch('isConcurrentSession') || !enabledForm : !watch('isConcurrentSession')}
                                onClick={(e) => handleChangeCounter("maxConcurrentTokens", "up")} >
                                <ArrowDropUp />
                              </IconButton>
                            </Grid>
                            <Grid lg={12} className={classes.GridAdornmentIcon}>
                              <IconButton
                                aria-label="Button down"
                                style={{ padding: "0px", color: theme.palette.primary.light, opacity: (enabledForm && watch('isConcurrentSession')) ? 1 : 0.5 }}
                                disabled={!!app.id ? !watch('isConcurrentSession') || !enabledForm : !watch('isConcurrentSession')}
                                onClick={(e) => handleChangeCounter("maxConcurrentTokens", "down")} >
                                <ArrowDropDown />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={1} md={3} sm={1} xs={1}>
                    <Tooltip title={"Valor desde 1 hasta 255"}>
                      <HelpOutline className={classes.helpIcon} />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container item spacing={2} lg={11} md={12} sm={12} xs={12} alignItems='center'>
                  <Grid item lg={11} md={12} sm={12} xs={12}>
                    <Divider style={{ height: "1px", width: "100%" }} />
                  </Grid>
                </Grid>
                {app.id !== 0 && (
                  <Grid container item direction="row" lg={12} md={12} sm={12} xs={12} justifyContent="center">
                    <Grid container item style={{ marginTop: "10px" }} lg={4} md={4} sm={6} xs={8}>
                      <ButtonSaveChanges
                        handleClick={async (e) => {
                          setAppError("");
                          await trigger();
                          const responseError = validateErrorManual();
                          if (!isEmpty(formState.errors) || responseError) {
                            return;
                          }
                          if (!validateEquals(changeStringToNumber(), originalInfo.current)) {
                            saveApplication(e)
                          } else {
                            showMessageSaveOrUpdate({
                              newTab: false,
                              changeTab: false,
                              cancelEdit: false,
                              back: false,
                              item: actions.NOT_CHANGES
                            });
                          }
                        }}
                        title={"Actualizar token"}
                        color="primary"
                        variant="contained"
                        id="buttonSave"
                        className={classes.buttonSave}
                        margin="dense"
                        isDisabled={!enabledForm}
                      />{" "}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ),
          },
          {
            label: "Contraseña",
            tabContent: (
              <Grid container spacing={1}>
                <Grid container item direction="row" alignItems="center" spacing={1} lg={8} >
                  <Grid item lg={10}>
                    <ControlledInputRoundedForm
                      id="numberPasswordChanges"
                      name="numberPasswordChanges"
                      fullWidth
                      control={control}
                      label="Numero de cambios de contraseña"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10);
                      }}
                      type="number"
                      inputProps={{ min: "-1", step: "1" }}
                      disabled={!enabledForm}
                      error={errors.numberPasswordChanges}
                      helperText={errors.numberPasswordChanges?.message}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <Tooltip title={"Valor numérico desde -1"}>
                      <HelpOutline className={classes.helpIcon} />
                    </Tooltip>
                  </Grid>
                  <Grid item spacing={2} lg={5}>
                    <ControlledInputRoundedForm
                      id="passwordExpiration"
                      name="passwordExpiration"
                      control={control}
                      label="Expiracion de Contraseña"
                      fullWidth
                      onInput={(e) => {
                        e.target.value = Math.max(-1, parseInt(e.target.value)).toString().slice(0, 10);
                      }}
                      type="number"
                      inputProps={{
                        min: "-1",
                        max: "9999999999",
                        maxLength: 12,
                      }}
                      disabled={!enabledForm}
                      error={errors.passwordExpiration}
                      helperText={errors.passwordExpiration?.message}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <Tooltip title={"Cantidad de dias o meses para el vencimiento de la clave"}>
                      <HelpOutline className={classes.helpIcon} />
                    </Tooltip>
                  </Grid>
                  <Grid item lg={4}>
                    <FormControl component="fieldset" disabled={!enabledForm}>
                      <RadioGroup
                        row
                        aria-label="Cantidad de tiempo"
                        value={watch('passwordTypeExpiration')}
                        onChange={handleChangeRadio}>
                        <FormControlLabel
                          name="passwordTypeExpiration"
                          InputRef={control}
                          value={3}
                          control={<StyledRadioButton />}
                          label="Dias"
                        />
                        <FormControlLabel
                          name="passwordTypeExpiration"
                          InputRef={control}
                          value={4}
                          control={<StyledRadioButton />}
                          label="Meses"
                        />
                      </RadioGroup>
                    </FormControl>
                    <ErrorMessage
                      errors={errors}
                      name="passwordTypeExpiration"
                      as="p"
                      style={{ color: "#D80000" }}
                      color="error"
                    ></ErrorMessage>
                  </Grid>
                  {app.id !== 0 && (
                    <Grid container item direction="row" lg={12} md={12} sm={12} xs={12} justifyContent="center">
                      <Grid container item style={{ marginTop: "10px" }} lg={4} md={4} sm={6} xs={8}>
                        <ButtonSaveChanges
                          handleClick={async (e) => {
                            setAppError("");
                            await trigger();
                            const responseError = validateErrorManual();
                            if (!isEmpty(formState.errors) || responseError) {
                              return;
                            }
                            if (!validateEquals(changeStringToNumber(), originalInfo.current)) {
                              saveApplication(e)
                            } else {
                              showMessageSaveOrUpdate({
                                newTab: false,
                                changeTab: false,
                                cancelEdit: false,
                                back: false,
                                item: actions.NOT_CHANGES
                              });
                            }
                          }}
                          title={"Actualizar Contraseña"}
                          color="primary"
                          variant="contained"
                          id="buttonSave"
                          className={classes.buttonSave}
                          margin="dense"
                          isDisabled={!enabledForm}
                        />{" "}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ),
          },
          {
            label: "Configuración",
            tabContent: (
              <Grid>
                <Typography margin="dense"  >
                  Interface siguiente al restablecer contraseña
                </Typography>
                <Grid item direction="column" lg={12} md={12} sm={12} xs={12}>
                  <FormControl component="fieldset" disabled={!enabledForm}>
                    <RadioGroup
                      aria-label="Mecanismo despues de restablecer la contraseña"
                      value={watch('mechanismAfterSetPass')}
                      onChange={handleChangeRadio}>
                      <FormControlLabel
                        name="mechanismAfterSetPass"
                        InputRef={control}
                        value={1}
                        control={<StyledRadioButton />}
                        label="Login de la aplicación"
                      />

                      <FormControlLabel
                        name="mechanismAfterSetPass"
                        InputRef={control}
                        value={2}
                        control={<StyledRadioButton />}
                        label="Home de la aplicación"
                      />
                    </RadioGroup>
                  </FormControl>
                  <ErrorMessage
                    errors={errors}
                    name="mechanismAfterSetPass"
                    as="p"
                    style={{ color: "#D80000" }}
                    color="error"
                  ></ErrorMessage>
                </Grid>
              </Grid>
            ),
          }
        ]}
      />
      <Grid item>
        {!!appError && (
          <div className={classes.errorText}>
            <Typography color="error" variant="subtitle2">
              {appError}
            </Typography>
          </div>
        )}
      </Grid>
      {tab < 3 ? (
        <Tooltip id="button-next" title="Siguiente" style={{ float: "right", marginTop: "18px" }}>
          <IconButton onClick={(e) => {
            const newTab = tab < 3 ? tab + 1 : tab;
            handleChangeTab(e, newTab);
          }}>
            <ArrowIcon className={classes.nextArrow} />
          </IconButton>
        </Tooltip>
      ) : (
        <Grid container item direction="row" lg={12} justifyContent="center">
          <Grid container item style={{ marginTop: "10px", marginBottom: "20px" }} lg={4}>
            <ButtonSaveChanges
              handleClick={async (e) => {
                if (app.id === 0) {
                  handleSubmit(saveApplication(e));
                } else {
                  setAppError("");
                  await trigger();
                  const responseError = validateErrorManual();
                  if (!isEmpty(formState.errors) || responseError) {
                    return;
                  }
                  if (!validateEquals(changeStringToNumber(), originalInfo.current)) {
                    saveApplication(e)
                  } else {
                    showMessageSaveOrUpdate({
                      newTab: false,
                      changeTab: false,
                      cancelEdit: false,
                      back: false,
                      item: actions.NOT_CHANGES
                    });
                  }
                }
              }}
              title={app.id === 0 ? "Guardar" : "Actualizar"}
              id="buttonSave"
              className={classes.buttonSave}
              isDisabled={!enabledForm}
            />
          </Grid>
        </Grid>
      )}
    </FormLayout>
  );
}

const mapStateToProps = (state) => ({
  applicationCreated: state.applicationsReducer.applicationCreated,
  applicationModified: state.applicationsReducer.applicationModified,
  appsReducer: state.applicationsReducer.getApplicationsResponse,
  applicationError: state.applicationsReducer.applicationError,
});

const mapDispatchToProps = {
  createApplication,
  getApplications,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm2);
