import { Fab, Grid, makeStyles, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { createApplication, getApplications } from '../../actions/applications.action';
import { APPLICATION_ACTIVATED } from '../../actions/types';
import NewUserIcon from '../../components/IconsLibrary/NewUserIcon';
import ViewLayout from '../../components/layouts/ViewLayout'
import CustomTable from '../../components/Table/CustomTable.component'
import { isEmpty } from '../../utils/proprietaryHooks';
import ApplicationForm2 from './ApplicationForm2.view';

const useStyles = makeStyles({
  styleFab: {
    boxShadow: 'none'
  }
})

const Applications = (props) => {
  const { getApplications, appsReducer, applicationActivated, createApplication } = props;
  // lista de estados 
  const [isFormShowed, setFormShowed] = useState(false);
  const [app, setApp] = useState(0);
  const [isLoading, setLoading] = useState(true)
  const [option, setOption] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [apps, setApps] = useState([])
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    checked: false,
    delete: false,
    message: "",
    id: 0
  })

  // effect handling 
  useEffect(() => {
    if (!appsReducer.length) {
      getApplications();
    }
  }, [appsReducer.length, getApplications])

  useEffect(() => {
    if (!!appsReducer.length) {

      setLoading(false)
      setApps(appsReducer.sort((a, b) => (a.idState === b.idState) ? 0 : a.idState ? 1 : -1))
    }
  }, [appsReducer])

  useEffect(() => {
    if (!isEmpty(applicationActivated)) {
      setLoading(false)
      setConfirmInactivate({
        open: true,
        message: `La aplicación ha sido actualizada`,
        item: 'inactivate',
        checked: false,
        showBtnAccept: false,
        showBtnCancel: false,
      })
      getApplications();
      dispatch({
        type: APPLICATION_ACTIVATED,
        payload: {}
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationActivated, setLoading])


  // lista de componentes de configuración de modelo de datos de columnas
  const columns = [
    {
      id: 'title', label: 'Nombre aplicación',
      haveDropDown: true, align: "center"
    },
    {
      id: 'urlApp', label: 'URL aplicación',
      haveDropDown: true, align: "left"
    },
    {
      id: 'amountTimeComb', label: 'Duración token',
      haveDropDown: true, align: "center",
    },
    {
      id: "idState",
      label: "Estado",
      minWidth: 90,
      align: "center",
      haveDropDown: true,
      component: {
        handleChange: (e, value, id) => handleConfirmClick(e, value, id)
      },
      handleIconClick: (evt, id) => handleDeleteClick(evt, id),
      withIcon: false,
      tooltipMessage: "aplicación",
      format: (value) => (value === 1 ? false : true),
    }
  ]

  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          lg: 7,
          md: 12,
          sm: 12
        }
      },
      menuOrder: {
        options: [
          {
            label: 'Activo',
            columns: [{
              prop: 'idState',
              direction: -1
            }]
          },
          {
            label: 'Inactivo', columns: [{
              prop: 'idState',
            },
            ]
          },
          {
            label: 'Más reciente',
            columns: [{ prop: 'modifiedOn', direction: 1, format: (date) => { return new Date(date).getTime() } }]
          },
          { label: 'Más antiguo', columns: [{ prop: 'modifiedOn', direction: -1, format: (date) => { return new Date(date).getTime() } }] }
        ],
        cellSize: {
          lg: 6,
          md: 2,
          sm: 2
        }
      },
      // exportButton: {
      //     cellSize: {
      //         lg: 6,
      //         md: 2,
      //         sm: 2
      //     }
      // },
      children: () => {
        return (
          <Grid container item lg={12} md={12} sm={2} xs={2} direction="row-reverse" >
            <Tooltip title={`Crear aplicación`} aria-label={`Nuevo usuario`}>
              <Fab
                className={classes.styleFab}
                size="small"
                onClick={() => {
                  setFormShowed(true);
                  setApp({ id: 0 })
                }}
              >
                <NewUserIcon />
              </Fab>
            </Tooltip>
          </Grid>
        )
      }
    }
    return button;
  }


  // event handling 

  const handleConfirmClick = (evt, value, id) => {
    evt.preventDefault();
    setLoading(true);
    // deseas activar esta aplicacion
    setConfirmInactivate({
      open: true,
      message: `¿Deseas ${evt.target.checked ? 'activar' : 'desactivar'} esta aplicación?`,
      showBtnAccept: true,
      showBtnCancel: true,
      checked: true,
      item: 'inactivate',
      id: value
    });
  }
  const handleDeleteClick = (evt) => {
    evt.preventDefault();
    setConfirmInactivate({
      open: true,
      item: 'delete',
      checked: false,
      message: '¿Deseas eliminar esta aplicación?'
    });
  }

  const handleConfirmChecked = () => {
    if (confirmInactivate.item === 'inactivate') {
      let application = appsReducer.find(item => item.id === confirmInactivate.id);
      application = {
        ...application,
        idState: (application.idState === 0) ? 1 : 0
      }
      createApplication(application, null, true);

    }
    setConfirmInactivate({
      open: false,
      item: 0,
      checked: false,
      message: ''
    });
  }

  const handleCellClick = (evt, row) => {
    setApp(row)
    setFormShowed(true)
  }

  const closeAlert = () => {
    setConfirmInactivate({
      open: false,
      checked: false
    })
    setLoading(false)
  }

  return (
    <ViewLayout
      headerTitle={'Aplicaciones'}
      handleAccept={handleConfirmChecked}
      confirmInactivate={confirmInactivate}
      setConfirmInactivate={setConfirmInactivate}
      handleConfirmClose={closeAlert}
      isLoading={isLoading}
    >
      {
        isFormShowed ?
          <ApplicationForm2
            app={app}
            setApp={setApp}
            setCardState={setFormShowed}
            setLoading={setLoading}
          /> :
          <CustomTable
            columns={columns}
            mainParam={'name'}
            data={apps}
            buttons={getButtons()}
            handleCellClick={handleCellClick}
            option={option}
            setOption={setOption}
            paramToDisable={'idState'}
            havePagination={true}

          />
      }
    </ViewLayout>
  )
}

const mapStateToProps = (state) => ({
  appsReducer: state.applicationsReducer.getApplicationsResponse,
  appChanged: state.applicationsReducer.applicationCreated,
  applicationError: state.applicationsReducer.applicationError,
  applicationActivated: state.applicationsReducer.applicationActivated
})


const mapDispatchToProps = {
  getApplications,
  createApplication
}

export default connect(mapStateToProps, mapDispatchToProps)(Applications)
