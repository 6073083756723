export const regexEmail = /[^-_.@A-Za-z0-9]/g;
export const regexPhone = /[^-+()0-9\s]/g;
export const regexOnlyNumbers = /[^0-9]{0,}$/g;
export const regexAlphaNumeric = /([^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s]+)$/g;
export const regexAlphabet = /([^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+)$/g;
export const regexContainsNumbers = /\d+/;
export const regexContainsLetters = /[a-zA-Z]/;
export const regexContainsUpperLetter = /[A-Z]/;
export const regexContainsLowerLetter = /[a-z]/;
export const regexContainsSpecialCharacter = /[!#$%&/(){}<=>?¿¡*;:,.+_-]/;

export const hasNumbers = (text) => {
  return regexContainsNumbers.test(text);
};

export const hasLetters = (text) => {
  return regexContainsLetters.test(text);
};
export const hasUpperLetter = (text) => {
  return regexContainsUpperLetter.test(text);
};
export const hasLowerLetter = (text) => {
  return regexContainsLowerLetter.test(text);
};
export const hasSpecialCharacter = (text) => {
  return regexContainsSpecialCharacter.test(text);
};

export const isCorrectLength = (text, length) => {
  return text.length >= length;
};

export const regexText = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  let regex = /^[A-Za-z áéíóúüñÁÉÍÓÚÜÑ]*$/;
  if (!regex.test(keyValue)) return false;
  else return true;
};
