import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import {
  makeStyles,
  TextField,
  withStyles,
} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import ReCAPTCHA from "react-google-recaptcha";
import { Rules } from '../../../utils/RHFRules';
import ErrorBox from "../../../components/errorBox.component";
import { getDocumentTypes, getExistsUsersAccount } from "../../../actions/auth.action";
import { getEnterprise } from "../../../actions/enterprise.action";
import { getParmeterConfigAction } from "../../../actions/applications.action";
import PageBackground from "../../../components/pageBackground.component";
import CardBody from "../../../components/CardBody/cardBody.component";
import PrimaryButtonUserCertificate from "../../../components/primaryButtonUserCertificate.component";
import LoginCertificate from "./LoginCertificate";
import MaintenanceView from "../../notifications/MaintenanceView";

let isVerified = false
let tryFailedByRecaptcha = 0;

function onChange(value) {
  if (value) {
    isVerified = true;
  } else {
    tryFailedByRecaptcha = 1;
  }
}

const UserCertificate = (props) => {
  const {
    appName,
    getDocumentTypes,
    documentTypes,
    isAuthenticated,
    getExistsUsersAccount,
    getUsersAccount,
    getAuthenticationResponse,
    getParmeterConfigAction,
    parameterConfig,
  } = props;
  const [loading, setLoading] = useState(false);
  const [infoUser, setInfoUser] = useState(null);
  const [showLoginCertificate, setShowLoginCertificate] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [typeDocumentPass, setTypeDocumentPass] = useState(0);
  
  const {
    control,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: ({
      typeDocument: "",
      numberDocument: "",
    }),
    shouldUnregister: false,
  });
  const classes = useStyles();

  //#region

  useEffect(() => {
    if (isAuthenticated) {
      //window.location.assign(isAuthenticated.urlAuth);
    }
    if (appName && appName !== "") {
      setLoading(true);
      getParmeterConfigAction(appName, 'ParameterApps');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(false);
    setShowLoginCertificate(false);
    getDocumentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!getAuthenticationResponse) {
      if (getAuthenticationResponse.responseCode === 200) {
        reset();
      }
      if (getAuthenticationResponse.code === "A10" || getAuthenticationResponse.code === "A20") {
        setShowLoginCertificate(true);
        setTimeout(() => {
          window.location.assign(getAuthenticationResponse.url);
        }, 2000);
      }
    }
  }, [getAuthenticationResponse, reset]);
  //#endregion

  //#region  custom methods

  const setLogin = async (data) => {
    let userData = {
      typeDocument: data.typeDocument,
      numberDocument: data.numberDocument,
    };
    if(parameterConfig && parameterConfig.captchaApply){
      if (isVerified) {
        setLoading(true);
        await getExistsUsersAccount(data);
        setInfoUser(userData);
        setShowLoginCertificate(true);
      } else {
        tryFailedByRecaptcha = 1;
      }
    } else {
      setLoading(true);
      await getExistsUsersAccount(data);
      setInfoUser(userData);
      setShowLoginCertificate(true);
    }
  };

  /**
   *  returns error box of auth response
   * @returns {JSX.Element}
   */

  const getErrorRecaptcha = () => {
    if (!isVerified && tryFailedByRecaptcha > 0) {
      tryFailedByRecaptcha = 0;
      return <ErrorBox
        value={"Por favor valida que no eres un robot"}></ErrorBox>
    }
  }

  const setTypeDocument = (event) => {
    setValue("numberDocument", "");
    if (event.target.value === "PA") {
      setTypeDocumentPass(1);
    } else {
      setTypeDocumentPass(0);
    }
  };

  const onCancelButton = () => {
    setShowLoginCertificate(false);
    setLoading(false);
    setValue("typeDocument", "");
    setValue("numberDocument", "");
    isVerified = false;
    window.location.reload();
  };

  //#endregion

  return (
    <>
      {!showLoginCertificate ? (
        <>
          <div className="login">
            <div className="card_login">
              <PageBackground></PageBackground>
              <CardBody>
                <form id="user-certificate" autoComplete="off">
                  <fieldset disabled={isVisible ? true : false}>
                    <hr></hr>
                    <br />
                    <Controller
                      control={control}
                      placeholder="Tipo de documento"
                      name="typeDocument"
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                      onChange={() => {
                        clearErrors("typeDocument");
                      }}
                      error={!!errors.typeDocument}
                      helperText={errors.typeDocument ? errors.typeDocument.message : null}
                      FormHelperTextProps={{ style: { marginLeft: 0 } }}
                      as={(
                        <TextField
                          select
                          size="small"
                          variant="outlined"
                          required
                          fullWidth
                          InputProps={{
                            classes,
                            startAdornment: (
                              <div style={{ height: "35.38px", backgroundColor: "#F5AE19", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }}>
                                <PersonIcon style={{ color: "#fff", marginTop: "4px", marginLeft: "5px", marginRight: "5px" }} />
                              </div>
                            ),
                          }}
                        >
                          {documentTypes && documentTypes.map(
                            (td) => (
                              <StyledMenuItem
                                key={td.id}
                                value={td.code}
                              >
                                {td.name}
                              </StyledMenuItem>
                            ),
                          )}
                        </TextField>
                      )}
                      inputProps={{ onChange: (e) => setTypeDocument(e) }}
                    />
                    <Controller
                      as={TextField}
                      style={{ marginTop: "5px", marginBottom: "5px" }}
                      name="numberDocument"
                      control={control}
                      placeholder="Número de documento"
                      fullWidth={true}
                      onChange={() => {
                        clearErrors("numberDocument");
                      }}
                      error={!!errors.numberDocument}
                      helperText={errors.numberDocument ? errors.numberDocument.message : null}
                      rules={typeDocumentPass === 0 ? Rules.numerico : Rules.letrasNumeros}
                      InputProps={{
                        classes,
                        startAdornment: (
                          <div style={{ height: "34.38px", backgroundColor: "#F5AE19", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", marginTop: "5px" }}>
                            <PersonIcon style={{ color: "#fff", marginTop: "4px", marginLeft: "5px", marginRight: "5px" }} />
                          </div>
                        ),
                      }}
                    />
                    {parameterConfig && !parameterConfig.captchaApply && (
                      <br></br>
                    )}
                    {parameterConfig && parameterConfig.captchaApply && (
                      <ReCAPTCHA className="recaptcha"
                        sitekey="6Lc675cpAAAAAA9QU8AecdiDpFLP1TD8Ht1hpmf1"
                        onChange={onChange}
                        size="normal"
                    />
                    )}
                    <br />
                    <PrimaryButtonUserCertificate
                      txtBtn={"Continuar"}
                      loading={loading}
                      onClick={handleSubmit(setLogin)}
                    />
                    {getErrorRecaptcha()}
                  </fieldset>
                </form>
              </CardBody>
            </div>
          </div>
        </>
      ) : (
        < LoginCertificate onCancelButton={onCancelButton} getUsersAccount={getUsersAccount} appName={appName} infoUser={infoUser} getAuthenticationResponse={getAuthenticationResponse} />
      )}
      <MaintenanceView appName={appName} setIsVisible={setIsVisible} isVisible={isVisible}></MaintenanceView>
    </>
  );
};

UserCertificate.propTypes = {
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
  getEnterprise: PropTypes.func.isRequired,
  enterpriseReducer: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
  enterpriseReducer: state.enterpriseReducer,
  isAuthenticated: state.authReducer.isAuthenticated,
  documentTypes: state.authReducer.documentTypes,
  getUsersAccount: state.authReducer.getUsersAccount,
  getAuthenticationResponse: state.authReducer.getAuthenticationResponse,
  parameterConfig: state.applicationsReducer.parameterConfig,
});
export default connect(mapStateToProps, {
  getEnterprise,
  getDocumentTypes,
  getExistsUsersAccount,
  getParmeterConfigAction,
})(UserCertificate);

const useStyles = makeStyles((theme) => ({
  input: {
    fontWeight: "500",
    color: "#B2B2B2",
    paddingLeft: 6,
    fontFamily: "muli, saint-serif",
    fontSize: "18px",
    "&:hover": {
      color: "#4F4F4F",
      fontWeight: "400",
      border: "none",
    },
    borderRadius: "0px 12px 12px 0px",
    marginTop: 4,
  },
  root: {
    paddingLeft: "0px",
    boxShadow: "0px 4px 3px #00000033",
    borderRadius: "12px",
    backgroundColor: "white",
    height: "36.38px",
    "& .MuiInputBase-root": {
      color: theme.palette.primary.light,
    },
    "& .MuiFormLabel-root": {
      color: "black",
      paddingLeft: "40px",
    },
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "& .MuiSelect-nativeInput": {
      left: "34px",
      width: "100%",
      bottom: "0",
      color: "transparent",
      opacity: 0.3,
      position: "absolute",
      border: "none",
      pointerEvents: "none",
      fontSize: "18px",
      marginBottom: "7px",
    },
    "& .MuiSelect-nativeInput::placeholder": {
      color: "#4F4F4F",
    },
  },
}));

const StyledMenuItem = withStyles({
  root: {
    "&.MuiListItem-button": {
      "&:hover": {
        backgroundColor: "#878787",
        color: "#FFFFFF",
      },
    },
    "&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected": {
      backgroundColor: "#878787",
      color: "#FFFFFF",
    },
  },
})(MenuItem);
