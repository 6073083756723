import React,{ useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { resetPassword, checkIsValidEmailAction } from "../../../actions/auth.action";

import PageBackground from "../../../components/pageBackground.component";
import CardBodyResetPassword from "../../../components/CardBody/cardBodyResetPassword.component";
import PrimaryButtonUserCertificate from "../../../components/primaryButtonUserCertificate.component";
import ValidationCodeCertificate from "./ValidationCodeCertificate";

import Typography from "@material-ui/core/Typography";
import { Grid, TextField, makeStyles, withStyles } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import PersonIcon from '@material-ui/icons/Person';

/**
 * Reset Password Component ( Reset password sending an e-mail
 * form and make a post request for user verification  )
 *
 * @returns Redux connect
 */

export const ResetPasswordCertificate = (props) => {

  const history = useHistory();
  const {checkIsValidEmailAction, validateUserEmail, resetPassword} = props;
  const { usersInAccounts } = props.authReducer.getUsersAccount;

  const validationSchema = Yup.object().shape({
    certificate: Yup.string().ensure().required("Campo obligatorio"),
  });

  const [isContinue, setIsContinue] = useState(false);
  const [usersAccount, setUsersAccount] = useState(usersInAccounts);
  const [showLogo, setShowLogo] = useState({
    passwordReset: false,
    resendEmail: false,
  });
  const [loading, setLoading] = useState(false);
  
  const {
    control,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: ({
      certificate: "",
    }),
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const classes = useStyles();

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (validateUserEmail) {
      if (validateUserEmail.statusCode === 200) {
        setIsContinue(true);
      } else {
        setIsContinue(false);
        setLoading(false);
      }
    }
  }, [validateUserEmail]);

  const onCancel = () => {
    history.push({
      pathname: '/Login'
    });
    window.location.reload();
    setValue("certificate", "");
  };
 
  const setResetPassword = async (data) => {
    let user = usersInAccounts.filter((item) => item.username === data.certificate)
    let isAssingPassword = user.map((item) => item.assignManualPassword);
    if(isAssingPassword[0]){
      let userData = {
        user: user[0].username,
        appname: props.appName,
      };
      resetPassword(userData, history)
    }else{
      setUsersAccount(user);
      setLoading(true);
      await checkIsValidEmailAction({ Email: user[0].email });
    }
  };

  const onCancelCode = () => {
    setLoading(false);
    setIsContinue(false);
  };

    return (
      <div className="login">
        <PageBackground></PageBackground>
        <CardBodyResetPassword showLogo={showLogo} >
          {!showLogo.passwordReset && !showLogo.resendEmail && (
            <hr></hr>
          )}
          <br />
          {
            !showLogo.passwordReset && !showLogo.resendEmail ? (
              <Typography style={{ textAlign: "center", color: '#E55200', fontWeight: 800, fontSize: 20 }}>
                Olvidé mi contraseña
              </Typography>
            ) : (
              showLogo.passwordReset && !showLogo.resendEmail ? (
                <Typography style={{ textAlign: "center", color: '#E55200', fontWeight: 800, fontSize: 20 }}>
                  ¡Contraseña restablecida!
                </Typography>
              ) : (
                <Typography style={{ textAlign: "center", color: '#E55200', fontWeight: 800, fontSize: 20 }}>
                  ¡Hagámoslo de nuevo!
                </Typography>
              )
            )
          }
          <br />
          {
            !isContinue ? (
              <form
              id="resetpassword-certificate"
              autoComplete="off"
              >
              <Controller
                control={control}
                placeholder="Selecciona Certificado/Usuario"
                name="certificate"
                style={{ marginTop: "5px", marginBottom: "5px" }}
                onChange={() => {
                  clearErrors("certificate");
                }}
                error={!!errors.certificate}
                helperText={errors.certificate ? errors.certificate.message : null}
                FormHelperTextProps={{ style: { marginLeft: 0 } }}
                as={(
                  <TextField
                    select
                    size="small"
                    variant="outlined"
                    required
                    fullWidth
                    InputProps={{
                      classes,
                      startAdornment: (
                        <div style={{ height: "35.38px", backgroundColor: "#F5AE19", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px" }}>
                          <PersonIcon style={{ color: "#fff", marginTop: "4px", marginLeft: "5px", marginRight: "5px" }} />
                        </div>
                      ),
                    }}
                  >
                    {usersInAccounts && usersInAccounts.map(
                      (td) => (
                        <StyledMenuItem
                          key={td.username}
                          value={td.username}
                        >
                          {td.username}
                        </StyledMenuItem>
                      ),
                    )}
                  </TextField>
                )}
              />
                <Grid container spacing={3} style={{ margin: "15px" }}>
                  <Grid container item xs={5}>
                    <PrimaryButtonUserCertificate
                      txtBtn={"Regresar"}
                      onClick={onCancel}
                    />
                  </Grid>
                  <Grid container item xs={5}>
                    <PrimaryButtonUserCertificate
                      txtBtn={"Continuar"}
                      nameComponent={"resetPasswordCertificate"}
                      loading={loading}
                      onClick={handleSubmit(setResetPassword)}
                    />
                  </Grid>
                </Grid>
              </form>
            ) : (
              <ValidationCodeCertificate onCancelCode={onCancelCode} formData={usersAccount} setShowLogo={setShowLogo} />
            )
          }
          <br />
          
        </CardBodyResetPassword>
      </div>
    );
}

const useStyles = makeStyles((theme) => ({
  input: {
    fontWeight: "500",
    color: "#B2B2B2",
    paddingLeft: 6,
    fontFamily: "muli, saint-serif",
    fontSize: "18px",
    "&:hover": {
      color: "#4F4F4F",
      fontWeight: "400",
      border: "none",
    },
    borderRadius: "0px 12px 12px 0px",
    marginTop: 4,
  },
  root: {
    paddingLeft: "0px",
    boxShadow: "0px 4px 3px #00000033",
    borderRadius: "12px",
    backgroundColor: "white",
    height: "36.38px",
    "& .MuiInputBase-root": {
      color: theme.palette.primary.light,
    },
    "& .MuiFormLabel-root": {
      color: "black",
      paddingLeft: "40px",
    },
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
    "& .MuiSelect-nativeInput": {
      left: "34px",
      width: "100%",
      bottom: "0",
      color: "transparent",
      opacity: 0.3,
      position: "absolute",
      border: "none",
      pointerEvents: "none",
      fontSize: "18px",
      marginBottom: "7px",
    },
    "& .MuiSelect-nativeInput::placeholder": {
      color: "#4F4F4F",
    },
  },
}));

const StyledMenuItem = withStyles({
  root: {
    "&.MuiListItem-button":{
      "&:hover":{
        backgroundColor: "#878787",
        color: "#FFFFFF",
      },
    },
  },
})(MenuItem);

ResetPasswordCertificate.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  checkIsValidEmailAction: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
  validateUserEmail: state.authReducer.validateUserEmail,
});

export default connect(mapStateToProps, { resetPassword, checkIsValidEmailAction })(ResetPasswordCertificate);
