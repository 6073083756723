import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import { messagesResponseRoles } from "../utils/constants";
import { GET_ROLE, GET_ROLES, GET_ERRORS, SET_ROLE_ERROR, GET_PRIVILEGES, DEFAULTRESPONSE, GET_ROLE_CHANGED, GET_APPLICATIONS } from "./types";

export /**
 * Roles Action getRoles
 *
 * @method GET
 * @param {*}
 */
const getRoles = () => async (dispatch) => {
    try {
        const response = await axios.get(API_SECURITY_ENDPOINT + "/api/role/list", defaultHeaders());
        dispatch({
            type: GET_ROLES,
            payload: response.data.result.records,
        });
    } catch (error) {
        console.error(error);

        const { response } = error;
        dispatch({
            type: GET_ERRORS,
            payload: !!response ? response : DEFAULTRESPONSE.noResponseFromApi,
        });
    }
};

export /**
 * Role Action getRoleById
 *
 * @method GET
 * @param {*} roleId
 */
const getRoleById = (roleId) => async (dispatch) => {
    if (roleId === "") {
        dispatch({
            type: GET_ROLE,
            payload: [],
        });
    } else {
        try {
            let myConfig = defaultHeaders();
            Object.assign(myConfig.headers, {
                id: roleId,
            });
            const response = await axios.get(API_SECURITY_ENDPOINT + "/api/role/list", myConfig);
            if (response.data.result.count === 0) {
                return;
            }
            dispatch({
                type: GET_ROLE,
                payload: response.data.result.records[0],
            });
        } catch (error) {
            const { response } = error;
            console.error(error);
            dispatch({
                type: GET_ERRORS,
                payload: !!response ? response : DEFAULTRESPONSE.noResponseFromApi,
            });
        }
    }
};

export /**
 * Roles Action getApplication
 *
 * @method GET
 * @param {*} roleId
 */
const getApplicationById = (roleId) => async (dispatch) => {
    try {
        let myConfig = defaultHeaders();
        Object.assign(myConfig.headers, {
            id: roleId,
        });
        const response = await axios.get(API_SECURITY_ENDPOINT + "/api/application/applicationId", myConfig);
        if (response.data.result.count === 0) {
            return;
        }
        dispatch({
            type: GET_APPLICATIONS,
            payload: response.data.result.records,
        });
    } catch (error) {
        const { response } = error;
        console.error(error);
        dispatch({
            type: GET_ERRORS,
            payload: !!response ? response : DEFAULTRESPONSE.noResponseFromApi,
        });
    }
};

export /**
 * Roles Action getPrivilegeByApp
 *
 * @method GET
 * @param {*} appId
 */
const getPrivilegeByApp = (appId, role) => async (dispatch) => {
    try {
        let myConfig = defaultHeaders();
        Object.assign(myConfig.headers, {
            application: appId,
            rol: role,
        });
        const options = {
            method: "GET",
            url: API_SECURITY_ENDPOINT + "/api/privilege/list",
            ...myConfig,
            transformResponse: [
                (data) => {
                    if (data !== "") {
                        const jsonData = JSON.parse(data);
                        if (jsonData.statusCode === 200) {
                            const { records } = jsonData.result;
                            const newRecords = records.filter((item) => item.idstate === 0);
                            return {
                                ...jsonData,
                                result: {
                                    ...jsonData.result,
                                    records: newRecords,
                                },
                            };
                        } else {
                            return jsonData;
                        }
                    }
                    return data;
                },
            ],
        };
        const response = await axios(options);
        // if (response.data.result.count === 0) {
        //     return;
        // }
        dispatch({
            type: GET_PRIVILEGES,
            payload: response.data.result.records ,
        });
        // return { data: response.data.result.records, name: appId };
    } catch (error) {
        const { response } = error;
        if (response.data.result.informationCode === "R11") {
            dispatch({
                type: GET_PRIVILEGES,
                payload: [],
            });
        } else {
            console.error(error);
            dispatch({
                type: GET_ERRORS,
                payload: !!response ? response : DEFAULTRESPONSE.noResponseFromApi,
            });
        }
    }
};

export /**
 * Role Action create ( create )
 *
 * @method POST
 * @param {history} history
 */
const createRole = (roleData, updateChecked) => async (dispatch) => {
    try {
        var response = await axios.post(API_SECURITY_ENDPOINT + "/api/role/create", roleData, defaultHeaders());
        if (response.status === 200) {
            updateChecked();
            dispatch({
                type: GET_ROLE_CHANGED,
                payload: messagesResponseRoles.createRole,
            });
        } else {
            dispatch({
                type: SET_ROLE_ERROR,
                payload: response.data,
            });
        }
    } catch (err) {
        console.error(err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: SET_ROLE_ERROR,
            payload: response,
        });
    }
};

export /**
 * Role Action modify ( modify )
 *
 * @method POST
 * @param {history} history
 */
const modifyRole = (roleData, updateChecked, isActivating, tab) => async (dispatch) => {
    try {
        var response = await axios.post(API_SECURITY_ENDPOINT + "/api/role/modify", roleData, defaultHeaders());
        if (response.status === 200) {
            dispatch({
                type: GET_ROLE_CHANGED,
                payload: isActivating
                    ? `El Rol quedó en estado ${!roleData.idstate === true ? "activo" : "inactivo"}`
                    : tab === 0
                    ? messagesResponseRoles.updateRoleData
                    : messagesResponseRoles.updateRoleApps,
            });
            updateChecked();
        } else {
            dispatch({
                type: SET_ROLE_ERROR,
                payload: response,
            });
        }
    } catch (err) {
        console.error(err);
        const response = !!err.response ? err.response.data : DEFAULTRESPONSE.noResponseFromApi;
        dispatch({
            type: SET_ROLE_ERROR,
            payload: response,
        });
    }
};
