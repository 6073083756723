import { makeStyles } from "@material-ui/core";

export const useStylesNotifications = makeStyles((theme) => ({
    paperTable: {
        width: "100%",
        border: `2px solid ${theme.palette.primary.light}`
    },
    paper: {
        width: "100%",
        padding: "10px 15px",
    },
    buttonSave: {
        margin: "15px 3px 20px 3px",
        flex: 1,
        float: "right",
        background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
        boxShadow: "0px 4px 3px #0000004D",
        borderRadius: 10,
    },
    GridAdornmentIcon: {
        height: "24px",
    },
    titleLight:{
      paddingTop: "10px", 
      color: theme.palette.primary.light, 
      fontSize: "16px" 
    },
    titleMain: {
      color: theme.palette.primary.main, 
      fontSize: "16px"
    },
    textInfoRange: {
      color: theme.palette.secondary.main, 
      fontSize: "11px", 
      marginTop: "20px", 
      marginBottom: "3px"
    }
}));