import { Grid, IconButton, makeStyles } from "@material-ui/core";
import React, {
  forwardRef,
} from "react";

export const GridDrag = forwardRef((props, ref) => {
  const { values, dragStart, dragOver, drop, itemAction } = props;
  const numberRows = 3;
  const classes = useStyles();
  const maxWidth = 130 * numberRows;

  return (values && values.length > 0) ? (
    <Grid
      id="gridContainerSuite"
      style={{
        height: `${maxWidth}px`,
      }}
      className={classes.styleContainer}
    >
      <span>
       {values.map((app, index) => {
          return (
            <span
              className={classes.styleContainerImage}
              onMouseOver={() => {
                const p = document.getElementById(
                  `buttons-${app.name}-${index}`
                );
                const img = document.getElementById(`images-${app.key}`);
                if (!!p && !!img) {
                  p.style.display = "block";
                }
              }}
              onMouseLeave={() => {
                const p = document.getElementById(
                  `buttons-${app.name}-${index}`
                );
                const img = document.getElementById(`images-${app.key}`);
                if (!!p && !!img) {
                  p.style.display = "none";
                  img.style.border = "1px solid #C1C1C1";
                  if (app.isActivate) {
                    img.style.opacity = 1;
                  }
                }
              }}
            >
              <div style={{ width: "90px", position: "relative" }}>
                <img
                  className={[classes.styleImages]}
                  id={`images-${app.key}`}
                  alt={app.name}
                  pos={`${index}`}
                  src={app.iconName}
                  style={{ opacity: `${app.isActivate ? "1.0" : "0.5"}` }}
                  title={app.name}
                  onDragStart={dragStart}
                  onDragOver={dragOver}
                  onDrop={drop}
                />
              </div>
              <div
                id={`buttons-${app.name}-${index}`}
                className={classes.containerButtonActions}
              >
                <IconButton
                  onClick={() => itemAction(app.key, "Edit", app)}
                  className={classes.iconButtonStyle}
                >
                  <span className={`material-icons ${classes.styleIconButton}`}>
                    edit
                  </span>
                </IconButton>
                <IconButton
                  onClick={() => itemAction(app.key, "Delete", app)}
                  className={classes.iconButtonStyle}
                >
                  <span className={`material-icons ${classes.styleIconButton}`}>
                    delete
                  </span>
                </IconButton>
              </div>
            </span>
          );
        })}
      </span>
    </Grid>
  ) : (
    <span>No hay datos para mostrar</span>
  );
});

const useStyles = makeStyles((theme) => ({
  styleImages: {
    border: "1px solid #C1C1C1",
    borderRadius: "12px",
    padding: "11px",
    margin: "5px",
    width: "96px",
    height: "96px",
    objectFit: "contain",
    display: "block",
    backgroundColor: '#EFEFEF',
  },
  styleContainer: {
    borderRadius: "10px",
    padding: "10px",
    overflowY: "scroll",
    width: "387px",
  },
  styleContainerImage: {
    position: "relative",
    display: "inline-block",
    margin: "15px",
  },
  styleIconButton: {
    fontSize: "15px",
    color: "#009BDD",
  },
  containerButtonActions: {
    position: "absolute",
    right: "0",
    top: "0",
    marginRight: "-7px",
    marginTop: "6px",
    display: "none",
  },
  iconButtonStyle: {
    width: "20px",
    height: "20px",
  },
  plusIcon: {
    position: "relative",
    height: "34px !important",
    width: "34px !important",
    stroke: theme.palette.secondary.main,
    margin: "42px",
  },
}));