import axios from "axios";
import { API_SECURITY_ENDPOINT, defaultHeaders } from "../config/config";
import { CHANGE_NOTIFICATION, DEFAULTRESPONSE, GET_ALL_NOTIFICATIONS, GET_ALL_TEMPLATES, GET_ERROR_NOTIFICATION } from "./types";


export const createUpdateNotification = (data, action) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_SECURITY_ENDPOINT}/api/Notifications/${action}`,data, defaultHeaders() );
    if (response.status === 200) {
      dispatch({
        type: CHANGE_NOTIFICATION,
        payload: response.data.result
      })
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: GET_ERROR_NOTIFICATION,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
}

export const DeleteNotification = (data) => async (dispatch) => {
  try {
    const response = await axios.post(`${API_SECURITY_ENDPOINT}/api/Notifications/Delete`,data, defaultHeaders() );
    if (response.status === 200) {
      dispatch({
        type: CHANGE_NOTIFICATION,
        payload: response.data.result
      })
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: GET_ERROR_NOTIFICATION,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
}

export const getTemplatesByApp = (apps) => async (dispatch) => {
  try {
    let myConfig = defaultHeaders();
    Object.assign(myConfig.headers, {
      apps,
    });
    const response = await axios.get(`${API_SECURITY_ENDPOINT}/api/ApplicationsTemplates/TemplatesByApps`, myConfig);
    if (response.status === 200) {

      const templates = response.data.result;
      dispatch({
        type: GET_ALL_TEMPLATES,
        payload: templates
      })
    }
  } catch (error) {
    const { response } = error;
    console.error(error);
    dispatch({
      type: GET_ERROR_NOTIFICATION,
      payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    });
  }
}

export const getListNotifications = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_SECURITY_ENDPOINT}/api/Notifications/List`, defaultHeaders());
    if (response.status === 200) {

      const notifications = response.data.result;
      dispatch({
        type: GET_ALL_NOTIFICATIONS,
        payload: {loading: true, notifications: notifications}
      })
    }
  } catch (error) {
    // const { response } = error;
    console.error(error);
    // dispatch({
    //   type: GET_ERROR_NOTIFICATION,
    //   payload: !!response ? response.data : DEFAULTRESPONSE.noResponseFromApi,
    // });
  }
}