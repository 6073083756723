import { FormControlLabel, Typography, useMediaQuery } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import ControlledInputRoundedForm from '../InputForm/ControlledInputRoundedForm';
import { makeStyles } from "@material-ui/core";
import { ControlledCheckComponent } from '../CheckComponents/ControlledCheckComponent';

const useStyle = makeStyles((theme) => ({
  iconButton: {
    padding: "0px",
    color: theme.palette.primary.light,
  },
  iconColor: {
    color: theme.palette.primary.light
  }
}));

export const TimeComponent = (props) => {

  const {
    control,
    errors,
    setValue,
    getValues,
    handleOnChangeTime,
    GlobalDisabled,
    handleChangeTime,
    propsGlobal,
  } = props;

  const classes = useStyle();
  const matches = useMediaQuery('(max-width:1796px)');
  const idHours = propsGlobal === undefined ? "hours" : propsGlobal.hour.id;
  const nameHours = propsGlobal === undefined ? "hours" : propsGlobal.hour.name;

  const idMinutes = propsGlobal === undefined ? "minutes" : propsGlobal.minutes.id;
  const nameMinutes = propsGlobal === undefined ? "minutes" : propsGlobal.minutes.name;


  const onChangeControl = (e) => {
    let value = parseInt(e.target.value) || 0;
    const name = e.target.name;
    if (name === nameHours) {
      if (value < 0) {
        value = 0;
      }

      if (value > 23) {
        value = 23;
      }
    } else {
      // Son minutos
      if (value < 0) {
        value = 0;
      }

      if (value > 59) {
        value = 59;
      }
    }

    setValue(name, value)
  }

  const ChangeTimeControl = (action, name) => {
    let value = parseInt(getValues()[name]);
    if (action === "add") {
      if (name === nameHours) {
        if (value >= 23) {
          value = 23;
        } else {
          value++;
        }
      } else {
        if (value >= 59) {
          value = 59;
        } else {
          value++;
        }
      }
    } else {
      if (value <= 0) {
        value = 0;
      } else {
        value--;
      }
    }
    setValue(name, value);
  }

  return (
    <Grid container spacing={1} lg={12} md={8} style={{ alignItems: "center" }}>
      {propsGlobal !== undefined &&
        <Grid item lg={12} md={12}>
          <Typography color="inherit" style={{ fontSize: "14px", marginTop: "5px" }}>
            {propsGlobal.labelGlobal}
          </Typography>
        </Grid>}
      <Grid item style={{ width: "100px" }}>
        <div>
          <Typography color="inherit" style={{ fontSize: "14px" }}>
            Hora
          </Typography>
        </div>
        <div>
          <Grid item style={{ padding: "0px 5px 0px 0px", width: "100px" }}>
            <ControlledInputRoundedForm
              id={idHours}
              name={nameHours}
              inputProps={{
                maxLength: propsGlobal === undefined ? 50 : propsGlobal.hour.maxLength,
                style: { paddingTop: "10px" }
              }}
              style={{ marginTop: "0px" }}
              pattern={/[^0-9]/g}
              disabled={GlobalDisabled}
              fullWidth
              control={control}
              error={errors[nameHours]}
              helperText={errors[nameHours]?.message}
              onChange={(e) => handleOnChangeTime === undefined ? onChangeControl(e) : handleOnChangeTime(e)}
              adornment={{
                startAdornment: (
                  <IconButton
                    disabled={GlobalDisabled}
                    className={classes.iconButton}
                    aria-label="Button Up"
                    style={{ opacity: GlobalDisabled ? 0.5 : 1 }}
                    onClick={() => handleChangeTime === undefined ? ChangeTimeControl("remove", nameHours) : handleChangeTime("remove", "hours")} >
                    <RemoveIcon className={classes.iconColor} />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    disabled={GlobalDisabled}
                    className={classes.iconButton}
                    aria-label="Button Up"
                    style={{ opacity: GlobalDisabled ? 0.5 : 1 }}
                    onClick={() => handleChangeTime === undefined ? ChangeTimeControl("add", nameHours) : handleChangeTime("add", "hours")} >
                    <AddIcon className={classes.iconColor} />
                  </IconButton>
                )
              }}
            />
          </Grid>
        </div>
      </Grid>
      <Grid item style={{ width: "100px" }}>
        <div>
          <Typography color="inherit" style={{ fontSize: "14px" }}>
            Minutos
          </Typography>
        </div>
        <div>
          <Grid item style={{ padding: "0px 5px 0px 5px", width: "105px" }}>
            <ControlledInputRoundedForm
              id={idMinutes}
              name={nameMinutes}
              inputProps={{
                maxLength: propsGlobal === undefined ? 50 : propsGlobal.minutes.maxLength,
                style: { paddingTop: "10px" }
              }}
              pattern={/[^0-9]/g}
              disabled={GlobalDisabled}
              fullWidth
              control={control}
              error={errors.name}
              helperText={errors.name?.message}
              onChange={(e) => handleOnChangeTime === undefined ? onChangeControl(e) : handleOnChangeTime(e)}
              adornment={{
                startAdornment: (
                  <IconButton
                    className={classes.iconButton}
                    disabled={GlobalDisabled}
                    aria-label="Button Up"
                    style={{ opacity: GlobalDisabled ? 0.5 : 1 }}
                    onClick={() => handleChangeTime === undefined ? ChangeTimeControl("remove", nameMinutes) : handleChangeTime("remove", "minutes")} >
                    <RemoveIcon className={classes.iconColor} />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    className={classes.iconButton}
                    disabled={GlobalDisabled}
                    aria-label="Button Up"
                    style={{ opacity: GlobalDisabled ? 0.5 : 1 }}
                    onClick={() => handleChangeTime === undefined ? ChangeTimeControl("add", nameMinutes) : handleChangeTime("add", "minutes")} >
                    <AddIcon className={classes.iconColor} />
                  </IconButton>
                )
              }}
              style={{ marginTop: "0px" }}
            />
          </Grid>
        </div>
      </Grid>
      {
        (propsGlobal !== undefined && propsGlobal.complement !== undefined) && <Grid item style={{ width: "170px", textAlign: "center" }}>
          <div style={{ width: "170px", height: matches ? "0px" : "21px",}}>
            <Typography color="inherit" style={{ fontSize: "14px" }}>
            </Typography>
          </div>
          <Grid>
            <FormControlLabel
              disabled={GlobalDisabled}
              control={<ControlledCheckComponent
                id={propsGlobal.complement.id}
                name={propsGlobal.complement.name}
                isDisabled={GlobalDisabled}
                control={control}
              />}
              label={
                <Typography color="inherit" style={{ fontSize: "14px" }}>
                  {propsGlobal.complement.label}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      }
    </Grid>
  )
}
