import { Button, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.common.white,
        borderRadius: 3,
        border: "1px solid #A8A8A8",
        height: 37,
        padding: '0 30px',
        color: `${theme.palette.text.primary} !important`,
        opacity: "1",
        '&&:hover':{
            background: theme.palette.secondary.main,
            color: `${theme.palette.text.secondary} !important`,
            border: 'none',
            boxShadow: 'none'     
        }
    },
    label: {
        textTransform: 'capitalize',
    },
}));



const IconEspecialButton = (props) => {
    const classes = useStyles();
    return (
        <Button
            className={classes.root}
            {...props}
        />
    )
}

export default IconEspecialButton
