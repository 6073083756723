import React from 'react'

const OrderIcon = ({ style = {}, active,
    width = "100%", className = "",
    viewBox = "0 0 30 12" }) => {
    const fill = active ? "#FFFFFF" : "#6d6e71";
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width={width}
            height={width}
            style={style}
            viewBox={viewBox}
            className={`svg-icon ${className || ""}`}>
            <g id="Grupo_3020" data-name="Grupo 3020" transform="translate(-596.676 -157)">
                <rect id="Rectángulo_1256" data-name="Rectángulo 1256" width="12" height="2" transform="translate(596.676 157)" fill={fill} />
                <rect id="Rectángulo_1258" data-name="Rectángulo 1258" width="9" height="2" transform="translate(596.676 161)" fill={fill} />
                <rect id="Rectángulo_1259" data-name="Rectángulo 1259" width="6" height="2" transform="translate(596.676 165)" fill={fill} />
            </g>
            <path id="Trazado_775" data-name="Trazado 775" d="M464.114,575l2.149,4.355,4.806.7-3.477,3.39.821,4.786-4.3-2.26-4.3,2.26.821-4.786-3.478-3.39,4.806-.7Z" transform="translate(-3.223 1.389)"  fill={fill} />

        </svg>
    )
}

export default OrderIcon
