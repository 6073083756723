/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const CustomInputBeMyself = (props) => {
  const {
    label, name, type, className, classes, disabled, required, multiline, rows, control, rule, error, defaultValue,
    inputProps, id, onValidate, styleTextField, styleFromHelperText, InputPropsParam, onKeyDown, autoFocus,
  } = props;


  return (
    <FormControl style={className} fullWidth>
      <Controller
        render={({ value, onChange }) => (
          <TextField
            label={label}
            id={id}
            size="small"
            type={type}
            fullWidth
            variant="outlined"
            multiline={multiline}
            rows={rows}
            value={value}
            style={styleTextField}
            error={Boolean(error ? error[name] : false)}
            disabled={disabled}
            required={required}
            autoFocus={autoFocus}
            onChange={((e) => {
              onChange(e);
              onValidate && (onValidate(e));
            })}
            inputProps={inputProps}
            InputProps={InputPropsParam}
            FormHelperTextProps={styleFromHelperText || { style: { margin: '0 0 0 5px', color: '#d61103' } }}
            helperText={error ? (error[name] ? error[name].message : null) : null}
            onKeyDown={onKeyDown}
            className={classes}
          />
        )}
        name={name}
        control={control}
        rules={rule}
        error={Boolean(error ? error[name] : false)}
        defaultValue={defaultValue || ''}
      />
    </FormControl>
  );
};

export default CustomInputBeMyself;
