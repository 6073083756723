
import {
    GET_ENTERPRISE
  } from "../actions/types";

//constantes
const initialState = {
    getEnterpriseResponse : []
}

export default function enterpriseReducer (state = initialState, action){
    switch(action.type){
        case GET_ENTERPRISE:
            return {
                ...state,
                getEnterpriseResponse: action.payload
            }
        default:
            return state

    }
}