import { Button, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    styleButton: {
        '&:disabled': {
            background: theme.palette.primary.main,
            opacity: 0.4
        },
    },
    label: {
        fontWeight: 'bold'
    }
}));

export const ButtonSaveChanges = ({ type, title, isDisabled, handleClick, id, style, margin }) => {

    const styles = useStyles();

    return (
        <div>
            <Button
                type={type}
                id={id}
                classes={{ label: styles.label }}
                className={`${style} ${styles.styleButton}`}
                margin={margin}
                variant='contained'
                color='primary'
                disabled={isDisabled}
                onClick={handleClick}>
                {title}
            </Button>
        </div>
    )
}

export default ButtonSaveChanges
