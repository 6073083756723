import React, { useEffect, useState } from 'react';
import LayouthInfoUko from '../../../components/AuthenticationLayoutUko/LayouthInfoUko';
import { CHECKEMAIL, ERRORINFO, PASSWORDUPDATED, SENDEMAIL } from '../../../actions/auth.action';
import ShowAlertUko from "../../../components/alertUko.component";
import { useTheme } from '@material-ui/core';
import SuccessAlertUko from './SuccessAlertUko';
import { enmaskEmail } from '../../../utils/utils';

const InfoUko = (props) => {

    const {
        appName,
        history
    } = props;

    const CHECKEMAILAGAIN = 'CHECK_EMAIL_AGAIN';
    const INVALIDLINK = 'INVALID_LINK';

    const theme = useTheme();
    const [messageTypeFromHistory, setmessageTypeFromHistory] = useState(history.location.state?.messageType);
    const [messageCheckEmail, setmessageCheckEmail] = useState("");
    const [countdown, setCountdown] = useState(-1);

    const cutMessage = (oldMessage) => {
        const indexOfA = oldMessage.indexOf("a ");
        const indexOfComa = oldMessage.indexOf(", ");
        const emailExtract = oldMessage.substring(indexOfA + 1, indexOfComa);

        return <div> <center>Te hemos enviado un email a <b>{emailExtract}</b>, ve allí para <br />
            continuar con el proceso. No olvides revisar el spam. </center> </div>;
    }

    const handleClick = () => {
        if (history) {
            if (history.location.state.pagename && history.location.state.pagename !== "") {
                window.location.replace(history.location.state.pagename);
            }
        }
    }

    const onClickLinkEmail = () => {
        setmessageTypeFromHistory(CHECKEMAILAGAIN);
        setCountdown(60);
    }

    /**
     * Extract new message with current email from history
     * Remember: history is updated eith backend services
     */
    useEffect(() => {
        const userEmail = history.location.state?.emailFromUser;
        switch (messageTypeFromHistory) {
            case INVALIDLINK:
                setmessageCheckEmail(<div> <center>El tiempo para activar tu cuenta ha finalizado,
                    hemos enviado a tu <br /> email un nuevo vínculo de activación. </center> </div>);
                break;
            case CHECKEMAILAGAIN:
                setmessageCheckEmail(<div> <center>Te hemos reenviado un email a <b>{enmaskEmail(userEmail)}</b><br />
                    No olvides revisar el spam. </center> </div>);
                break;
            default:
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageTypeFromHistory])


    useEffect(() => {
        if (countdown > 0) {
            setTimeout(
                () => setCountdown(countdown - 1),
                1000
            );
        }
    }, [countdown])

    const renderInfo = (messageType) => {
        switch (messageType) {
            case CHECKEMAIL:
                return (
                    <LayouthInfoUko
                        appName={appName}
                        firstImage={"urlSignIn"}
                        title={"¡Revisa tu email!"}
                        secondImage={"urlCheckEmailMoreBlue"}
                        widthOfSecondImage={"306px"}
                        textBeforeEnd={cutMessage(history.location.state.message)}
                    >
                    </LayouthInfoUko>
                );
            case SENDEMAIL:
                return (
                    <LayouthInfoUko
                        appName={appName}
                        firstImage={"urlSignIn"}
                        title={"¡Este enlace ya no es válido!"}
                        secondImage={"urlExpireTime"}
                        infoText={"Para restablecer tu contraseña ingresa de nuevo tu email"}
                        hasInput={true}
                    ></LayouthInfoUko>
                );
            case ERRORINFO:
                return <ShowAlertUko
                    logo={theme.urlLogo.urlSignIn}
                    image={theme.alertUko.imageUko}
                    value={history.location.state?.message}
                    onClick={handleClick}
                    appName={appName}
                />;
            case PASSWORDUPDATED:
                return <SuccessAlertUko />;
            case INVALIDLINK:
                return <LayouthInfoUko
                    appName={appName}
                    firstImage={"urlSignIn"}
                    title={"¡Tiempo de activación finalizado!"}
                    secondImage={"urlExpireTime"}
                    textBeforeEnd={messageCheckEmail}
                    onClickLink={onClickLinkEmail}
                    textInLink={'¿No has recibido el email?'}
                />;
            case CHECKEMAILAGAIN:
                return <LayouthInfoUko
                    appName={appName}
                    firstImage={"urlSignIn"}
                    title={"¡Revisa de nuevo tu email!"}
                    secondImage={"urlCheckEmailAgain"}
                    textBeforeEnd={messageCheckEmail}
                    textInTheEnd={countdown > 0 ? <div>Intentalo de nuevo en <b>00:{countdown}</b></div> : ''}
                    onClickLink={onClickLinkEmail}
                    textInLink={ countdown === 0 ? '¿No has recibido el email?' : ''}
                    countdown={countdown}
                    setCountdown={setCountdown}
                />;
            default:
                return (
                    <LayouthInfoUko
                        appName={appName}
                        firstImage={"urlSignIn"}
                        title={"¡Este enlace ya no es válido!"}
                        secondImage={"urlInvalidLinkMoreBlue"}
                        infoText={<div><center>Para restablecer tu contraseña ingresa de nuevo tu email</center></div>}
                        hasInput={true}
                    ></LayouthInfoUko>
                );
        }
    }

    return (
        <div>
            {renderInfo(messageTypeFromHistory)}
        </div>
    );
}

export default InfoUko;
