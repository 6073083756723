import {
  Card,
  CssBaseline,
  Grid,
  Link,
  makeStyles,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FullLoader from "../../../components/Loader/FullLoader.component";
import PageBackground from "../../../components/pageBackground.component";
import { SendUserActivationEmail } from "../../../actions/auth.action";
import AppBar from "@material-ui/core/AppBar";
import { ClockIcon } from "../../../components/IconsLibrary/ClockIcon";
import { EmailIcon } from "../../../components/IconsLibrary/EmailIcon";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "600px",
    maxWidth: "600px",
    // height: '350px',
    maxHeight: "500px",
    padding: "70px",
    borderRadius: "10px",
    backgroundColor: "#F7F7F7",
  },
  title: {
    fontSize: "18px",
  },
  title2: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  textEmailResend: {
    fontSize: "16px",
    textDecorationLine: "underline",
    cursor: "pointer",
    fontWeight: 600,
  },
  textTimeSecond: {
    fontSize: "50px",
  },
  textSecond: {
    fontSize: "20px",
  },
  cardApp: {
    width: "92px",
    height: "92px",
    cursor: "pointer",
    borderRadius: "10px",
    shadows: "0px 3px 6px #00000029",
    marginBottom: "10px",
    // marginLeft: '5px',
    // marginRight: '5px',
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid #009BDD",
    },
  },
  image: {
    width: "80px",
    height: "80px",
    padding: "5px",
    objectFit: "contain",
  },
  root2: {
    display: "flex",
    justifyContent: "center",
  },
  toolbar: theme.mixins.toolbar,
  colorText: {
    color: theme.palette.primary.dark,
  },
  content: {
    flexGrow: 1,
    padding: "0px",
    justifyContent: "flex-start",
    overFlowX: "hidden",
  },
  imageApp: {
    width: "163px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "43px",
    backgroundImage: theme.logo.backgroundImage,
    paddingTop: "14px",
    flex: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#F1F1F1 0% 0% no-repeat padding-box",
  },
}));

/**
 * Resend email view
 *
 * @export Class Component
 * @class ResendEmailView
 * @extends {Component}
 * @returns Redux connect
 */

const ResendEmailView = (props) => {

  const {history} = props;

  const classes = useStyles();
  const [isLoading] = useState(false);
  const [stateMessage, setStateMessage] = useState(1);
  const [blocked, setBlocked] = useState({
    timeStatus: "",
    timeBlocked: "",
  });
  const [userData] = useState(
    !!history.location.state ? history.location.state.userData : null
  );
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    if (!!userData) {
      dispatch(
        SendUserActivationEmail({
          userName: userData.username,
          appName: userData.appname,
        })
      );
    } else {
      history.push({
        pathname: "/Alert",
        state: {
          message: "No hay información del usuario.",
          pagename: "/Login",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(
      SendUserActivationEmail({
        userName: userData.username,
        appName: userData.appname,
      })
    );
    if (stateMessage === 1 || stateMessage === 3) {
      setStateMessage(2);
      ClockCountsDown(60);
    }
  };

  const getRemainingTime = (deadline) => {
    let now = new Date(),
      remainTime = (new Date(deadline) - now + 1000) / 1000,
      remainSeconds = ("0" + Math.floor(remainTime % 60)).slice(-2),
      remainMinutes = ("0" + Math.floor((remainTime / 60) % 60)).slice(-2),
      remainHours = ("0" + Math.floor((remainTime / 3600) % 24)).slice(-2);
    return {
      remainSeconds,
      remainMinutes,
      remainHours,
      remainTime,
    };
  };

  const ClockCountsDown = (finaMinute) => {
    let FechaHora = "";
    let fechaActual = new Date();
    let faltanMinutos = finaMinute * 1000;
    let fechaFuturo = fechaActual.getTime() + faltanMinutos;
    if (blocked.timeStatus !== true) {
      let timerUpdate = setInterval(() => {
        let currentTime = getRemainingTime(new Date(fechaFuturo));
        if (currentTime.remainMinutes !== "00") {
          FechaHora =
            currentTime.remainMinutes + ":" + currentTime.remainSeconds;
        } else {
          FechaHora = currentTime.remainSeconds;
        }

        setBlocked({
          timeBlocked: FechaHora + "",
          timeStatus: true,
        });
        if (currentTime.remainTime <= 1) {
          clearInterval(timerUpdate);
          setBlocked({
            timeBlocked: "",
            timeStatus: false,
          });
          setStateMessage(3);
        }
      }, 1000);
    }
  };

  return (
    <div className={classes.root2}>
      <CssBaseline />
      <AppBar position="fixed" className={`${classes.appBar} 'App-Bar'`}>
        <Toolbar>
          <div>
            <div className={classes.imageApp}></div>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className="login">
          <PageBackground></PageBackground>
          {
            <Card className={classes.root}>
              {isLoading ? (
                <FullLoader open={isLoading} handleClose={() => {}} />
              ) : null}
              {stateMessage === 3 && (
                <>
                  <Grid container lg={12} justifyContent="center">
                    <Typography className={[classes.title2, classes.colorText]}>
                      ¡Listo!
                    </Typography>
                  </Grid>
                  <br></br>
                </>
              )}
              {stateMessage === 1 && (
                <>
                  <Grid container lg={12} justifyContent="center">
                    <ClockIcon color={theme.palette.primary.iconSend} />
                  </Grid>
                  <br></br>
                  <br></br>
                </>
              )}
              {(stateMessage === 2 || stateMessage === 3) && (
                <>
                  <Grid container lg={12} justifyContent="center">
                    <EmailIcon color={theme.palette.primary.iconSend} />
                  </Grid>
                  <br></br>
                  <br></br>
                </>
              )}
              {stateMessage === 1 && (
                <Grid container lg={12} justifyContent="center">
                  <Typography className={classes.title}>
                    El tiempo máximo para activar tu cuenta
                  </Typography>
                  <Typography className={classes.title}>
                    ha finalizado, hemos enviado a tu email
                  </Typography>
                  <Typography className={classes.title}>
                    un nuevo vínculo de activación.
                  </Typography>
                </Grid>
              )}
              {stateMessage === 2 && (
                <Grid container lg={12} justifyContent="center">
                  <Typography className={classes.title}>
                    Te hemos enviado un nuevo email.
                  </Typography>
                  <Typography className={classes.title}>
                    Si no lo has recibido, inténtalo de nuevo en:
                  </Typography>
                </Grid>
              )}
              {stateMessage === 3 && (
                <Grid container lg={12} justifyContent="center">
                  <Typography className={classes.title}>
                    El email ha sido enviado nuevamente
                  </Typography>
                </Grid>
              )}

              <br></br>
              <br></br>
              {(stateMessage === 1 || stateMessage === 3) && (
                <Grid container lg={12} justifyContent="center">
                  <Link onClick={handleClick}>
                    <Typography
                      className={[classes.textEmailResend, classes.colorText]}
                    >
                      ¿No has recibido el email?
                    </Typography>
                  </Link>
                </Grid>
              )}
              {stateMessage === 2 && (
                <Grid container lg={12} justifyContent="center">
                  <Grid container item lg={12} justifyContent="center">
                    <Typography
                      className={[classes.textTimeSecond, classes.colorText]}
                      style={{ lineHeight: "1.0" }}
                    >
                      {blocked.timeBlocked}
                    </Typography>
                  </Grid>
                  <Grid container item lg={12} justifyContent="center">
                    <Typography
                      className={[classes.textSecond, classes.colorText]}
                    >
                      {blocked.timeStatus ? "Segundos" : ""}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Card>
          }
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ResendEmailView);