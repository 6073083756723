import React, { Fragment, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import MasterPage from "../components/masterPage/masterPage.component.js";
import { validateTokenAction, tokenValidation } from "../actions/auth.action";
import { useHistory } from 'react-router'
/**
 * PrivateRoute function (Intercepts all private routes with token verification service consumption,
 * on success and valid response, return a React Fragment with component and sidenav,
 * on fail, return a login redirect, on loading return a centered spinner)
 *
 * @export function
 * @param {Component} PrivateRoute
 * @param {String} roleNames
 * @param {String} location
 * @returns React.Fragment, Redirect 
 */

const PrivateRoute = ({ component: Component, isAuth, ...rest }, props) => {
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    // const appName = rest.appName;
    const paramToken = window.location.search.substring(1);

    if (!paramToken && !jwtToken) {
      return <Redirect to='/login' />
    }
    if (paramToken && !jwtToken) {
      dispatch(validateTokenAction(paramToken));
      history.replace({
        search: "",
      })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Route
      {...rest}
      render={(componentProps) => (
        <Fragment>
          <MasterPage component={Component} {...componentProps} appName={rest.appName} />
        </Fragment>
      )
      }
    />
  );
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, { validateTokenAction, tokenValidation })(PrivateRoute);
