import { useState } from "react";

const initialStateDrag = [];
let posDrag = 0;
let iteration = 0;

export const useDrag = (initialState = initialStateDrag) => {
    const [values, setValues] = useState(initialState);

    const addInitialData = (data) => {
        setValues(data);
    }

    const addItem = (item) => {
        const temporalList = [...values, item];
        setValues(temporalList);
    }

    const dragStart = (e) => {
        const html = e.target;
        html.setAttribute("isdrag", true);
        e.dataTransfer.setData("idDragItem", e.target.id);
    };

    const drop = (e) => {
        e.preventDefault();
        const elementDrop = e.target;
        const posDrop = parseInt(elementDrop.getAttribute("pos"));

        const idElementStart = e.dataTransfer.getData("idDragItem");
        const elementStart = document.getElementById(idElementStart);
        let posStart = parseInt(elementStart.getAttribute("pos"));
        elementStart.removeAttribute("isdrag");
        posStart = posDrag;

        let data = JSON.parse(JSON.stringify(values));
        const dataComfirm = JSON.parse(JSON.stringify(values));

        data[posDrop] = dataComfirm[posStart];
        data[posStart] = dataComfirm[posDrop];
        
        iteration = 0;
        setValues(data);
    };

    const dragOver = (e) => {
        e.preventDefault();
        const elementDrag = e.target;
        if (iteration === 0) {
            let posiDrag = parseInt(elementDrag.getAttribute("pos"));
            posDrag = posiDrag;
            iteration++;
        }
    };

    return {
        values,
        addInitialData,
        addItem,
        dragStart,
        dragOver,
        drop,
    };
};
