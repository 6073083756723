import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Select,MenuItem } from "@material-ui/core";
import { LANGUAGES } from '../config/config';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        margin: 0,
        padding: 0,
        backgroundColor: '#0C0C0C',
    },
    content: {
        paddingRight: 10,
        paddingLeft: 10,
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
    },
    suiteBar: {
        marginRight: 10,
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        paddingLeft: '10px',
        paddingRight: '10px',
        background: '#F1F1F1 0% 0% no-repeat padding-box',
    },
    root: {
        "& .MuiSelect-root": {
          boxShadow: "0px 3px 3px #0000001A",
          borderRadius: "10px",
          opacity: 1,
          margin: 2,
          paddingRight: 0,
        },
        "& .MuiSelect-icon": {
            display: 'none',
        },
        "& .MuiSelect-root.Mui-selected": {
            border: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        "&&&:before": {
            borderBottom: "none",
        },
        "&&:after": {
            borderBottom: "none",
        },
    },
    select: {
        '&:hover': {
            backgroundColor: '#3F4849',
            borderRadius: '20px',
            paddingLeft: '5px',
            paddingRight: '5px',
            marginRight: '-5px',
        },
    },
    menuPaper: {
        marginTop: '37px',
        marginLeft: '-38px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
        background: '#141D1E',
    },
    menuItem: {
        color: '#BEC8C9',
        '&:hover': {
            color: '#E1FF2B',
        },
        '&.Mui-selected': {
            color: '#E1FF2B',
        },
    },
}));

const Header = () => {

    const [, i18n] = useTranslation("global");
    const classes = useStyles();

    const onChangeLang = (e) => {
        const lang_code = e.target.value;
        setTimeout(() => {
            i18n.changeLanguage(lang_code);
          }, 2000);
        localStorage.setItem('bms_lang', JSON.stringify(lang_code));
        sessionStorage.removeItem('versionApp');
        window.location.reload();
    };

    const getFlagSrc = (lang) => {
       return LANGUAGES.filter((arr) => arr.code === lang)[0].img;
    };

    return (
        <>
            <AppBar position={'static'} style={{ zIndex: 1211 }}>
                <Toolbar className={classes.toolbar}>
                    <Grid container>
                        <Grid item xs={false} sm={1} />
                        <Grid item xs={12} sm={10} className={classes.content}>
                            <Box display="flex" alignItems="center">
                                <Select
                                    className={classes.root}
                                    classes={{ select: classes.select }}
                                    value={i18n.language}
                                    onChange={onChangeLang}
                                    renderValue={(selected) => {
                                        const flagSrc = getFlagSrc(selected);
                                        return (
                                            <img src={flagSrc} className={classes.logoService} alt="language" />
                                        );
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                        className: classes.menuPaper,
                                        },
                                    }}
                                >
                                    {LANGUAGES.map(({ code, label }) => (
                                    <MenuItem
                                        className={ classes.menuItem }
                                        key={code}
                                        value={code}
                                    >
                                        {label}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                        <Grid item xs={false} sm={1} />
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
