import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
/**
 * CardBody Component ( component usable as container, accept div tree as children, and set card appearance )
 *
 * @export constant
 * @param {*} { ...props }
 * @returns React Material-UI Card
 */

const minWidth = 285;
const maxWidth = 370;
const minHeight = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    //  display: "table",
    width: "368px",
    minWidth: minWidth,
    maxWidth: maxWidth,
    minHeight: minHeight,
    // position: "absolute",
    margin: "0 auto",
    padding: "25px",
    borderRadius: 12,
  },
  title: {
    color: theme.palette.primary.main,
    fontFamily: "muli, sans-serif",
    fontStyle: "normal",
    fontWeight: 800,
    textAlign: "center",
    fontSize: "28px",
    padding: "10px",
  },
  logo: {
    width: "200px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "10px",
    paddingTop: "15.64%",
    backgroundImage: theme.logo.backgroundImage,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const CardBody = ({ ...props }) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <div>
        <div className={classes.logo} alt="Logo"></div>
        <Typography className={classes.title}>{props.title}</Typography>
        {children}
      </div>
    </Card>
  );
};

export default CardBody;
