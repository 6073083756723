import React from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

/**
 * PrimaryButtonUko constant ( function component used as generic primary button )
 *
 * @exports constant
 * @param {*} { ...props }
 * @returns HTML object
 */

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "Center",
    width: "100%",
    "& .MuiButton-root.Mui-disabled": {
        backgroundColor: theme.buttonUko.buttonUkoBackgroundDisabled,
        color: theme.buttonUko.buttonUkoColorDisabled,
        fontSize: '16px',
        fontWeight: 600
    },
    "& .MuiButton-label": {
      fontSize: "16px",
      fontWeight: 600,
      color: theme.buttonUko.buttonUkoBackgroundSuccess,
    },
  },
  button: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.buttonUko.buttonUkoBackgroundActive,
    width: "100%",
    height: "40.19px",
    display: "block",
    color: theme.buttonUko.buttonUkoBackgroundSuccess,
    fontSize: "20px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.buttonUko.buttonUkoBackgroundActive,
    },
    "& .MuiButton-label":{
      textTransform: "none",
    }
  },
}));
const PrimaryButtonUko = ({ ...props }) => {
  const { txtBtn, onClick, type, loading, name, disabled, href } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button
        href={href}
        name={name}
        type={type === "" || type === undefined ? "submit" : type}
        className={classes.button}
        onClick={onClick}
        disabled={loading || disabled}
      >
        {loading && <CircularProgress size={14} color="secondary" />}
        {!loading && txtBtn}
      </Button>
    </div>
  );
};

export default PrimaryButtonUko;
