import { CHANGE_NOTIFICATION, GET_ALL_NOTIFICATIONS, GET_ALL_SUBJECTS, GET_ALL_TEMPLATES, GET_ERROR_NOTIFICATION } from "../actions/types";

const initialState = {
  getAllTemplates: {},
  getErrorTemplates: {},
  getAllSubjects: {},
  getAllNotifications: {loading: false, notifications: []},
  changeNotification: {}
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_TEMPLATES:
      return {
        ...state,
        getAllTemplates: action.payload
      };
    case GET_ERROR_NOTIFICATION:
      return {
        ...state,
        getErrorTemplates: action.payload
      };
    case GET_ALL_SUBJECTS:
      return {
        ...state,
        getAllSubjects: action.payload
      };
    case GET_ALL_NOTIFICATIONS:
      return {
        ...state,
        getAllNotifications: action.payload
      };
    case CHANGE_NOTIFICATION:
      return {
        ...state,
        changeNotification: action.payload
      };

    default:
      return state;
  }
}