import React from 'react';

export const textToSpan = (text, classes) => {
    return (
        <span className={classes.titleTree}>{text}</span>
    )
}

export const enmaskEmail = (oldEmail) => {
    let splitEmail = oldEmail.split('@');
    const lengthFistPart = splitEmail[0].length;
    let enmaskEmail = '';
    const indexOfFinalDomain = oldEmail.indexOf(".c");
    for (let i = 0; i < oldEmail.length; i++) {
        const element = oldEmail[i];
        if ((i > (lengthFistPart / 3)) && element !== '@' && i < (indexOfFinalDomain - 2 )) {
            enmaskEmail += '*';
        } else {
            enmaskEmail += element;
        }
    }
    return enmaskEmail;
}

