import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  tokenValidation,
  assignPassword,
} from "../../../actions/auth.action";

import {
  hasNumbers,
  hasLowerLetter,
  hasUpperLetter,
  hasSpecialCharacter,
  isCorrectLength,
} from "../../../helpers/regex.helper";
import SimpleReactValidator from "simple-react-validator";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Typography from "@material-ui/core/Typography";
import AuthenticationLayoutUko from "../../../components/AuthenticationLayoutUko/AuthenticationLayoutUko";
import CustomInput from "../../../components/AuthenticationLayoutUko/CustomInput";
import PrimaryButtonUko from "../../../components/primaryButtonUko.component";
import ErrorBoxUko from "../../../components/errorBoxUko.component";
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { Link, useHistory  } from "react-router-dom";
import { isEmpty } from "../../../utils/proprietaryHooks";
import { encryptionKey, encryptionIv } from "../../../config/config";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";
import { getVersionAppAction } from "../../../actions/applications.action";
import { Grid, InputAdornment, IconButton, useTheme } from "@material-ui/core";
import { Buffer } from 'buffer';

const ChangePasswordUko = (props) => {
  const {
    appName,
    assignPassword,
    authReducer,
    tokenValidation,
    getVersionAppAction,
    getVersionApp,
  } = props
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const {
      control,
      watch,
    } = useForm({
      defaultValues: {
        password: "",
        confirmPassword: "",
      },
  });
  const watchPassword = watch('password');
  const watchConfirmPassword = watch('confirmPassword');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorBox, setErrorBox] =useState("");
  const [isErrorBox, setIsErrorBox]=useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showIconNewPassword, setShowIconNewPassword] = useState(false);
  const [showIconConfirmPassword, setShowIconConfirmPassword] = useState(false);
  const [incorrectPasswords, setIncorrectPasswords]= useState(false);
  const [versionMessage, setVersionMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState({
      length: false,
      upper: false,
      lower: false,
      number: false,
      specialChar: false,
  });

  const [formState, setFormState] = useState({
    user: "",
    token: "", 
    email: "",
    confirmpassword: "",
    newpassword: "",
    tokenValidation:false,
  });

  const loginValidator = new SimpleReactValidator(reactValidatorOptions);

  useEffect(() => {
    if (!watchPassword || !watchConfirmPassword) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [watchPassword, watchConfirmPassword]);

  useEffect(() => {
    if (appName && appName !== "") {
      getVersionAppAction(appName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(getVersionApp)) {
      setVersionMessage(getVersionApp.version);
    }
  }, [getVersionApp]);

  useEffect(() => {
    try {
      var paramsChar = decodeURIComponent(window.location.search.substring(1));
      var aesjs = require("aes-js");
      var aesCbc = new aesjs.ModeOfOperation.cbc(encryptionKey, encryptionIv);
      var encryptedText = aesjs.utils.hex.toBytes(
        new Buffer(paramsChar || "", "base64").toString("hex")
      );
      var decryptedBytes = aesCbc.decrypt(encryptedText);
      var decryptedText = aesjs.utils.utf8
        .fromBytes(decryptedBytes)
        .replace(/(?:\\[rn]|[\r\n]+|[\000-\031\200-\377])+/g, "");

      if (paramsChar.trim() === "" || decryptedText.trim() === "") {
        history.push({
            pathname: "/Alert",
            state: {
              message: "El enlace no es válido.",
              pagename: "/Login",
            },
          });
        return;
      }

      var objParameters = JSON.parse(decryptedText);
      const email = JSON.parse(Buffer.from((objParameters.token).split('.')[1], 'base64').toString()).email;

      if(objParameters){
        setFormState({
          user: objParameters.user,
          token: objParameters.token,
          email: email,
          oldpassword: "",
          confirmpassword:"",
          newpassword:""
        });
      }
    } catch (error) {
      console.error(error);
      history.push({
          pathname: "/Alert",
          state: {
            message: "Error al procesar el enlace.",
            pagename: "/Login",
          },
      });
    }
  }, [history])

  useEffect(() => {
    const validateToken = async () => {
      if (formState.user !== "" && formState.token !== "") {
        await tokenValidation(
          formState.user,
          formState.token,
          appName,
          history
        );
      }
    };
    if (!formState.tokenValidated) {
      validateToken().then(() => {
        setFormState((prevState) => ({ ...prevState, tokenValidated: true }));
      });
    }
  }, [formState, tokenValidation, appName, history]);

  useEffect(() => {
    if(authReducer.isInvalidToken.ok !== false){
      history.push({
        pathname: "/resendemail",
        state: {
          message: authReducer.isInvalidToken.message,
          messageType: 'INVALID_LINK',
          emailFromUser: formState.email,
          userName: formState.user,
        },
      });
    }
  }, [authReducer, history, formState, appName]);

  const setChangePassword = async (e) => {
    e.preventDefault();
    if (loginValidator.allValid()) {
      if (comparePasswords() === false) {
        setIsErrorBox(true);
        return;
      }
  
      const formData = {
        user: formState.user,
        password: password,
        token: formState.token,
        appname: appName,
      };

      try {
        setLoading(true);
        await assignPassword(formData, history);   
      } catch (error) {
        setLoading(false);
        setErrorBox(error.response.data.result.information);
        setIncorrectPasswords(true);
        setIsErrorBox(true);
      }
    } else {
      loginValidator.showMessages();
      setIsErrorBox(true);
    }
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setShowIconNewPassword(true);
    setIncorrectPasswords(false);
    setIsErrorBox(false);
    setErrorBox("")
  
    const errors = {
      length: isCorrectLength(newPassword, 8),
      upper: hasUpperLetter(newPassword),
      lower: hasLowerLetter(newPassword),
      number: hasNumbers(newPassword) ? true : false,
      specialChar: hasSpecialCharacter(newPassword) ? true : false,
    };

    setPassword(newPassword);
    setPasswordErrors(errors);
  };

  const handleConfirmPasswordChange = (event) => {
    setShowIconConfirmPassword(true)
    setIncorrectPasswords(false);
    setIsErrorBox(false);
    setErrorBox("")
    setConfirmPassword(event.target.value)
  };

  const comparePasswords = () => {
    if (password !== confirmPassword) {
      setErrorBox("Las contraseñas no coinciden.");
      setIncorrectPasswords(true);
      return false;
    } else {
      let passwordValidLength = isCorrectLength(password, 8) ? true : false;
      let passwordValidUpper = hasUpperLetter(password) ? true : false;
      let passwordValidLower = hasLowerLetter(password) ? true : false;
      let passValidNum = hasNumbers(password) ? true : false;
      let passValidChar = hasSpecialCharacter(password) ? true : false;
  
      if (passwordValidLength && passwordValidUpper && passwordValidLower && passValidNum && passValidChar) {
        return true;
      } else {
        setErrorBox("La contraseña no cumple con los mínimos requeridos.");
        setIncorrectPasswords(true);
        return false;
      }
    }
  };

  return (
    <AuthenticationLayoutUko title="Cambiar contraseña" logoApp={theme.urlLogo.urlChangePasswordlogo} logoSignIn={theme.urlLogo.urlSignIn}>
      <form id="cambiarcontraseña-validation" autoComplete="off" onSubmit={(e)=> setChangePassword(e)}>
        <Grid container direction="column" style={{ marginTop: "40px", gap: "16px" }}>
          <Grid item xs={12} style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
            <CustomInput
              label="Nueva contraseña*"
              name="password"
              control={control}
              error={incorrectPasswords ? {
                password: {
                  message: "",
                  type: "required"
                },
                confirmPassword: {
                  message: "",
                  type: "required"
                } 
              } : {}}
              type={showPassword ? "password" : "text"}
              onValidate={(e)=>handlePasswordChange(e)}
              styleFromHelperText={{ style: { marginRight: 10 } }}
              InputPropsParam={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={() => setShowPassword(!showPassword)}
                      size="small"
                    >
                    {showIconNewPassword && (showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />)} 
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              classes={classes.typeInput}
              autoFocus={false}
            />
          </Grid>
          <Grid item xs={12} style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>
            <CustomInput
              label="Confirmar contraseña*"
              name="confirmPassword"
              control={control}
              error={incorrectPasswords ? {
                password: {
                  message: "",
                  type: "required"
                },
                confirmPassword: {
                  message: "",
                  type: "required"
                } 
              } : {}}
              type={showConfirmPassword ? "password" : "text"}
              onValidate={(e)=>handleConfirmPasswordChange(e)}
              styleFromHelperText={{ style: { marginRight: 10 } }}
              InputPropsParam={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                      size="small"
                    >
                    {showIconConfirmPassword && (showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />)}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              classes={classes.typeInput}
              autoFocus={false}
            />
          </Grid>
          <Grid>
            <br />
            <Typography align="left" className={classes.Typography}>Tu contraseña debe tener como mínimo:</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={passwordErrors.upper}
                  color="primary"
                  className={classes.checkBox}
                />
              }
              label={
                <Typography className={classes.Typography}>1 mayúscula</Typography>
              }
              className={classes.displayForm}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={passwordErrors.lower}
                  color="primary"
                  className={classes.checkBox}
                />
              }
              label={
                <Typography className={classes.Typography}>1 minúscula</Typography>
              }
              className={classes.displayForm}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={passwordErrors.number}
                  color="primary"
                  className={classes.checkBox}
                />
              }
              label={
                <Typography className={classes.Typography}>1 número</Typography>
              }
              className={classes.displayForm}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={passwordErrors.specialChar}
                  color="primary"
                  className={classes.checkBox}
                />
              }
              label={
                <Typography className={classes.Typography}>1 carácter especial</Typography>
              }
              className={classes.displayForm}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={passwordErrors.length}
                  color="primary"
                  className={classes.checkBox}
                />
              }
              label={
                <Typography className={classes.Typography}>Mínimo 8 caracteres</Typography>
              }
              className={classes.displayForm}
            />
          </Grid>
          {isErrorBox && (
            <ErrorBoxUko value={errorBox}></ErrorBoxUko>
          )}
          <PrimaryButtonUko
            txtBtn={"Cambiar contraseña"}
            disabled={showButton}
            loading={loading}
          />
          <Grid item xs={12} style={{ display:"flex", justifyContent:"center" }} className={classes.gridReset}>
            <Typography>
              <Link
                to="/login"
                style={{
                  textDecoration: "underline",
                  color: theme.palette.primary.main,
                  marginBottom: '2px',
                  fontSize: '15px',
                  fontWeight: 700
                }}
              >
                Iniciar sesión
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
      <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'space-between', marginTop: '24px', marginBottom: '24px' }} ></div>
      <Box className={classes.version}>
        <p>{versionMessage}</p>
      </Box>
    </AuthenticationLayoutUko>
  ); 
};

const useStyles = makeStyles((theme) => ({
  typeInput: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '8px',
      width: '486px',
      height: '56px',
      backgroundColor: theme.InputUko.backgroundColor,
      [theme.breakpoints.down("xs")]: {
        width: "320px",
      },
    },
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.InputUko.colorInputLetter,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      borderColor: theme.InputUko.borderInput,
    },
    "& .MuiInputLabel-root": {
      fontWeight: 500,
      color: theme.InputUko.colorLabel,
      fontSize: '17px',
      marginTop: '7px',
    },
    "& .MuiInputLabel-root.Mui-focused": {
      fontWeight: 600,
      color: theme.InputUko.colorLabelFocus,
    },
    "& .MuiSvgIcon-root": {
      color: theme.InputUko.colorIcon,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.dark,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: theme.palette.error.dark,
    },
    "& .MuiInputLabel-shrink": {
      margin: "0 auto",
      position: "absolute",
      top: "1px",
    },
  },
  checkBox: {
    color: "#738499",
    "&.MuiCheckbox-colorPrimary.Mui-checked": {
      color: theme.palette.primary.main,
    }
  },
  displayForm:{
    display: 'flex',
    alignItems: 'center',
    margin: '0px 5px 0px 0px'
  },
  Typography:{
      color:'#738499'
  },
  gridReset: {
    [theme.breakpoints.down("xs")]: {
      width: "320px",
    },
  },
  version: {
    fontSize: '14px',
    fontWeight: 500,    
    color: theme.versionUko.colorVersionLabel,    
    position: 'absolute',
    bottom: '15px',
    left: '50%',
    right: '0',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      position: 'static',
    },
    '@media (max-height: 620px)':{
      position: 'static',
    }
  }
}));

ChangePasswordUko.propTypes = {
  assignPassword: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  tokenValidation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  getVersionApp: state.applicationsReducer.getVersionApp,
});

export default connect(mapStateToProps, {
  assignPassword,
  tokenValidation,
  getVersionAppAction,
})(ChangePasswordUko);