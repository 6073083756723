import React from 'react'
import { FormControl, Grid, MenuItem, Typography, FormGroup, FormControlLabel, withStyles, Checkbox } from '@material-ui/core'
import ControlledInputRoundedForm from '../../components/InputForm/ControlledInputRoundedForm'
import { useStylesNotifications } from './notification.style';
import { makeId } from '../../utils/proprietaryHooks';

const AntCheckbox = withStyles((theme) => ({
  root: {
    color: "grey",
    "&$checked": {
      color: theme.palette.primary.light,
    },
  },
  checked: {},
}))(Checkbox);

export const NotificationFormLeftView = (props) => {

  const classes = useStylesNotifications();

  const {
    enabledTabsForm,
    appsToSelect,
    handleChange,
    templatesData,
    errors, 
    control
  } = props;

  return (
    <Grid item lg={4} sm={12}>
      <ControlledInputRoundedForm
        id="reasonNotification"
        name="reasonNotification"
        label="Motivo de notificación"
        multiline
        rows={3}
        inputProps={{
          maxLength: 200,
        }}
        fullWidth
        disabled={!enabledTabsForm}
        control={control}
        error={errors.reasonNotification}
        helperText={errors.reasonNotification?.message}
      />
      <FormControl component="fieldset">
        <Typography className={classes.titleLight}>
          Aplicación
        </Typography>
        <FormGroup>
          {
            appsToSelect.map(item => (
              <FormControlLabel
                key={makeId(5)}
                disabled={!enabledTabsForm}
                control={<AntCheckbox checked={item.check} onChange={handleChange} name={item.name} />}
                label={
                  <Typography color="inherit" style={{ fontSize: "14px" }}>
                    {item.title}
                  </Typography>
                }
              />
            ))
          }
        </FormGroup>
      </FormControl>
      <ControlledInputRoundedForm
        id="template"
        name="template"
        select
        label="Selecciona la plantilla"
        fullWidth
        control={control}
        disabled={!enabledTabsForm}
        error={errors.template}
        helperText={errors.template?.message}
      >
        {templatesData.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </ControlledInputRoundedForm>
    </Grid>
  )
}

export const MemoizedNotificationFormLeftView = React.memo(NotificationFormLeftView)
