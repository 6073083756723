import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { Controller } from "react-hook-form";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary,
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: `1px solid ${theme.palette.info.light}`,
      },
    },
    "&$disabled": {
      color: theme.palette.common.white,
      "& + $track": {
        opacity: `${0.4} !important`,
      },
    },
  },
  disabled: {},
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.info.light}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.info.dark,
  },
  checked: {},
}))(Switch);

export const ControlledSwitchComponent = ({
  control,
  isDisabled,
  onChange,
  value,
  ...props
}) => {
  return (
    <div>
      {!control ? (
        <AntSwitch
          disabled={isDisabled}
          onChange={(e) => onChange(e.target.checked)}
          {...props}
          checked={value}
        />
      ) : (
        <Controller
          name={props.name}
          control={control}
          type="checkbox"
          render={({ props, onChange, value }) => (
            <AntSwitch
              disabled={isDisabled}
              onChange={(e) => onChange(e.target.checked)}
              {...props}
              checked={value}
            />
          )}
          {...props}
        />
      )}
    </div>
  );
};
