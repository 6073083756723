import { combineReducers } from "redux/es/redux";
import authReducer from "./auth.reducer";
import errorReducer from "./error.reducer";
import loadingReducer from "./loading.reducer";
import menuReducer from "./menu.reducer";
import userReducer from "./user.reducer";
import privilegesReducer from "./privileges.reducer";
import rolesReducer from "./roles.reducer";
import resetOtpReducer from "./resetOtp.reducer";
import enterpriseReducer from "./enterprise.reducer";
import applicationsReducer from "./applications.reducer";
import notificationReducer from "./notification.reducer";
import subjectReducer from "./subjects.redecer";

export default combineReducers({
  authReducer,
  errorReducer,
  loadingReducer,
  menuReducer,
  userReducer,
  privilegesReducer,
  rolesReducer,
  resetOtpReducer,
  enterpriseReducer,
  applicationsReducer,
  notificationReducer,
  subjectReducer
});
