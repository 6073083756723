import { fade, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 10,
    backgroundColor: "#FFFFFF",
    boxShadow: `0px 1px 1px #00000026`,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    "&$error": {
      borderColor: theme.palette.error.main,
    },
    "& .MuiInputBase-input.Mui-error": {
      borderColor: theme.palette.error.main,
    },
  },
  inputHiddenLabel: {
    color: "#b2b2b2",
  },
  focused: {
    fontSize: 14,
  },
  error: {},
}));

export const ControlledAutocompleteInputRoundedForm = ({ data, watch, setValue, clearErrors, ...rest }) => {
  const classes = useStyles();
  return (
    <Autocomplete
      id="tags-outlined"
      options={data}
      getOptionLabel={(option) => option.name}
      multiple
      style={{backgroundColor:"#FFF"}}
      defaultValue={() => {
        const currentPrivileges = watch("privilege");
        const listPrivileges = currentPrivileges.split(",");
        const selectedPrivileges = data.filter(item => listPrivileges.includes(item.name))
        return selectedPrivileges;
      }}
      filterSelectedOptions
      renderOption={(option) => <span>{option.name}</span>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          InputLabelProps={{ className: classes.inputHiddenLabel }}
          InputProps={{ ...params.InputProps, disableUnderline: true, classes }}
          label="Privilegios"
          size="small"
          {...rest}
        />
      )}
      onChange={(_, values) => {
        const priv = values.map(item => item.name);
        setValue("privilege", priv.toString())
        clearErrors("privilege")
      }}
    />
  );
};
