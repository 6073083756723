
import { UseDialog } from "@dg-bucaramanga/react-components-dg-qa";
import React, { forwardRef, useEffect, useImperativeHandle, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppsForMenu } from "../../actions/applications.action";
import { GetMenuByApp } from "../../actions/menu.action";
import { useTree } from "../../hooks/useTree";
import { isEmpty, validateEquals, } from "../../utils/proprietaryHooks";
import { MenuSettingsComponent } from "./MenuSettingsComponent";

let array = null;

export const MenuSettingsContainer = forwardRef((props, ref) => {
  const {
    saveMenu,
    applications,
    setApplications,
    currentApp,
    setCurrentApp,
    setOpen,
    setInitialFormValues,
    menu,
    children,
    valuesTitle,
    MenuSelected,
    menuLocation,
    applicationId,
    areChangesSave,
    setCurrentLeftDragDrop,
    setCurrentTopDragDrop,
    setChangesSave,
    isUpdatedItemFlag,
    onCancelButton,
  } = props;
  const {
    currentMenu,
  } = menu;

  const dispatch = useDispatch();

  //#region selectorsRegion
  const applicationsResponse = useSelector(({ applicationsReducer }) => {
    return applicationsReducer.getApplicationsMenuResponse;
  });

  useEffect(() => {
    if (!applications.length && applicationsResponse.length) {
      const sortedApplications = applicationsResponse.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setApplications(sortedApplications);
    }
  }, [applications.length, applicationsResponse, setApplications]);

  const customAction = {
    delete: "Delete",
    edit: "Edit",
    state: "State",
  };
  //#endregion

  const [dialog, setDialog] = React.useState({
    bodyText: "",
    cancelText: "",
    acceptText: "",
    action: "",
  });

  const removeTitle = (menus) => {
    let objectMenus = [];
    if (menus !== undefined && menus.length > 0) {
      for (const menu of menus) {
        let objectMenuChildren = [];
        if (menu.children !== undefined && menu.children.length > 0) {
          objectMenuChildren = removeTitle(menu.children);
        }
        const { title, ...rest } = menu;
        if (objectMenuChildren.length !== 0) {
          objectMenus.push({ ...rest, children: objectMenuChildren });
        } else {
          objectMenus.push(rest);
        }
      }
    }
    return objectMenus;
  };

  /**
   * Maneja la acción cuando se pulsa un botón en un elemento.
   * @param {string} key - La clave del elemento.
   * @param {string} action - La acción a realizar.
   * @param {object} section - La sección donde se encuentra el elemento.
   * @param {MouseEvent} event - El evento del ratón.
   */
  const itemAction = (key, action, section, event) => {
    let meesageToUser = '¡Menú modificado exitosamente!';
    async function getMenus() {
      await new Promise(GetMenuByApp(applicationId, menuLocation));
    }
    getMenus();
    switch (action) {
      case customAction.delete:
        meesageToUser = "El ítem ha sido eliminado";
        setDialog({
          bodyText: "¿Deseas eliminar este ítem?",
          cancelText: "No",
          acceptText: "Sí",
          action: key,
        });
        onAcceptOpen();
        break;
      case customAction.edit:
        section.isActivate = true;
        setInitialFormValues(section);
        setOpen(true);
        break;
      case customAction.state:
        if (MenuSelected === 3) {
          const dataComplete = array.menu3.length === 0 ? currentMenu : array.menu3;
          const menuCompleteApp = updateState(dataComplete.application, key, event.target.checked);
          const menuCompleteUser = updateState(dataComplete.user, key, event.target.checked);

          const responseNewData = {
            application: menuCompleteApp,
            user: menuCompleteUser,
          };
          saveMenu(true, responseNewData);
        } else {
          const dataComplete = array.menu1.length === 0 ? currentMenu : array.menu1;
          const menuComplete = updateState(dataComplete, key, event.target.checked);
          saveMenu(true, menuComplete, meesageToUser);
        }
        break;
      default:
        break;
    }
  };

  const handleConfirmClick = () => {
    onAcceptClose();
    if (menuLocation === 3) {
      const newDataApp = searchItemUpdateAndDelete(dialog.action, array.menu3.application, "Delete");
      const newDataUser = searchItemUpdateAndDelete(dialog.action, array.menu3.user, "Delete");
      const responseNewData = {
        application: newDataApp,
        user: newDataUser,
      };
      saveMenu(true, responseNewData, "El ítem ha sido eliminado");
    } else {
      const newData = searchItemUpdateAndDelete(dialog.action, array.menu1, "Delete");
      saveMenu(true, newData, "El ítem ha sido eliminado");
    }
  };

  /**
   * This action is necesary because scroll is hidden when Dialog is closed
   */
  const cancelOperation = () => {
    onAcceptClose();
    document.body.style.overflowY = "scroll";
  }

  const {
    Dialog: AcceptDialog,
    onOpen: onAcceptOpen,
    onClose: onAcceptClose,
  } = UseDialog({
    bodyText: dialog.bodyText,
    cancelButtonText: dialog.cancelText,
    confirmationButtonText: dialog.acceptText,
    onCancelClick: cancelOperation,
    onConfirmClick: handleConfirmClick,
  });

  const { values, addItemMenu, addInitialDataMenu, addItemMenu3, addInitialDataMenu3 } = useTree(itemAction);

  //#region
  /* A hook that is called when the component is mounted. */
  useEffect(() => {
    if (!applicationsResponse.length) {
      dispatch(getAppsForMenu());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!applications.length && applicationsResponse.length) {
      setApplications(applicationsResponse);
    }
  }, [applications.length, applicationsResponse, setApplications]);

  const memoizedAddInitialDataMenu = useCallback(addInitialDataMenu, [addInitialDataMenu]);
  const memoizedAddInitialDataMenu3 = useCallback(addInitialDataMenu3, [addInitialDataMenu3]);

  useEffect(() => {
    if (!isEmpty(currentMenu) && !array.menu1.length && MenuSelected === 1) {
      memoizedAddInitialDataMenu(currentMenu);
    }
  }, [memoizedAddInitialDataMenu, currentMenu, MenuSelected]);

  useEffect(() => {
    if (MenuSelected === 3) {
      if (!isEmpty(currentMenu) && (currentMenu.user.length || currentMenu.application.length) && array.menu3 && !array.menu3.user.length && !array.menu3.application.length) {
        memoizedAddInitialDataMenu3(currentMenu);
      }
    }
  }, [memoizedAddInitialDataMenu3, currentMenu, MenuSelected]);

  //#endregion

  useImperativeHandle(ref, () => ({
    addItemMenu: addItemMenu,
    addInitialDataMenu: addInitialDataMenu,
    values,
    searchItemUpdateAndDelete,
    addItemMenu3: addItemMenu3,
    addInitialDataMenu3: addInitialDataMenu3
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [values, MenuSelected]);

  useEffect(() => {
    array = values;
    if (menuLocation === 1) {
      if (array.menu1.length > 0) {
        let areEquals = validateEquals(removeTitle(array.menu1), removeTitle(currentMenu));
        if (areEquals) {
          if (isUpdatedItemFlag) {
            setChangesSave(true);
          } else {
            setChangesSave(false);
          }
          setCurrentLeftDragDrop([]);
        } else {
          setCurrentLeftDragDrop(array.menu1);
          setChangesSave(true);
        }
      }
    }
    if (menuLocation === 2) {
      if (array.menu1.length > 0) {
        let areEquals = validateEquals(removeTitle(array.menu1), removeTitle(currentMenu));
        if (areEquals) {
          if (isUpdatedItemFlag) {
            setChangesSave(true);
          } else {
            setChangesSave(false);
          }
          setCurrentTopDragDrop([]);
        } else {
          setCurrentTopDragDrop(array.menu1);
          setChangesSave(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, currentMenu]);

  /**
   *
   * @param {*} key  clave del item a actualizar
   * @param {*} initialData  arbol completo
   * @param {*} action   editar o eliminar
   * @param {*} newData  item que se está actualizando
   * @returns
   */
  const searchItemUpdateAndDelete = (key, initialData, action, newData) => {
    let dataResponse = [];
    for (const dat of initialData) {
      let dataChildrenResponse = [];
      if (dat.children !== undefined && dat.children.length > 0) {
        // busco item y lo remuevo de la lista
        if (action === "Delete") {
          const haveItem = dat.children.find((item) => item.key === key);
          if (haveItem) {
            dat.children = dat.children.filter((item) => item.key !== key);
          }
        }
        dataChildrenResponse = searchItemUpdateAndDelete(
          key,
          dat.children,
          action,
          newData
        );
      }
      if (action === "Delete") {
        if (dat.key !== key) {
          if (dataChildrenResponse.length > 0) {
            dataResponse.push({ ...dat, children: dataChildrenResponse });
          } else {
            dataResponse.push(dat);
          }
        }
      } else {
        if (dat.key === key) {
          if (dataChildrenResponse.length > 0) {
            dataResponse.push({ ...newData, children: dataChildrenResponse });
          } else {
            dataResponse.push({ ...newData });
          }
        } else {
          if (dataChildrenResponse.length > 0) {
            dataResponse.push({ ...dat, children: dataChildrenResponse });
          } else {
            dataResponse.push(dat);
          }
        }
      }
    }
    return dataResponse;
  };

  /**
   * Toma un array de objetos, una clave y un valor, y devuelve un nuevo array de objetos con el
   * valor de la clave establecido en el valor.
  * @param data - los datos que quieres actualizar
  * @param key - la clave del elemento que desea actualizar
  * @param value - true/false
  * @returns un array de objetos.
  */
  const updateState = (data, key, value) => {
    return data.map((item) => {
      let children = item.children;
      if (children && children.length > 0) {
        children = updateState(children, key, value);
      }
      return item.key === key ? { ...item, isActivate: value, children } : { ...item, children };
    });
  };

  return (
    <div>
      <MenuSettingsComponent
        applications={applications}
        currentApp={currentApp}
        values={values}
        valuesTitle={valuesTitle}
        setCurrentApp={setCurrentApp}
        children={children}
        addInitialDataMenu={addInitialDataMenu}
        addInitialDataMenu3={addInitialDataMenu3}
        saveMenu={saveMenu}
        setOpen={setOpen}
        MenuSelected={MenuSelected}
        areChangesSave={areChangesSave}
        onCancelButton={onCancelButton}
      />
      <AcceptDialog />
    </div>
  );
});